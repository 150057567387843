import logger from 'logger';
import { locationApiClient } from '@backend/src/services/locationApi';
import { paths } from '@backend/src/services/locationApi/schema';
import { Lead } from '@backend/src/interfaces/mongoose.gen';

export type LocationApiLocation =
  paths['/locationById/{locationId}']['get']['responses']['200']['content']['application/json'];

export type CloseLocations = {
  distance: number;
  location: LocationApiLocation;
}[];

export const getCloseLocations = async (
  address: Pick<Lead['fromAddress'], 'lat' | 'lon'>,
): Promise<CloseLocations> => {
  const { lat, lon } = address;

  const { data: locations } = await locationApiClient.GET(
    '/locationsByCoordinates',
    {
      params: {
        query: { lat, lon },
      },
    },
  );

  logger.info('getCloseLocations locations', {
    locations,
  });

  logger.info('getCloseLocations locations', { locations });

  return (locations?.slice(0, 3) || []) as CloseLocations;
};

export const checkIfLocationIsOK = ({
  locationID,
  closeLocations,
  currentLocationServiceArea,
}: {
  locationID: Lead['locationID'];
  closeLocations: CloseLocations;
  currentLocationServiceArea: LocationApiLocation['serviceArea'];
}): boolean => {
  const allowedDistance = parseInt(
    currentLocationServiceArea?.distance || '30',
    10,
  );
  const foundLocation = closeLocations.find(
    (closeLocation) => closeLocation.location?.id === locationID,
  );
  return (
    typeof foundLocation !== 'undefined' &&
    (foundLocation.distance || 0) <= allowedDistance
  );
};
