import {
  Lead,
  LeadInventory,
  LeadPhotoTag,
} from '@backend/src/interfaces/mongoose.gen';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { LocationApiLocation } from 'lib/locations';
import { InventoryType } from 'services/movetrac';

export const determineOtherQuantity = ({
  itemQuantity,
  tags,
}: {
  itemQuantity: LeadInventory['quantity'];
  tags: LeadPhotoTag[];
}): number =>
  itemQuantity - tags.reduce((acc, item) => acc + (item.quantity || 0), 0);

export const incrementInventory = ({
  lead,
  itemType,
  spaceId,
  quantity,
}: {
  lead: Lead;
  itemType: LeadInventory['itemType'];
  spaceId: LeadInventory['spaceId'];
  quantity: LeadInventory['quantity'];
}): Omit<LeadInventory, '_id'>[] => {
  const inventory = lead.inventory || [];

  const existingItem = inventory.find(
    (item) => item.spaceId === spaceId && `${item.itemType}` === `${itemType}`,
  );

  if (existingItem) {
    return inventory
      .map((item) => {
        return item.spaceId === spaceId && `${item.itemType}` === `${itemType}`
          ? {
              ...item,
              quantity: parseInt(`${quantity}`, 10) + item.quantity,
            }
          : item;
      })
      .filter((item) => item.quantity > 0);
  }

  return [
    ...inventory,
    { itemType, spaceId, quantity: parseInt(`${quantity}`, 10) },
  ];
};

export const isInventoryItemSpecialty = (item: InventoryType): boolean =>
  item.specialtyItem === 1;

export const isInventoryItemIncompatible = ({
  currentLocation,
  inventoryItem,
}: {
  currentLocation: LocationApiLocation;
  inventoryItem: InventoryType;
}): boolean => {
  const capabilityName = inventoryItem.locationCapabilityName;
  return (
    !isNil(capabilityName) &&
    !!capabilityName &&
    get(currentLocation, `capabilities.${capabilityName}`, 0) === 0
  );
};
