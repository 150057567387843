import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formErrorAnatomy.keys);
// import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = definePartsStyle({
  text: {
    bg: 'red.100',
    py: 2,
    px: 5,
    border: '1px',
    borderColor: 'errorColor',
    fontSize: { base: 'xs', md: 'sm' },
    color: 'black',
    p: {
      color: 'black',
    },
    _dark: {
      bg: 'red.900',
      color: 'white',
      p: {
        color: 'white',
      },
    },
  },
});

export const fancyField = definePartsStyle({
  text: {
    position: 'relative',
    top: -2,
    borderTop: 0,
    borderBottomRadius: '3xl',
  },
});

export const optionsField = definePartsStyle({
  text: {
    w: 'auto',
    display: 'inline-flex',
  },
});

export default defineMultiStyleConfig({
  // defaultProps: {
  //   variant: 'base',
  // },
  baseStyle,
  variants: { fancyField, optionsField },
});
