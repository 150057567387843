import * as React from 'react';
import { Trans } from 'react-i18next';

import { UFL_DOMAIN_EXT } from 'utils';
import { Box, Button, List, ListItem, Link } from '@chakra-ui/react';

const Hamburger = () => {
  const [showHamburger, setShowHamburger] = React.useState();
  const toggleHamburger = (e) => {
    switch (e.type) {
      case 'focus':
        setShowHamburger(true);
        break;
      case 'blur':
        setShowHamburger(false);
        break;
      case 'click':
        setShowHamburger(!showHamburger);
        break;
      default:
        break;
    }
  };
  const toggleClass = showHamburger ? 'show-nav nav-open' : '';
  const canada = UFL_DOMAIN_EXT === 'ca';
  const homeURL = `https://twomenandatruck.${canada ? 'ca' : 'com'}/`;
  const ariaExpanded = showHamburger ? 'true' : 'false';

  return (
    <Box className={toggleClass} onClick={toggleHamburger}>
      <Button
        id="toggle-menu"
        className="toggle-menu"
        aria-controls="megamenu"
        aria-expanded={ariaExpanded}
        aria-haspopup="true"
        aria-labelledby="mobile-menu-text"
        variant="mobileMenuToggle"
      >
        <Box as="span" className="bar first"></Box>
        <Box as="span" className="bar middle"></Box>
        <Box as="span" className="bar last"></Box>
        <Box as="span" id="mobile-menu-text" className="mobile-menu-text">
          <Trans>Menu</Trans>
        </Box>
      </Button>
      <Box
        as="nav"
        id="megamenu"
        className="megamenu"
        py={4}
        overflowY="scroll"
        layerStyle="lightContrastBox"
        borderBottom="1px"
        display={showHamburger ? 'block' : 'none'}
      >
        <List className="menu menu--main" px={4}>
          <ListItem>
            <Link
              id="local-moving"
              href={`${homeURL}local-moving`}
              target="_blank"
              rel="noopener"
              onFocus={toggleHamburger}
              onBlur={toggleHamburger}
              fontWeight="light"
              display="block"
              transition="all 0.2s ease"
              textDecoration="none"
              color="tmtBlue"
              borderBottom="1px"
              py={2.5}
              pl={0}
              _hover={{ pl: 2, color: 'black' }}
              _dark={{ color: 'tmtGold', _hover: { color: 'white' } }}
            >
              <Trans>Local</Trans>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              id="long-distance"
              href={`${
                canada ? homeURL + 'moving-services/' : homeURL
              }long-distance-moving`}
              target="_blank"
              rel="noopener"
              onFocus={toggleHamburger}
              onBlur={toggleHamburger}
              fontWeight="light"
              display="block"
              transition="all 0.2s ease"
              textDecoration="none"
              color="tmtBlue"
              borderBottom="1px"
              py={2.5}
              pl={0}
              _hover={{ pl: 2, color: 'black' }}
              _dark={{ color: 'tmtGold', _hover: { color: 'white' } }}
            >
              <Trans>Long Distance</Trans>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              id="packing"
              href={`${homeURL}${
                canada ? 'packing-services' : 'packing-and-moving-supplies'
              }`}
              target="_blank"
              rel="noopener"
              onFocus={toggleHamburger}
              onBlur={toggleHamburger}
              fontWeight="light"
              display="block"
              transition="all 0.2s ease"
              textDecoration="none"
              color="tmtBlue"
              borderBottom="1px"
              py={2.5}
              pl={0}
              _hover={{ pl: 2, color: 'black' }}
              _dark={{ color: 'tmtGold', _hover: { color: 'white' } }}
            >
              <Trans>Packing & Supplies</Trans>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              id="reviews"
              href={`${homeURL}reviews`}
              target="_blank"
              rel="noopener"
              onFocus={toggleHamburger}
              onBlur={toggleHamburger}
              fontWeight="light"
              display="block"
              transition="all 0.2s ease"
              textDecoration="none"
              color="tmtBlue"
              borderBottom="1px"
              py={2.5}
              pl={0}
              _hover={{ pl: 2, color: 'black' }}
              _dark={{ color: 'tmtGold', _hover: { color: 'white' } }}
            >
              <Trans>Reviews</Trans>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              id="about-us"
              href={`${homeURL}about-us`}
              target="_blank"
              rel="noopener"
              onBlur={toggleHamburger}
              onFocus={toggleHamburger}
              fontWeight="light"
              display="block"
              transition="all 0.2s ease"
              textDecoration="none"
              color="tmtBlue"
              py={2.5}
              pl={0}
              _hover={{ pl: 2, color: 'black' }}
              _dark={{ color: 'tmtGold', _hover: { color: 'white' } }}
            >
              <Trans>About Us</Trans>
            </Link>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Hamburger;
