import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import get from 'lodash/get';
import pick from 'lodash/pick';
import findKey from 'lodash/findKey';
import { Button, Text, Box } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
} from 'react-hook-form';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import untranslatedBuildingTypes from '@backend/lib/mwc/buildings';
import {
  isFromAddressBuildingTypeStorage,
  validBuildingFloor,
} from '@backend/lib/lead/helperFunctions';
import untranslatedSteps from '@backend/lib/lead/steps';
import { calculateMilage, formatAddress } from 'services/mapbox';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { useFormDefaults } from 'lib/form';
import { getCloseLocations, checkIfLocationIsOK } from 'lib/locations';
import { updateCloseLocations } from 'actions/locationActions';
import {
  selectLead,
  selectLeadCustomerType,
  selectLeadLocationId,
} from 'slices/leadSlice';
import { selectCurrentLocation } from 'slices/app';
import { RootState } from 'store/configureStore';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import RhfAddressAutocompleteField, {
  MANUAL_ADDRESS_ID,
} from 'components/fields/RhfAddressAutocompleteField';
import StepSummary from 'components/StepSummary';
import RhfTextField from 'components/fields/RhfTextField';
import RhfStyledRadioField from 'components/fields/RhfStyledRadioField';
import UlfFormStep from 'components/UlfFormStep';

const STEP_NAME = 'fromAddress';

const useSelectFromAddressStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) => pick(lead, ['fromAddress'])),
  );

export const FromAddressStepSummary = () => {
  const values = useSelectFromAddressStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid} data-private>
      {values.fromAddress?.fullAddress}
    </StepSummary>
  );
};

const homeBuildingTypes = untranslatedBuildingTypes().home;
const condoId = findKey(homeBuildingTypes, { mwc: 'Condo' });
const appartmentId = findKey(homeBuildingTypes, { mwc: 'Apartment' });

export const addressStepFormSchema = z
  .object({
    autoCompleteSuggestion: z
      .object({
        id: z.string(),
        place_name: z.string(),
      })
      .passthrough()
      .or(z.string())
      .refine((v) => typeof v === 'object', {
        params: { i18n: 'invalid_autocomplete_address' },
      }),
    manualAddress: z
      .string()
      .optional()
      .refine(
        (v) =>
          !v ||
          RegExp(
            /^(\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d|[A-Z]\d[A-Z]\s?)$/i,
          ).test(v || ''),
        {
          params: { i18n: 'invalid_postal_code' },
        },
      ),
    buildingTypeId: z.string(),
    buildingFloor: z.string().or(z.null()).optional(),
    buildingComplex: z.string().or(z.null()).optional(),
    buildingUnit: z.string().or(z.null()).optional(),
  })
  .superRefine(
    (
      { autoCompleteSuggestion, manualAddress, buildingTypeId, buildingFloor },
      refinementContext,
    ) => {
      if (
        typeof autoCompleteSuggestion === 'object' &&
        autoCompleteSuggestion.id === MANUAL_ADDRESS_ID &&
        !(manualAddress || '').length
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          params: { i18n: 'required' },
          path: ['manualAddress'],
        });
      }

      if (!validBuildingFloor(buildingTypeId, buildingFloor || '')) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          // message: 'Please provide a floor number',
          params: { i18n: 'invalid_floor_number' },
          path: ['buildingFloor'],
        });
      }
    },
  );

export type AddressStepFormValues = z.infer<typeof addressStepFormSchema>;

export const AddressStepForm = ({
  onContinue,
  onSave,
  searchOptions,
  customerType,
  stepName,
}: UseStepMethodsReturn & {
  searchOptions: {
    componentRestrictions: {
      country: string[];
    };
    geolocation: {
      latitude?: string | number;
      longitude?: string | number;
    };
  };
  customerType: string;
  stepName: string;
}) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[stepName];
  const { watch, handleSubmit, formState, control, reset } =
    useFormContext<AddressStepFormValues>();
  const { isSubmitting, isValid } = formState;

  const buildingTypes =
    untranslatedBuildingTypes(t)[customerType as 'home' | 'business'];

  const autoCompleteSuggestion = watch('autoCompleteSuggestion');

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      {typeof autoCompleteSuggestion === 'object' &&
      autoCompleteSuggestion?.id === MANUAL_ADDRESS_ID ? (
        <>
          <Text>
            <Trans>
              Don't worry, your ZIP/postal code is enough for us to start your
              quote. You can give us more details later if needed.
            </Trans>
          </Text>
          <RhfTextField
            label={t('Postal Code')}
            isRequired={true}
            control={control}
            name="manualAddress"
          />
          <Button
            onClick={() =>
              reset({
                autoCompleteSuggestion: '' as unknown as {
                  id: string;
                  description: string;
                },
              })
            }
          >
            <Trans>Return to Address Autocomplete</Trans>
          </Button>
        </>
      ) : (
        <RhfAddressAutocompleteField
          label={t('Address')}
          isRequired={true}
          control={control}
          name="autoCompleteSuggestion"
          searchOptions={searchOptions}
        />
      )}
      <RhfStyledRadioField
        label={t('What type of building is this?')}
        isRequired={true}
        control={control}
        name="buildingTypeId"
        isComplex={true}
        options={Object.entries(buildingTypes).map(([value, option]) => ({
          value,
          label: option.label,
          icon: option.icon,
        }))}
      />
      {customerType === 'home' &&
        [condoId, appartmentId].includes(watch('buildingTypeId')) && (
          <Box mt={8}>
            <RhfTextField
              label={t('Floor number')}
              inputProps={{
                // type: 'number',
                inputMode: 'numeric',
                min: '1',
                max: '99',
                maxLength: 2, // This doesn't always work
              }}
              maskedInputProps={{
                mask: [/[1-9]/, /[0-9]/],
                guide: false,
              }}
              isRequired={true}
              control={control}
              name="buildingFloor"
            />
            <RhfTextField
              label={t('Complex')}
              control={control}
              name="buildingComplex"
              inputProps={{
                maxLength: 50,
              }}
            />
            <RhfTextField
              label={t('Unit Number / Letter')}
              control={control}
              name="buildingUnit"
              inputProps={{
                maxLength: 50,
              }}
            />
          </Box>
        )}
    </UlfFormStep>
  );
};

export const useFromAddressStep = (
  address: Lead['fromAddress'],
  stepName: string,
) => {
  const { onSave, onContinue } = useStepMethods(stepName);
  const currentLocation = useAppSelector(selectCurrentLocation);
  const geoIpLocation = useAppSelector(
    (state: RootState) => state.app.geoIpLocation,
  );
  const customerType = useAppSelector(selectLeadCustomerType);

  const searchOptions = {
    componentRestrictions: {
      country: ['us', 'ca'],
    },
    geolocation: get(currentLocation, 'coordinates.lat', false)
      ? {
          latitude: get(currentLocation, 'coordinates.lat', undefined),
          longitude: get(currentLocation, 'coordinates.lon', undefined),
        }
      : {
          latitude: get(geoIpLocation, 'latitude', undefined),
          longitude: get(geoIpLocation, 'longitude', undefined),
        },
  };

  const form = useForm<AddressStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(addressStepFormSchema),
    defaultValues: {
      autoCompleteSuggestion: address?.autoCompleteSuggestion,
      manualAddress: address?.manualAddress,
      buildingTypeId: address?.buildingTypeId
        ? `${address?.buildingTypeId as unknown as string}`
        : undefined,
      buildingFloor: address?.buildingFloor as unknown as string,
      buildingComplex: address?.buildingComplex,
      buildingUnit: address?.buildingUnit,
    },
  });

  return {
    onSave,
    onContinue,
    customerType,
    searchOptions,
    currentLocation,
    form,
  };
};

export const parseAddressFormValues = ({
  autoCompleteSuggestion,
  buildingUnit,
  buildingFloor,
  buildingComplex,
  buildingTypeId,
  ...values
}: AddressStepFormValues): Lead['fromAddress'] => ({
  ...values,
  autoCompleteSuggestion,
  buildingTypeId: parseInt(buildingTypeId, 10),
  buildingFloor: buildingFloor ? parseInt(buildingFloor, 10) : undefined,
  buildingComplex: buildingComplex ? buildingComplex : undefined,
  buildingUnit: buildingUnit ? buildingUnit : undefined,
  validated: true,
  ...formatAddress(autoCompleteSuggestion),
});

const FromAddressStep = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fromAddress: address } = useSelectFromAddressStepValues();
  const leadLocationId = useAppSelector(selectLeadLocationId);
  const {
    onSave,
    onContinue,
    customerType,
    searchOptions,
    currentLocation,
    form,
  } = useFromAddressStep(address, STEP_NAME);

  const { setError } = form;

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<AddressStepFormValues> => {
    return async (values: AddressStepFormValues) => {
      try {
        const address = parseAddressFormValues(values);

        // Do distance calculations for each.
        const closeLocations = await getCloseLocations(address);

        dispatch(updateCloseLocations(closeLocations));

        return (
          fn &&
          fn({
            oneSpace: isFromAddressBuildingTypeStorage({
              fromAddress: address,
              customerType,
            }),
            fromAddress: address,
            locationOK: checkIfLocationIsOK({
              locationID: leadLocationId,
              closeLocations,
              currentLocationServiceArea: currentLocation?.serviceArea,
            }),
            // If already have a location, do its distance calc.
            selectedLocationDistance: currentLocation
              ? await calculateMilage(address, {
                  lat: currentLocation.coordinates?.lat,
                  lon: currentLocation.coordinates?.lon,
                })
              : undefined,
          })
        );
      } catch (error) {
        setError('autoCompleteSuggestion', {
          message: t('There was an error! Please try again.') as string,
        });
      }
    };
  };

  return (
    <FormProvider {...form}>
      <AddressStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
        searchOptions={searchOptions}
        customerType={customerType as string}
        stepName={STEP_NAME}
      />
    </FormProvider>
  );
};

const FromAddressStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <FromAddressStep /> : <FromAddressStepSummary />;
};

export default FromAddressStepContainer;
