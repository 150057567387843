import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { routerReducer } from 'store/reduxFirstHistory';
import app from 'reducers/app';
import uploadsReducer from 'slices/uploadsSlice';
import inventoryReducer from 'slices/inventorySlice';
import leadReducer from 'slices/leadSlice';

const config = {
  key: 'root',
  blacklist: ['router', 'uploads'],
  storage,
};

const rootReducer = persistCombineReducers(config, {
  app,
  lead: leadReducer,
  router: routerReducer,
  uploads: uploadsReducer,
  inventory: inventoryReducer,
});

export default rootReducer;
