import type { Dictionary } from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { UnknownAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ReadyState } from 'react-use-websocket';
import { push, replace } from 'redux-first-history';
import type { PartialDeep } from 'type-fest/source/partial-deep';

import {
  Lead,
  LeadInventory,
  LeadPhoto,
  LeadPhotoTag,
  LeadRoom,
} from '@backend/src/interfaces/mongoose.gen';
import { getUniqueRoomName } from '@backend/lib/lead/helperFunctions';
import untranslatedRooms from '@backend/lib/mwc/rooms';
import steps from '@backend/lib/lead/steps';
import { LeadBox, ObjectId } from '@backend/src/lib/lead';
import { photoStatus } from '@backend/src/lib/lead/constants';
import { createObjectId, determineCountry } from 'utils';
import logger from 'logger';
import types from 'constants/actionTypes';
import calculateFields from 'lib/calculateFields';
import { generateStorageSpace, isSpaceValid } from 'lib/space';
import { determineOtherQuantity, incrementInventory } from 'lib/inventory';
import { calculateRoomBasedEstimate } from 'lib/calculateRoomBasedEstimate';
import handleFromLocationOrServiceChange from 'lib/handleFromLocationOrServiceChange';
import { setInventorySpaceId } from 'slices/inventorySlice';
import { leadPhotoTagsBySpaceAndType } from 'lib/photoTag';
import { RootState } from 'store/configureStore';
import {
  doubleCheckLocationOK,
  processGtmEvents,
  setPercentageComplete,
} from 'actions/formActions';
import { leadCreate, leadGet, leadUpdate } from 'services/backend';
import {
  calculateFranchiseId,
  getGAValues,
  handleCustomerTypeDidChange,
  getPercentComplete,
} from 'actions/actionUtilities';
import { fireGtmEvent, setUserMessage } from 'actions/appActions';
import { verifyCloseLocations } from 'actions/locationActions';
import { calculateMoveDriveDistance } from 'lib/lead';

const roomDefinitions = untranslatedRooms();

export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAction
>;

type SimpleInventory = Omit<LeadInventory, '_id'>;

export const saveToStore = (
  detailsPartial: PartialDeep<Lead>,
): UnknownAction => {
  return {
    type: types.SAVE_LEAD_DETAILS,
    detailsPartial,
  };
};

export const apiSubmit = createAsyncThunk(
  'apiSubmit',
  async (lead: PartialDeep<Lead>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const activeLeadID = state.app.activeLeadID; // STATE INJECTION
    if (activeLeadID) {
      dispatch(saveLead(lead));
    } else {
      dispatch(createLead(lead));
    }
  },
);

export const savePartialLead = createAsyncThunk(
  'savePartialLead',
  async (partialLead: PartialDeep<Lead>, { dispatch }) => {
    dispatch(apiSubmit(partialLead));
    dispatch(saveToStore(partialLead));
  },
);

export const fetchLead = createAsyncThunk(
  'fetchLead',
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const token = state.app.token as string;
    const activeLeadID = state.app.activeLeadID as string;

    try {
      const lead = await leadGet({
        token,
        activeLeadID,
      });

      dispatch(saveToStore(lead));

      dispatch(
        verifyCloseLocations(lead.fromAddress) as unknown as UnknownAction,
      );

      return lead;
    } catch (response) {
      logger.error('fetchLead error', response);
      if (`${(response as Dictionary<string>).status}`[0] === '4') {
        dispatch(replace('/reauth'));
      }
    }
  },
  {
    condition: (_lead, { getState }) => {
      const state = getState() as RootState;
      if (state.app.backendPending === true) {
        return false;
      }
    },
  },
);

export const createLead = createAsyncThunk(
  'createLead',
  async (lead: PartialDeep<Lead>) => {
    try {
      return await leadCreate({
        lead,
      });
    } catch (response) {
      logger.error('createLead error', response);
      throw response;
    }
  },
);

export const saveLead = createAsyncThunk(
  'saveLead',
  async (lead: PartialDeep<Lead>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const token = state.app.token as string;
    const activeLeadID = state.app.activeLeadID as string;

    try {
      const response = await leadUpdate({
        token,
        activeLeadID,
        lead,
      });
      return response;
    } catch (response) {
      logger.error('saveLead error', response);
      if (`${(response as Dictionary<string>).status}`[0] === '4') {
        dispatch(replace('/reauth'));
      } else {
        throw response;
      }
    }
  },
);

export const getLeadIfNotWebSocket = (): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const readyState = state.app.readyState as ReadyState;
    if (readyState !== ReadyState.OPEN) {
      logger.info('Not connected to websocket, so manually getting lead.');
      dispatch(fetchLead());
    }
  };
};

export const addSpace = ({
  roomType,
  optionalName = '',
  // hasInventory = true,
  // hasBoxes = true,
}: {
  roomType: LeadRoom['roomType'];
  optionalName?: string;
  hasInventory?: boolean;
  hasBoxes?: boolean;
}): AppThunk<Promise<LeadRoom>> => {
  return async (dispatch, getState): Promise<LeadRoom> => {
    const state = getState();
    const lead = state.lead;
    const _id = createObjectId() as unknown as ObjectId;
    let rooms = lead.rooms || [];

    // Save a new room.
    const customerType = get(lead, 'customerType', 'home');
    const existingRoomNames = rooms.map((room) => room.optionalName || '');

    const roomTypeLabel = roomDefinitions[customerType][roomType].label;

    const generatedOptionalName = getUniqueRoomName(
      roomTypeLabel,
      existingRoomNames,
    );

    const space: LeadRoom = {
      _id,
      roomType,
      optionalName: optionalName || generatedOptionalName,
      // noInventory: !hasInventory,
      // noBoxes: !hasBoxes,
      isValid: false,
    };

    rooms = [...rooms, space];

    dispatch(
      savePartialLead({
        oneSpace: false,
        rooms,
      }),
    );

    return space;
  };
};

export type AddSpaceArgs = Parameters<typeof addSpace>[0];

export const addInventory = (
  inventory: SimpleInventory,
): AppThunk<Promise<boolean>> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state = getState();
    const lead = state.lead;
    const { itemType, spaceId, quantity } = inventory;
    const updatedInventory = incrementInventory({
      lead,
      itemType,
      spaceId,
      quantity,
    });

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = updatedInventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    dispatch(
      savePartialLead({
        inventory: updatedInventory as LeadInventory[],
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === spaceId
            ? {
                ...space,
                // noInventory: false,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );

    return true;
  };
};

export const removeInventory = ({
  itemType,
  spaceId,
}: {
  itemType: LeadInventory['itemType'];
  spaceId: LeadInventory['spaceId'];
}): AppThunk<Promise<boolean>> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state = getState();
    const lead = state.lead;
    const inventory = (lead.inventory || []).filter((item) => {
      return item.spaceId !== spaceId || item.itemType !== itemType;
    });

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    dispatch(
      savePartialLead({
        inventory,
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === spaceId
            ? {
                ...space,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );

    return true;
  };
};

export const setBoxQuantity = ({
  itemType,
  quantity,
  spaceId,
}: Omit<LeadBox, '_id'>): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const lead = state.lead;
    let boxes = lead.boxes || [];
    const spaceBoxOfThisType = boxes.find(
      (box) => box.spaceId === spaceId && `${box.itemType}` === `${itemType}`,
    );

    const parsedQuantity = parseInt(quantity, 10);
    const isZeroQuanity = parsedQuantity === 0;

    if (!isZeroQuanity && typeof spaceBoxOfThisType === 'undefined') {
      // New entry.
      boxes = [
        ...boxes,
        { itemType, quantity: parsedQuantity, spaceId },
      ] as unknown as Partial<LeadBox>[];
    } else if (isZeroQuanity) {
      // Quantity === 0.
      boxes = boxes.filter((box) => box._id !== spaceBoxOfThisType?._id);
    } else {
      boxes = boxes.map((box) =>
        box.spaceId === spaceId && `${box.itemType}` === `${itemType}`
          ? {
              ...box,
              quantity: parsedQuantity,
            }
          : box,
      );
    }

    // const boxesInSpace = boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = lead.inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    dispatch(
      savePartialLead({
        boxes,
        rooms: lead.rooms.map((space) => {
          return space._id === spaceId
            ? {
                ...space,
                // noBoxes: false,
                isValid: isSpaceValid({
                  // space: {
                  //   ...space,
                  //   noBoxes: false,
                  // },
                  // inventory: lead.inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  };
};

export const saveStep = createAsyncThunk(
  'saveStep',
  async (
    {
      stepToSave,
      lead,
      stepToNagivateTo = '',
      pushHistory = true,
    }: {
      stepToSave: string;
      stepToNagivateTo: string | number;
      pushHistory?: boolean;
      lead?: PartialDeep<Lead>;
    },
    { dispatch, getState },
  ) => {
    const step = steps()[stepToSave];
    const state = getState() as RootState;
    const existingLead = state.lead;
    const currentLocation = get(state, 'app.currentLocation'); // STATE INJECTION

    dispatch(setUserMessage(null)); // Clear the message after every step

    const gaValues = getGAValues();

    // This only contains the updated values.
    let incomingLead = {
      email: existingLead.email,
      phone: existingLead.phone,
      locationID: existingLead.locationID,
      ...(lead || {}),
      ...gaValues,
      domainCountry: determineCountry(),
    } as PartialDeep<Lead>;

    // This contains the entire existing lead with all the updated fields as well.
    // Yes, this isn't immutable. But it's much easier to read.
    let updatedLead = { ...existingLead, ...incomingLead } as PartialDeep<Lead>;

    incomingLead.moveDriveDistance = updatedLead.moveDriveDistance =
      await calculateMoveDriveDistance({
        incomingLead,
        existingLead,
        updatedLead,
      });

    incomingLead.franchiseID = updatedLead.franchiseID = calculateFranchiseId({
      updatedLead,
    });

    incomingLead = handleFromLocationOrServiceChange({
      incomingLead,
      existingLead,
      stepToSave,
      updatedLead,
    });

    updatedLead = { ...updatedLead, ...incomingLead };

    incomingLead = {
      ...incomingLead,
      ...handleCustomerTypeDidChange({
        existingLead,
        updatedLead,
      }),
    };
    updatedLead = { ...updatedLead, ...incomingLead };

    incomingLead.roomBasedEstimate = updatedLead.roomBasedEstimate =
      calculateRoomBasedEstimate({
        currentLocation,
        updatedLead,
      });

    incomingLead.locationOK = updatedLead.locationOK = doubleCheckLocationOK({
      updatedLead,
    });

    incomingLead = {
      ...incomingLead,
      ...calculateFields({
        incomingLead,
        stepToSave,
        stepToNagivateTo,
        updatedLead,
      }),
    };

    updatedLead = { ...updatedLead, ...incomingLead };

    if (step.canBeFinal === true) {
      // Submit the entire lead to the backend if this is the end of the form.
      await dispatch(savePartialLead(updatedLead));
    } else {
      // @TODO: Once reconciliation is in place, switch this to incomingLead.
      dispatch(savePartialLead(updatedLead));
    }

    dispatch(
      processGtmEvents({
        stepToSave,
        updatedLead,
        existingLead,
        stepToNagivateTo: `${stepToNagivateTo}`,
      }),
    );
    if (stepToNagivateTo) {
      dispatch(
        setPercentageComplete(
          getPercentComplete(incomingLead.currentStep, incomingLead),
        ),
      );
      if (incomingLead.formComplete) {
        if (incomingLead.roomsStageComplete) {
          dispatch(fireGtmEvent('roomsComplete'));
        }
        if (incomingLead.inventoryStageComplete) {
          dispatch(fireGtmEvent('inventoryComplete'));
        }
      } else {
        if (pushHistory) {
          dispatch(push(incomingLead.currentStep as string));
        } else {
          dispatch(replace(incomingLead.currentStep as string));
        }
      }
    }
    // };
  },
);

// export const finishForm = ({ lead }: { lead: PartialDeep<Lead> }): AppThunk => {
//   return async (dispatch) => {
//     if (lead.roomsStageComplete) {
//       dispatch(fireGtmEvent('roomsComplete'));
//     }
//     if (lead.inventoryStageComplete) {
//       dispatch(fireGtmEvent('inventoryComplete'));
//     }
//   };
// };

export const setSkipSpaces = (): AppThunk => {
  return async (dispatch) => {
    const storageRoom = generateStorageSpace();
    dispatch(
      savePartialLead({
        oneSpace: true,
        rooms: [storageRoom],
      }),
    );

    dispatch(setInventorySpaceId(`${storageRoom._id}`));
  };
};

export const updateInventoryInSpace = createAsyncThunk(
  'updateInventoryInSpace',
  async (
    { itemType, spaceId, quantity }: SimpleInventory,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const lead = state.lead;

    const inventory = (lead.inventory || []).map((item) => {
      return item.spaceId === spaceId && `${item.itemType}` === `${itemType}`
        ? {
            ...item,
            quantity,
          }
        : item;
    });

    return dispatch(
      savePartialLead({
        inventory,
      }),
    );
  },
);

// export const updateInventoryInSpace2 = ({
//   itemType,
//   spaceId,
//   quantity,
// }: SimpleInventory): AppThunk => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const inventory = (lead.inventory || []).map((item) => {
//       return item.spaceId === spaceId && `${item.itemType}` === `${itemType}`
//         ? {
//             ...item,
//             quantity,
//           }
//         : item;
//     });

//     return dispatch(
//       savePartialLead({
//         inventory,
//       }),
//     );
//   };
// };

export const replaceInventoryInSpace = createAsyncThunk(
  'replaceInventoryInSpace',
  async (
    {
      originalItemType,
      newItemType,
      spaceId,
      quantity,
    }: {
      originalItemType: LeadInventory['itemType'];
      newItemType: LeadInventory['itemType'];
      spaceId: NonNullable<LeadInventory['spaceId']>;
      quantity: LeadInventory['quantity'];
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const lead = state.lead;

    const inventory = (
      incrementInventory({ lead, itemType: newItemType, spaceId, quantity }) ||
      []
    ).filter((item) => {
      return item.spaceId !== spaceId || item.itemType !== originalItemType;
    });

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    return dispatch(
      savePartialLead({
        inventory: inventory as LeadInventory[],
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === spaceId
            ? {
                ...space,
                // noInventory: false,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  },
);

export const removeOtherInventoryFromSpace = ({
  itemType,
  spaceId,
}: {
  itemType: LeadInventory['itemType'];
  spaceId: LeadInventory['spaceId'];
}): AppThunk<Promise<boolean>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const lead = state.lead;

    const inventoryItem = (lead.inventory || []).find((item) => {
      return item.spaceId === spaceId && item.itemType === itemType;
    });

    if (!inventoryItem) {
      logger.error('removeOtherInventoryFromSpace inventoryItem not found', {
        itemType,
        spaceId,
      });
      return false;
    }

    const tags = leadPhotoTagsBySpaceAndType({
      leadPhotos: lead.photos,
      spaceId: spaceId as unknown as ObjectId,
      itemType,
    });

    const otherQuantity = determineOtherQuantity({
      itemQuantity: inventoryItem.quantity,
      tags,
    });

    return dispatch(
      addInventory({
        itemType,
        spaceId,
        quantity: parseInt(`-${otherQuantity}`, 10),
      }),
    );
  };
};

export const setSpaceNoBoxes = createAsyncThunk(
  'setSpaceNoBoxes',
  async (
    {
      spaceId,
      // noBoxes,
    }: {
      spaceId: LeadRoom['_id'];
      noBoxes: LeadRoom['noBoxes'];
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const lead = state.lead;

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === `${spaceId}`;
    // });

    const inventoryInSpace = lead.inventory.filter((item) => {
      return item.spaceId === `${spaceId}`;
    });

    return dispatch(
      savePartialLead({
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === `${spaceId}`
            ? {
                ...space,
                // noBoxes,
                isValid: isSpaceValid({
                  // space: {
                  //   ...space,
                  //   noBoxes,
                  // },
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  },
);

export const removeSpace = (spaceId: LeadInventory['_id']): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const lead = state.lead;

    dispatch(
      savePartialLead({
        rooms: (lead.rooms || []).filter((room) => {
          return room._id !== spaceId;
        }),
        boxes: (lead.boxes || []).filter((item) => {
          return item.spaceId !== `${spaceId}`;
        }),
        inventory: (lead.inventory || []).filter((item) => {
          return item.spaceId !== `${spaceId}`;
        }),
        photos: (lead.photos || []).filter((photo) => {
          return photo.spaceId !== `${spaceId}`;
        }),
      }),
    );
  };
};

export const removePhoto = (photoId: LeadPhoto['_id']): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const lead = state.lead;
    const photo = lead.photos.find(
      (photo) => photo._id === photoId,
    ) as LeadPhoto;
    const spaceId = photo.spaceId;

    const photoInventory = Object.fromEntries(
      photo.tags
        .filter((tag) => typeof tag.quantity !== 'undefined')
        .map((tag) => [tag.itemType, tag.quantity]),
    );

    // Remove all inventory in this photo.
    const inventory = lead.inventory
      .map((item) => {
        return item.spaceId === spaceId &&
          typeof photoInventory[item.itemType] !== 'undefined'
          ? {
              ...item,
              quantity: item.quantity - photoInventory[item.itemType],
            }
          : item;
      })
      .filter((item) => item.quantity > 0);

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    dispatch(
      savePartialLead({
        inventory,
        photos: (lead.photos || []).filter((photo) => {
          return photo._id !== photoId;
        }),
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === `${spaceId}`
            ? {
                ...space,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  };
};

export const removePhotoTagInventory = ({
  photoId,
  itemType,
  tagId,
  spaceId,
}: {
  photoId: LeadPhoto['_id'];
  itemType: LeadInventory['itemType'];
  tagId: LeadPhotoTag['_id'];
  spaceId: LeadInventory['spaceId'];
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const lead = state.lead;
    let existingQuantity = 0;

    const photos = (lead.photos || []).map((photo) => {
      return photo._id !== photoId
        ? photo
        : {
            ...photo,
            tags: photo.tags.map((tag) => {
              const thisTag = tag._id === tagId;
              if (thisTag) {
                existingQuantity = tag.quantity as number;
              }
              return thisTag ? omit(tag, ['quantity', 'itemType']) : tag;
            }),
          };
    });

    const inventory = incrementInventory({
      lead,
      itemType,
      spaceId,
      quantity: parseInt(`-${existingQuantity}`, 10),
    });

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    dispatch(
      savePartialLead({
        photos,
        inventory: inventory as LeadInventory[],
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === spaceId
            ? {
                ...space,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  };
};

export const tagFurnitureInPhoto = createAsyncThunk(
  'tagFurnitureInPhoto',
  async (
    {
      photoId,
      itemType,
      tagId,
      quantity,
      spaceId,
      originalLabel,
    }: {
      photoId: LeadPhoto['_id'];
      itemType: LeadInventory['itemType'];
      tagId: LeadPhotoTag['_id'];
      spaceId: LeadInventory['spaceId'];
      quantity: NonNullable<LeadPhotoTag['quantity']>;
      originalLabel: LeadPhotoTag;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const lead = state.lead;
    const isNewTag = typeof originalLabel.itemType === 'undefined';
    const isDifferentType =
      !isNewTag && `${originalLabel.itemType}` !== `${itemType}`;
    const existingQuantity =
      !isDifferentType && !isNewTag ? (originalLabel?.quantity as number) : 0;

    const photos = (lead.photos || []).map((photo) => {
      return photo._id !== photoId
        ? photo
        : {
            ...photo,
            // A photo is considered reviewed when there's at least one tagged label.
            status: photoStatus.Reviewed,
            tags: photo.tags.map((tag) => {
              return tag._id === tagId
                ? {
                    ...tag,
                    quantity,
                    itemType,
                  }
                : tag;
            }),
          };
    }) as LeadPhoto[];

    const inventory = incrementInventory({
      lead,
      itemType,
      spaceId,
      quantity: quantity - existingQuantity,
    });

    // const boxesInSpace = lead.boxes.filter((item) => {
    //   return item.spaceId === spaceId;
    // });

    const inventoryInSpace = inventory.filter((item) => {
      return item.spaceId === spaceId;
    });

    return dispatch(
      savePartialLead({
        photos,
        inventory:
          // The tag changed types, so we remove the previous quantity from the previous inventory type.
          !isNewTag && isDifferentType
            ? (incrementInventory({
                lead: {
                  inventory: inventory as LeadInventory[],
                } as Lead,
                itemType: originalLabel.itemType as number,
                spaceId,
                quantity: parseInt(`-${originalLabel.quantity}`, 10),
              }) as LeadInventory[])
            : (inventory as LeadInventory[]),
        rooms: lead.rooms.map((space) => {
          return `${space._id}` === spaceId
            ? {
                ...space,
                isValid: isSpaceValid({
                  // space,
                  // inventory,
                  inventoryCount: inventoryInSpace.length,
                  // boxes: lead.boxes,
                  // boxCount: boxesInSpace.length,
                }),
              }
            : space;
        }),
      }),
    );
  },
);

export const handleBrowserNavigation = ({
  stepToSave,
  stepToNagivateTo = '',
}: {
  stepToSave: string;
  stepToNagivateTo: string | number;
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingLead = state.lead;
    const updatedLead = {
      existingLead,
      currentStep: stepToNagivateTo,
    };

    dispatch(setUserMessage(null)); // Clear the message after every step

    dispatch(
      savePartialLead({
        currentStep: `${stepToNagivateTo}`,
      }),
    );

    dispatch(processGtmEvents({ stepToSave, updatedLead, existingLead }));
    dispatch(
      setPercentageComplete(
        getPercentComplete(updatedLead.currentStep, updatedLead),
      ),
    );
  };
};
