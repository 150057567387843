import _ from 'lodash';

import { FIRST_STEP } from 'utils';
import types from 'constants/actionTypes';
import { saveToStore } from 'actions/leadActions';
import { getPercentComplete } from 'actions/actionUtilities';
import { fireGtmEvent } from 'actions/appActions';

// @TODO Move the rest over to leadActions.ts

/**
 * once the lead is in place, calculate, etc
 */
export function rehydrate() {
  return (dispatch, getStore) => {
    const store = getStore();
    // @TODO: Update
    const currentStep = _.get(
      store,
      'router.location.pathname',
      `/${FIRST_STEP}`,
    )
      .slice(1)
      .split('/')[0];
    return dispatch(
      setPercentageComplete(getPercentComplete(currentStep, store.lead)),
    );
  };
}

// export const setSkipSpaces = () => {
//   return async (dispatch) => {
//     const storageRoom = generateStorageSpace();
//     await dispatch(
//       savePartialLead({
//         oneSpace: true,
//         rooms: [storageRoom],
//       }),
//     );

//     dispatch(setInventorySpaceId(storageRoom._id));
//   };
// };

// export const updateInventoryInSpace = ({ itemType, spaceId, quantity }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const inventory = (lead.inventory || []).map((item) => {
//       return item.spaceId === spaceId && `${item.itemType}` === `${itemType}`
//         ? {
//             ...item,
//             quantity,
//           }
//         : item;
//     });

//     return dispatch(
//       savePartialLead({
//         inventory,
//       }),
//     );
//   };
// };

// export const replaceInventoryInSpace = ({
//   originalItemType,
//   newItemType,
//   spaceId,
//   quantity,
// }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const inventory = (
//       incrementInventory({ lead, itemType: newItemType, spaceId, quantity }) ||
//       []
//     ).filter((item) => {
//       return item.spaceId !== spaceId || item.itemType !== originalItemType;
//     });

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     return dispatch(
//       savePartialLead({
//         inventory,
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 noInventory: false,
//                 isValid: isSpaceValid({
//                   space,
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// };

// export const removeInventoryFromSpace = ({ itemType, spaceId }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const inventory = (lead.inventory || []).filter((item) => {
//       return item.spaceId !== spaceId || item.itemType !== itemType;
//     });

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     return dispatch(
//       savePartialLead({
//         inventory,
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 isValid: isSpaceValid({
//                   space,
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// };

// export const removeOtherInventoryFromSpace = ({ itemType, spaceId }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const inventoryItem = (lead.inventory || []).find((item) => {
//       return item.spaceId === spaceId && item.itemType === itemType;
//     });

//     const tags = leadPhotoTagsBySpaceAndType({
//       leadPhotos: lead.photos,
//       spaceId,
//       itemType,
//     });

//     const otherQuantity = determineOtherQuantity({
//       itemQuantity: inventoryItem.quantity,
//       tags,
//     });

//     return dispatch(
//       addInventory({
//         itemType,
//         spaceId,
//         quantity: parseInt(`-${otherQuantity}`, 10),
//       }),
//     );
//   };
// };

// export const setSpaceNoBoxes = ({ spaceId, noBoxes }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = lead.inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     return dispatch(
//       savePartialLead({
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 noBoxes,
//                 isValid: isSpaceValid({
//                   space: {
//                     ...space,
//                     noBoxes,
//                   },
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// };

// export const savePartialLead = (partialLead) => {
//   return (dispatch) => {
//     dispatch(apiSubmit(partialLead));
//     dispatch(saveToStore(partialLead));
//   };
// };

// export function removeSpace(spaceId) {
//   return (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;

//     dispatch(
//       savePartialLead({
//         rooms: (lead.rooms || []).filter((room) => {
//           return room._id !== spaceId;
//         }),
//         boxes: (lead.boxes || []).filter((item) => {
//           return item.spaceId !== spaceId;
//         }),
//         inventory: (lead.inventory || []).filter((item) => {
//           return item.spaceId !== spaceId;
//         }),
//         photos: (lead.photos || []).filter((photo) => {
//           return photo.spaceId !== spaceId;
//         }),
//       }),
//     );
//   };
// }

// export function removePhoto(photoId) {
//   return (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;
//     const photo = lead.photos.find((photo) => photo._id === photoId);
//     const spaceId = photo.spaceId;

//     const photoInventory = Object.fromEntries(
//       photo.tags
//         .filter((tag) => typeof tag.quantity !== 'undefined')
//         .map((tag) => [tag.itemType, tag.quantity]),
//     );

//     // Remove all inventory in this photo.
//     const inventory = lead.inventory
//       .map((item) => {
//         return item.spaceId === spaceId &&
//           typeof photoInventory[item.itemType] !== 'undefined'
//           ? {
//               ...item,
//               quantity: item.quantity - photoInventory[item.itemType],
//             }
//           : item;
//       })
//       .filter((item) => item.quantity > 0);

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     dispatch(
//       savePartialLead({
//         inventory,
//         photos: (lead.photos || []).filter((photo) => {
//           return photo._id !== photoId;
//         }),
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 isValid: isSpaceValid({
//                   space,
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// }

// export function removePhotoTagInventory({ photoId, itemType, tagId, spaceId }) {
//   return (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;
//     let existingQuantity = 0;

//     const photos = (lead.photos || []).map((photo) => {
//       return photo._id !== photoId
//         ? photo
//         : {
//             ...photo,
//             tags: photo.tags.map((tag) => {
//               const thisTag = tag._id === tagId;
//               if (thisTag) {
//                 existingQuantity = tag.quantity;
//               }
//               return thisTag ? omit(tag, ['quantity', 'itemType']) : tag;
//             }),
//           };
//     });

//     const inventory = incrementInventory({
//       lead,
//       itemType,
//       spaceId,
//       quantity: parseInt(`-${existingQuantity}`, 10),
//     });

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     dispatch(
//       savePartialLead({
//         photos,
//         inventory,
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 isValid: isSpaceValid({
//                   space,
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// }

// export function tagFurnitureInPhoto({
//   photoId,
//   itemType,
//   tagId,
//   quantity,
//   spaceId,
//   originalLabel,
// }) {
//   return (dispatch, getState) => {
//     const state = getState();
//     const lead = state.lead;
//     const isNewTag = typeof originalLabel.itemType === 'undefined';
//     const isDifferentType =
//       !isNewTag && `${originalLabel.itemType}` !== itemType;
//     const existingQuantity =
//       !isDifferentType && !isNewTag ? originalLabel?.quantity : 0;

//     const photos = (lead.photos || []).map((photo) => {
//       return photo._id !== photoId
//         ? photo
//         : {
//             ...photo,
//             // A photo is considered reviewed when there's at least one tagged label.
//             status: photoStatus.Reviewed,
//             tags: photo.tags.map((tag) => {
//               return tag._id === tagId
//                 ? {
//                     ...tag,
//                     quantity,
//                     itemType,
//                   }
//                 : tag;
//             }),
//           };
//     });

//     const inventory = incrementInventory({
//       lead,
//       itemType,
//       spaceId,
//       quantity: quantity - existingQuantity,
//     });

//     const boxesInSpace = lead.boxes.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     const inventoryInSpace = inventory.filter((item) => {
//       return item.spaceId === spaceId;
//     });

//     return dispatch(
//       savePartialLead({
//         photos,
//         inventory:
//           // The tag changed types, so we remove the previous quantity from the previous inventory type.
//           !isNewTag && isDifferentType
//             ? incrementInventory({
//                 lead: {
//                   inventory,
//                 },
//                 itemType: originalLabel.itemType,
//                 spaceId,
//                 quantity: parseInt(`-${originalLabel.quantity}`, 10),
//               })
//             : inventory,
//         rooms: lead.rooms.map((space) => {
//           return space._id === spaceId
//             ? {
//                 ...space,
//                 isValid: isSpaceValid({
//                   space,
//                   // inventory,
//                   inventoryCount: inventoryInSpace.length,
//                   // boxes: lead.boxes,
//                   boxCount: boxesInSpace.length,
//                 }),
//               }
//             : space;
//         }),
//       }),
//     );
//   };
// }

// Lightweight version of saveStep that just updates the currentStep.
// export function handleBrowserNavigation({ stepToSave, stepToNagivateTo }) {
//   return (dispatch, getState) => {
//     const state = getState();
//     const lead = {
//       ...state.lead,
//     };
//     dispatch(appActions.setUserMessage(null)); // Clear the message after every step
//     lead.currentStep = stepToNagivateTo;

//     return Promise.resolve(lead)
//       .then((l) => {
//         // dispatch(apiSubmit(l));
//         // dispatch(saveToStore(l));
//         dispatch(
//           savePartialLead({
//             currentStep: stepToNagivateTo,
//           }),
//         );
//         return l;
//       })
//       .then((l) => {
//         dispatch(processGtmEvents(stepToSave, l));
//         dispatch(
//           setPercentageComplete(
//             utilities.getPercentComplete(l.currentStep, lead),
//           ),
//         );
//       });
//   };
// }

export function markLocationOK(okay) {
  return saveToStore({
    locationOK: okay,
  });
}

export function setLanguage(language) {
  return saveToStore({
    language,
  });
}

export function setSelectedLocationMilage(milage) {
  return saveToStore({
    selectedLocationDistance: milage,
  });
}

export function setUpdating(updating) {
  return {
    type: types.SET_UPDATING,
    value: updating,
  };
}

/**
 * Private actions
 */

export function doubleCheckLocationOK({ updatedLead }) {
  return Boolean(
    updatedLead.locationID && updatedLead.franchiseID && updatedLead.locationOK,
  );
}

/**
 * Pass in a partial lead object to merge it into the store
 */
// export function saveToStore(detailsPartial) {
//   return {
//     type: types.SAVE_LEAD_DETAILS,
//     detailsPartial,
//   };
// }

// export function apiSubmit(lead) {
//   return (dispatch, getState) => {
//     const activeLeadID = getState().app.activeLeadID; // STATE INJECTION
//     if (activeLeadID) {
//       return dispatch(saveLead(lead));
//     }
//     return dispatch(createLead(lead));
//   };
// }

export function setPercentageComplete(pct) {
  return {
    type: types.SAVE_PERCENT_COMPLETE,
    payload: pct,
  };
}

/**
 * Check if any GTM events need to be fired
 */
export function processGtmEvents({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stepToSave,
  updatedLead,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  existingLead,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stepToNagivateTo = '',
}) {
  return (dispatch) => {
    if (
      updatedLead.inventoryStart === true ||
      updatedLead.currentStep === 'inventory'
    ) {
      dispatch(fireGtmEvent('inventoryStart'));
    }
    if (
      stepToSave === 'inventory' ||
      updatedLead.inventoryStageComplete === true
    ) {
      dispatch(fireGtmEvent('inventoryComplete'));
    }
  };
}
