import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({

});

const actionMenu = defineStyle({
  list: {
    minW: '120px',
    py: '0',
    borderEndRadius: '0',
  },
});

export default defineStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'actionMenu',
  },
  variants: {
    actionMenu,
  },
});
