import { CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';
import {
  ControllerFieldState,
  ControllerRenderProps,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  FormErrorMessage,
  FormControl,
  Radio,
  Heading,
  Flex,
  FormHelperText,
  Text,
  useColorModeValue,
  Box,
  RadioProps,
} from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Field = ControllerRenderProps<any, string>;

type Option = {
  value: string;
  label: string | JSX.Element;
  icon?: string | IconDefinition;
};

export type RhfStyledRadioFieldProps = {
  field: Field;
  fieldState: Pick<ControllerFieldState, 'isDirty' | 'error'>;
  label: string;
  isRequired?: boolean;
  isComplex?: boolean;
  isColumns?: boolean;
  options: Option[];
  additionalOptions?: Option[];
  suffix?: JSX.Element;
};

export const Option = ({
  option,
  field,
  isChecked,
  isDisabled,
  isColumns,
  isComplex,
}: {
  option: Option;
} & Pick<RadioProps, 'isChecked' | 'isDisabled'> &
  Pick<RhfStyledRadioFieldProps, 'isComplex' | 'isColumns' | 'field'>) => {
  const faPrimaryColor = useColorModeValue('#294a93', '#f2a900');
  const faCheckedSecondaryColor = useColorModeValue('white', 'black');
  const faUncheckedSecondaryColor = useColorModeValue('#294a93', '#f2a900');

  return (
    <Radio
      variant={isComplex ? 'column' : 'button'}
      w={isComplex && isColumns ? { base: '100%', sm: '50%' } : undefined}
      minW={!isComplex ? 150 : undefined}
      justifyContent="center"
      isChecked={isChecked}
      isDisabled={isDisabled}
      {...field}
      value={option.value}
    >
      {option.icon && typeof option.icon !== 'string' && (
        <FontAwesomeIcon
          icon={option.icon}
          style={
            isChecked
              ? ({
                  '--fa-primary-color': faCheckedSecondaryColor,
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-color': faCheckedSecondaryColor,
                  '--fa-secondary-opacity': 0.5,
                } as CSSProperties)
              : ({
                  '--fa-primary-color': faPrimaryColor,
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-color': faUncheckedSecondaryColor,
                  '--fa-secondary-opacity': 0.5,
                } as CSSProperties)
          }
        />
      )}
      {option.icon && typeof option.icon === 'string' && (
        <div className={`svg-container radio-svg`}>
          <ReactSVG src={option.icon} />
        </div>
      )}
      <Box as="span" flex={1}>
        {option.label}
      </Box>
      <Box className="checkmark" display="none">
        <FontAwesomeIcon icon={faCheckCircle} />
      </Box>
    </Radio>
  );
};

export const RhfStyledRadioField = ({
  label,
  options,
  additionalOptions,
  isRequired = false,
  isComplex = false,
  isColumns = true,
  field,
  fieldState,
  suffix,
}: RhfStyledRadioFieldProps) => {
  return (
    <FormControl
      id={field.name}
      isRequired={isRequired}
      as="fieldset"
      aria-describedby={isRequired ? `required-${field.name}` : undefined}
      mt={2}
    >
      <legend id={`legend-${field.name}`} style={{ width: '100%' }}>
        <Heading size="md" textAlign="center">
          {label}
          {isRequired && (
            <Text as="span" aria-hidden="true" color="red.400" ml={1}>
              *
            </Text>
          )}
        </Heading>
      </legend>
      <Flex
        aria-labelledby={`legend-${field.name}`}
        wrap="wrap"
        role="group"
        textAlign="left"
        my={2}
        {...(!isComplex && {
          direction: 'row',
          justify: 'center',
          gap: 2,
        })}
      >
        {options.map((option, index) => (
          <Option
            key={index}
            option={option}
            field={field}
            isChecked={
              Array.isArray(field.value)
                ? field.value.includes(option.value)
                : field.value === option.value
            }
            isColumns={isColumns}
            isComplex={isComplex}
          />
        ))}
        {suffix}
        {(additionalOptions || []).map((option, index) => (
          <Option
            key={index}
            option={option}
            field={field}
            isChecked={
              Array.isArray(field.value)
                ? field.value.includes(option.value)
                : field.value === option.value
            }
            isColumns={isColumns}
            isComplex={isComplex}
          />
        ))}
      </Flex>
      <FormErrorMessage>
        {fieldState.error && <p>{fieldState.error.message}</p>}
      </FormErrorMessage>
      {isRequired && (
        <FormHelperText layerStyle="requiredText" id={`required-${field.name}`}>
          <Text as="span" aria-hidden="true" color="red.400">
            *
          </Text>
          <Trans>This is a required field.</Trans>
        </FormHelperText>
      )}
    </FormControl>
  );
};

const RhfStyledRadioFieldContainer = ({
  label,
  options,
  isRequired,
  isComplex,
  isColumns,
  additionalOptions,
  suffix,
  ...props // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UseControllerProps<any> &
  Pick<
    RhfStyledRadioFieldProps,
    | 'label'
    | 'options'
    | 'isRequired'
    | 'isComplex'
    | 'isColumns'
    | 'additionalOptions'
    | 'suffix'
  >) => {
  const controler = useController(props);
  return (
    <RhfStyledRadioField
      label={label}
      options={options}
      additionalOptions={additionalOptions}
      suffix={suffix}
      isRequired={isRequired}
      isComplex={isComplex}
      isColumns={isColumns}
      {...controler}
    />
  );
};

export default RhfStyledRadioFieldContainer;
