import type { PartialDeep } from 'type-fest';

import { ObjectId } from '@backend/src/lib/lead';
import {
  Lead,
  LeadInventory,
  LeadRoom,
} from '@backend/src/interfaces/mongoose.gen';
import { isFromAddressBuildingTypeStorage } from '@backend/lib/lead/helperFunctions';
import untranslatedRooms from '@backend/lib/mwc/rooms';
import { createObjectId } from 'utils';

type SimpleInventoryItem = Pick<LeadInventory, 'spaceId'>;

type SimpleRoom = Pick<LeadRoom, '_id' | 'roomType' | 'optionalName'>;

export const generateStorageSpace = (): SimpleRoom => ({
  _id: createObjectId() as unknown as ObjectId,
  roomType: 27, // MWC Storage
  // optionalName: 'My Items',
});

export const defaultRoomTypeByCustomerType = ({
  customerType,
}: Pick<Lead, 'customerType'>): number => {
  const roomsByType = untranslatedRooms();
  const rooms = roomsByType[customerType as keyof typeof roomsByType];
  return Object.keys(rooms).find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (roomId) => (rooms as Record<string, any>)[roomId].default,
  ) as unknown as number;
};

export const generateDefaultSpace = ({
  customerType,
}: Pick<Lead, 'customerType'>): SimpleRoom => {
  return {
    _id: createObjectId() as unknown as ObjectId,
    roomType: defaultRoomTypeByCustomerType({ customerType }),
    // optionalName: 'My Items',
  };
};

export const itemsBySpace = <T extends SimpleInventoryItem>(
  spaceId: LeadRoom['_id'],
  items: T[],
): T[] =>
  (items || []).filter(
    (item) => item.spaceId === (spaceId as unknown as string),
  );

export const isSpaceInventoryValid = ({
  // space,
  inventoryCount,
}: {
  // space: Pick<LeadRoom, 'noInventory'>;
  inventoryCount: number;
}): boolean => {
  const valid = inventoryCount !== 0;
  return valid;
};

export const isSpaceBoxesValid = ({
  space,
  boxCount,
}: {
  space: Pick<LeadRoom, 'noBoxes'>;
  boxCount: number;
}): boolean => {
  const valid = boxCount !== 0 || space.noBoxes === true;
  return valid;
};

// A space is valid if the inventory and boxes are valid.
export const isSpaceValid = ({
  // space,
  // boxCount,
  inventoryCount,
}: {
  // space: Pick<LeadRoom, '_id' | 'noInventory' | 'noBoxes'>;
  // boxCount: number;
  inventoryCount: number;
}): boolean => {
  const inventoryValid = isSpaceInventoryValid({
    // space,
    inventoryCount,
  });

  return inventoryValid;

  // const boxesValid = isSpaceBoxesValid({
  //   space,
  //   boxCount,
  // });

  // return inventoryValid && boxesValid;
};

export const isLeadMultiSpace = (
  lead: PartialDeep<
    Pick<Lead, 'customerType' | 'fromAddress' | 'toAddress' | 'oneSpace'>
  >,
): boolean => {
  return lead.oneSpace !== true && !isFromAddressBuildingTypeStorage(lead);
};
