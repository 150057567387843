import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import pick from 'lodash/pick';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
  Controller,
} from 'react-hook-form';
import {
  Heading,
  Box,
  Flex,
  ListItem,
  OrderedList,
  Checkbox,
} from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import untranslatedSteps from '@backend/lib/lead/steps';
import untranslatedConcerns from '@backend/lib/mwc/concerns';
import { useFormDefaults } from 'lib/form';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import StepSummary from 'components/StepSummary';
import UlfFormStep from 'components/UlfFormStep';

const STEP_NAME = 'concerns';

const useSelectConcernsStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['concerns', 'hasConcerns']),
    ),
  );

export const ConcernsStepSummary = () => {
  const values = useSelectConcernsStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const concerns = untranslatedConcerns(t);
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {(values.concerns || []).length > 0 ? (
        <OrderedList>
          {(values.concerns || []).map((concern: keyof typeof concerns) => (
            <ListItem key={concern}>{concerns[concern].label}</ListItem>
          ))}
        </OrderedList>
      ) : (
        <Trans>None</Trans>
      )}
    </StepSummary>
  );
};

const rawConcerns = untranslatedConcerns();

const schema = z
  .object({
    ...Object.fromEntries(
      Object.keys(rawConcerns).map((value) => [value, z.boolean()]),
    ),
    no: z.boolean(),
  })
  .refine(
    (data) =>
      typeof Object.keys(data).find(
        (key) => (data as Dictionary<unknown>)[key] === true,
      ) !== 'undefined',
    {
      message: 'Please select an item',
      path: ['no'],
    },
  );

export type ConcernsStepFormValues = z.infer<typeof schema>;

export const ConcernsStepForm = ({
  onContinue,
  onSave,
}: UseStepMethodsReturn) => {
  const { t } = useTranslation();
  const concerns = untranslatedConcerns(t);
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { handleSubmit, formState, reset, getValues, control } =
    useFormContext<ConcernsStepFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <fieldset>
        <legend id="legend-services" style={{ width: '100%' }}>
          <Heading size="md" textAlign="center" display="none">
            <Trans>Concerns</Trans>
          </Heading>
        </legend>
        <Flex
          aria-labelledby="legend-services"
          wrap="wrap"
          role="group"
          textAlign="left"
          my={2}
        >
          {Object.entries(concerns).map(([value, option]) => {
            return (
              <Controller
                key={value}
                control={control}
                // Yes, this is weird due to TS.
                name={value as 'no'}
                render={({
                  field: { name, value: isChecked, onBlur, ref },
                }) => (
                  <Checkbox
                    ref={ref}
                    name={name}
                    value={name}
                    onBlur={onBlur}
                    isChecked={isChecked}
                    onChange={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { no, ...values } =
                        getValues() as Dictionary<boolean>;
                      const newValues = {
                        ...values,
                        [value]: !values[value],
                      };
                      reset({
                        ...newValues,
                        no: false,
                      });
                    }}
                    variant="column"
                    w={{ base: '100%', sm: '50%' }}
                  >
                    {option.icon && (
                      <div className={`svg-container checkbox-svg`}>
                        <ReactSVG src={option.icon} aria-hidden="true" />
                      </div>
                    )}
                    <Box as="span" flex={1}>
                      {option.label}
                    </Box>
                    <Box className="checkmark" display="none">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Box>
                  </Checkbox>
                )}
              />
            );
          })}
          <Controller
            control={control}
            name="no"
            render={({ field: { name, value, onBlur, ref } }) => (
              <Checkbox
                ref={ref}
                name={name}
                value={name}
                onBlur={onBlur}
                isChecked={value}
                onChange={() => {
                  const { no, ...values } = getValues() as Dictionary<boolean>;
                  reset({
                    no: !no,
                    ...Object.fromEntries(
                      Object.keys(values).map((value) => [value, false]),
                    ),
                  });
                }}
                variant="column"
                w={
                  Object.keys(concerns).length % 2
                    ? { base: '100%', sm: '50%' }
                    : '100%'
                }
              >
                <div className={`svg-container checkbox-svg`}>
                  <ReactSVG
                    src="/img/icon-concern-none.svg"
                    aria-hidden="true"
                  />
                </div>
                <Box as="span" flex={1}>
                  <Trans>I don't have any concerns</Trans>
                </Box>
                <Box className="checkmark" display="none">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
              </Checkbox>
            )}
          />
        </Flex>
      </fieldset>
    </UlfFormStep>
  );
};

const ConcernsStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { concerns, hasConcerns } = useSelectConcernsStepValues();

  const form = useForm<ConcernsStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      ...Object.fromEntries(
        Object.keys(rawConcerns).map((value) => [
          value,
          (concerns || []).includes(value as keyof typeof rawConcerns),
        ]),
      ),
      no: typeof hasConcerns !== 'undefined' ? !hasConcerns : undefined,
    },
  });

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<ConcernsStepFormValues> => {
    return async ({ no, ...values }: ConcernsStepFormValues) =>
      fn &&
      fn({
        concerns: Object.keys(values).filter(
          (v) => (values as Dictionary<unknown>)[v],
        ),
        hasConcerns: !no,
      });
  };

  return (
    <FormProvider {...form}>
      <ConcernsStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
      />
    </FormProvider>
  );
};

const ConcernsStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <ConcernsStep /> : <ConcernsStepSummary />;
};

export default ConcernsStepContainer;
