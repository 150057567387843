import _ from 'lodash';
import last from 'lodash/last';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import findKey from 'lodash/findKey';
import type { PartialDeep } from 'type-fest';

import untranslatedBuildingTypes from '../mwc/buildings';
import untranslatedServices from '../mwc/services';
import { Lead } from '../../src/interfaces/mongoose.gen';
import stages from './stages';

const buildingTypes = untranslatedBuildingTypes();
const services = untranslatedServices();

// export const hasMoveService = function (lead) {
//   return _.isArray(lead.services) && (lead.services || []).includes('move');
// };

// export const hasInternalService = function (lead) {
//   return _.isArray(lead.services) && (lead.services || []).includes('internal');
// };

// export const hasPackingService = function (lead) {
//   return _.isArray(lead.services) && (lead.services || []).includes('packing');
// };

// export const leadHasFromAddress = (lead: Lead): boolean =>
//   !isNil(lead.fromAddress) && !!lead.fromAddress.postalCode;

// export const leadConcernsFieldExists = (lead: Lead): boolean =>
//   !isNil(lead.concerns);

export const leadHasAddress = (
  lead: PartialDeep<Pick<Lead, 'fromAddress' | 'toAddress'>>,
  addressField: 'fromAddress' | 'toAddress',
): boolean => {
  const field = lead[addressField] as Required<Lead['fromAddress']>;
  return !isNil(field) && !!field.postalCode;
};

export const isBuildingTypeStorage = (
  lead: PartialDeep<Pick<Lead, 'customerType' | 'fromAddress' | 'toAddress'>>,
  addressField: 'fromAddress' | 'toAddress',
): boolean => {
  const field = lead[addressField] as Required<Lead['fromAddress']>;
  return (
    !isNil(lead.customerType) &&
    !isNil(field) &&
    !isNil(field.buildingTypeId) &&
    !isNil(buildingTypes[lead.customerType][field.buildingTypeId]) &&
    buildingTypes[lead.customerType][field.buildingTypeId].mwc === 'Storage'
  );
};

export const isFromAddressBuildingTypeStorage = (
  lead: PartialDeep<Pick<Lead, 'customerType' | 'fromAddress' | 'toAddress'>>,
): boolean => {
  return isBuildingTypeStorage(lead, 'fromAddress');
};

export const hasDestination = (lead: PartialDeep<Lead>): boolean =>
  (lead.services || []).filter(
    (service: string | number) => services[service].destination === true,
  ).length > 0;

export const shouldSkipSqFt = (lead: PartialDeep<Lead>): boolean => {
  const servicesThatSkipSqFt = (lead.services || []).filter(
    (service: string | number) => services[service].skipSqFt === true,
  );
  return (
    isFromAddressBuildingTypeStorage(lead) ||
    (servicesThatSkipSqFt.length > 0 &&
      servicesThatSkipSqFt.length === (lead.services || []).length)
  );
};

export const shouldSkipConcerns = (lead: PartialDeep<Lead>): boolean => {
  const servicesThatSkipConcerns = (lead.services || []).filter(
    (service: string | number) => services[service].skipConcerns === true,
  );
  return (
    !isCustomerTypeHome(lead) ||
    (servicesThatSkipConcerns.length > 0 &&
      servicesThatSkipConcerns.length === (lead.services || []).length)
  );
};

export const collectRoom = (lead: PartialDeep<Lead>): boolean =>
  (lead.services || []).filter(
    (service: string | number) => services[service].rooms === true,
  ).length > 0;

export const collectInventory = (lead: PartialDeep<Lead>): boolean =>
  (lead.services || []).filter(
    (service: string | number) => services[service].inventory === true,
  ).length > 0;

export const collectRoomAndInventory = (lead: PartialDeep<Lead>): boolean =>
  collectRoom(lead) && collectInventory(lead);

export const endsWithCTA = (lead: PartialDeep<Lead>): boolean => {
  return (
    isArray(lead.services) &&
    (lead.services || []).filter(
      (service: string | number) => services[service].callToAction !== true,
    ).length === 0
  );
};

// export const specialtyItemsForCustomerType = function (lead) {
//   return [];
//   // return _.isUndefined(lead.customerType) ? false : _.toPairs(mwc.rooms[lead.customerType]).filter(function (v) {
//   //   return v[1].specialty;
//   // }).length > 0;
// };

export const isCustomerTypeHome = function (lead: PartialDeep<Lead>): boolean {
  return !isUndefined(lead.customerType) && lead.customerType === 'home';
};

export const answeredRoomStart = function (lead: PartialDeep<Lead>): boolean {
  return !isUndefined(lead.roomsStart);
};

export const skipInventory = function (lead: PartialDeep<Lead>): boolean {
  return answeredRoomStart(lead) && lead.roomsStart === false;
};

export const mwcDateFormat = function (d: Date): string {
  return (
    ('00' + (d.getMonth() + 1)).slice(-2) +
    '/' +
    ('00' + d.getDate()).slice(-2) +
    '/' +
    d.getFullYear() +
    ' ' +
    ('00' + d.getHours()).slice(-2) +
    ':' +
    ('00' + d.getMinutes()).slice(-2) +
    ':' +
    ('00' + d.getSeconds()).slice(-2)
  );
};

export const mwcNameFormat = (v: string): string => {
  if (_.isNull(v)) {
    return '';
  }
  return v.trim().replace(/[^A-Z0-9˜\s.,-]/gi, '');
};

export const mwcPhoneFormat = (v: string): number => {
  return parseInt(v.replace(/\D/g, ''));
};

export const mwcAddressPremiseFormat = (
  v: string,
  lead: Partial<Pick<Lead, 'fromAddress' | 'toAddress'>>,
  addressField: 'fromAddress' | 'toAddress',
): string => {
  return [
    ...(_.isString(v) ? [v] : []),
    _.get(lead, `${addressField}.buildingUnit`, ''),
  ]
    .filter((x) => _.isString(x) && x.trim().length)
    .join(', ')
    .slice(0, 50);
};

/**
 * Returns a unique room name
 * @param {string} roomType
 * @param {string[]} existingRoomNames
 */
export const getUniqueRoomName = function (
  roomType: string,
  existingRoomNames: string[],
): string {
  const existingRoomBaseNames = map(
    existingRoomNames,
    (o) => isString(o) && o.replace(/\s\(.*\)/, ''),
  );
  if (existingRoomBaseNames.includes(roomType)) {
    const collisionValues = _(existingRoomBaseNames)
      .map((o, i) => {
        if (o == roomType) {
          const f = existingRoomNames[i].match(/.*\((.*)\)/);
          return f ? parseInt(f[1]) : null;
        }
        return null;
      })
      .without(null)
      .value();
    const newCounter = find(
      [...Array(9).keys()].slice(1),
      (o) => !collisionValues.includes(o),
    );
    return `${roomType} (${newCounter})`;
  }
  return roomType;
};

// https://stackoverflow.com/questions/20798477/how-to-find-the-indexes-of-all-occurrences-of-an-element-in-array/20798567#20798567
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAllIndexes<T>(arr: T[], val: T): number[] {
  const indexes = [];
  for (let i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
  return indexes;
}

/**
 * Returns a unique label suffix
 */
export const getUniqueIndex = (
  label: string,
  index: number,
  labels: string[],
): number => getAllIndexes(labels, label).indexOf(index);

/**
 * Returns a unique label
 */
export const getUniqueLabel = function (
  label: string,
  index: number,
  labels: string[],
): string {
  const thisIndex = getUniqueIndex(label, index, labels);

  if (thisIndex <= 0) {
    return label;
  }

  return `${label} (${thisIndex})`;
};

// export const addressToFull = function (address: Lead['fromAddress']): string {
//   return `${address.street || ''} ${address.premise || ''} ${
//     address.city || ''
//   }, ${address.region || ''} ${address.postalCode || ''} ${
//     address.country || ''
//   }`;
// };

// export const leadIsCanadian = function (lead: Lead): boolean {
//   return !isNil(lead.franchiseID) && lead.franchiseID.toString()[0] === '3';
// };

export const leadIsLocationOK = (lead: PartialDeep<Lead>): boolean => {
  return get(lead, 'locationOK', false);
};

// export const showStorageInventory = (lead: Lead): boolean =>
//   isFromAddressBuildingTypeStorage(lead) &&
//   !skipInventory(lead) &&
//   !endsWithCTA(lead) &&
//   collectRoomAndInventory(lead);

// export const showRoomsAndInventory = (lead: Lead): boolean =>
//   !isFromAddressBuildingTypeStorage(lead) &&
//   !skipInventory(lead) &&
//   !endsWithCTA(lead) &&
//   collectRoomAndInventory(lead);

export const showInventory = (lead: PartialDeep<Lead>): boolean =>
  !skipInventory(lead) && !endsWithCTA(lead) && collectRoomAndInventory(lead);

export const validBuildingFloor = (
  buildingTypeId: Lead['fromAddress']['buildingTypeId'] | string,
  buildingFloor: Lead['fromAddress']['buildingFloor'] | string,
): boolean => {
  const homeBuildingTypes = buildingTypes.home;
  const condoId = findKey(homeBuildingTypes, { mwc: 'Condo' });
  const appartmentId = findKey(homeBuildingTypes, { mwc: 'Apartment' });
  return (
    (buildingFloor ? `${buildingFloor}` : '').length > 0 ||
    (!isNil(buildingTypeId) &&
      ![`${condoId}`, `${appartmentId}`].includes(`${buildingTypeId}`))
  );
};

export const validAddressStep = (
  lead: Partial<Pick<Lead, 'fromAddress' | 'toAddress'>>,
  addressField: 'fromAddress' | 'toAddress',
): boolean => {
  return (
    get(lead, `${addressField}.autoCompleteSuggestion.id`, '').length > 0 &&
    validBuildingFloor(
      get(lead, `${addressField}.buildingTypeId`),
      get(lead, `${addressField}.buildingFloor`),
    )
  );
};

export const getLastCompletedStage = (
  lead: Partial<Pick<Lead, 'completeStages'>>,
): keyof typeof stages | undefined => {
  return (lead?.completeStages || []).length > 0
    ? (last(lead?.completeStages) as keyof typeof stages)
    : undefined;
};

export const limitTo50 = (v: unknown): string | undefined =>
  isString(v) ? v.slice(0, 50).trim() : undefined;
