import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Heading } from '@chakra-ui/react';

export const headerId = 'intro-header';

const Intro = ({ title, description, rawDescription, bodyContent }) => {
  // Optional property 'bodyContent' for steps with only static body copy
  // i.e. Call to Action
  const strongRE = /<strong>|<\/strong>/gi;
  const breakRE = /<br \/>/gi;

  const strong = description && description.toLowerCase().match(strongRE);
  const strongify = (descr) => {
    return descr.split(strongRE);
  };

  const descriptionArray = description && description.split(breakRE);

  return (
    <Box
      px={{ base: 4, md: 8 }}
      pt={{ base: 4, md: 8 }}
      pb={4}
      textAlign="center"
    >
      {title && (
        <Heading
          as="h1"
          id={headerId}
          // {...((description || rawDescription) && {
          //   'aria-describedby': 'intro-description',
          // })}
          color="gray.700"
          _dark={{ color: 'gray.200' }}
        >
          {title}
        </Heading>
      )}
      {rawDescription && (
        <Box className="intro-description" mb={4}>
          {rawDescription}
        </Box>
      )}
      {descriptionArray && (
        <Box className="intro-description" mb={4}>
          {descriptionArray.map((pCopy, i) => (
            <Text key={i}>
              {strong ? (
                strongify(pCopy).map((copy, idx) => ({
                  ...(idx % 2 === 0 ? (
                    <span key={idx}>{copy}</span>
                  ) : (
                    <strong key={idx}>{copy}</strong>
                  )),
                }))
              ) : (
                <span>{pCopy}</span>
              )}
            </Text>
          ))}
        </Box>
      )}
      {bodyContent && <Text>{bodyContent}</Text>}
    </Box>
  );
};

Intro.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.string,
  rawDescription: PropTypes.any,
  bodyContent: PropTypes.string,
};

export default Intro;
