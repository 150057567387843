import { zodResolver } from '@hookform/resolvers/zod';
import type { Dictionary } from 'lodash';
import { z } from 'lib/../i18n';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import { Heading, Checkbox, Text, Flex, Box } from '@chakra-ui/react';
import { faCheckCircle, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import untranslatedSteps from '@backend/lib/lead/steps';
import untranslatedPackingSupplies, {
  PackingSupply,
} from '@backend/lib/mwc/packing-supplies';
import { useFormDefaults } from 'lib/form';
import { useAppSelector } from 'store/configureStore';
import {
  selectLead,
  selectLeadBoxes,
  selectLeadInventory,
} from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import useBoxTypes from 'hooks/useBoxTypes';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import StepSummary from 'components/StepSummary';
import UlfFormStep from 'components/UlfFormStep';
import useInventoryDefinitions from 'hooks/useInventoryDefinitions';

const STEP_NAME = 'supplies';

const useSelectSuppliesStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['packingSupplies', 'wantsSupplies']),
    ),
  );

export const SuppliesStepSummary = () => {
  const values = useSelectSuppliesStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return <StepSummary stepKey={STEP_NAME} isValid={isValid}></StepSummary>;
};

const rawPackingSupplies = untranslatedPackingSupplies();

const schema = z
  .object({
    ...Object.fromEntries(
      Object.keys(rawPackingSupplies).map((value) => [value, z.boolean()]),
    ),
    other: z.boolean(),
    no: z.boolean(),
  })
  .refine(
    (data) =>
      typeof Object.keys(data).find(
        (key) => (data as Dictionary<unknown>)[key] === true,
      ) !== 'undefined',
    {
      message: 'Please select an item',
      path: ['no'],
    },
  );

export type SuppliesStepFormValues = z.infer<typeof schema>;

export const SuppliesStepForm = ({
  onContinue,
  onSave,
  packingSupplies,
  quantity,
}: UseStepMethodsReturn & {
  packingSupplies: Record<string, PackingSupply>;
  quantity: string;
}) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { handleSubmit, formState, control, getValues, reset } =
    useFormContext<SuppliesStepFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <UlfFormStep
      title={stepDefinition.title}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
      submitText={t('Get my quote!')}
      submitIcon={faPaperPlane}
    >
      <Text textAlign="center">{stepDefinition.description}</Text>
      {quantity && (
        <Text textAlign="center">
          <Trans quantity={quantity}>
            According to your inventory, you intend to pack your belongings
            using <strong>{quantity}</strong>.
          </Trans>
        </Text>
      )}
      <Text textAlign="center" mb={8}>
        <Trans>
          Just let us know what you're interested in and we'll reach out to you
          in advance of your service date.
        </Trans>
      </Text>
      <fieldset>
        <legend id="legend-supplies" style={{ width: '100%' }}>
          <Heading size="md" textAlign="center">
            <Trans>What supplies are you interested in purchasing?</Trans>
          </Heading>
        </legend>
        <Flex
          aria-labelledby="legend-supplies"
          wrap="wrap"
          role="group"
          textAlign="left"
          my={2}
        >
          {Object.entries(packingSupplies).map(([value, option]) => {
            return (
              <Controller
                key={value}
                control={control}
                // Yes, this is weird due to TS.
                name={value as 'no'}
                render={({
                  field: { name, value: isChecked, onBlur, ref },
                }) => (
                  <Checkbox
                    ref={ref}
                    name={name}
                    value={name}
                    onBlur={onBlur}
                    isChecked={isChecked}
                    onChange={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { no, ...values } =
                        getValues() as Dictionary<boolean>;
                      const newValues = {
                        ...values,
                        [value]: !values[value],
                      };
                      reset({
                        ...newValues,
                        no: false,
                      });
                    }}
                    variant="column"
                    w={{ base: '100%', sm: '50%' }}
                  >
                    {option.icon && (
                      <div className={`svg-container checkbox-svg`}>
                        <ReactSVG src={option.icon} aria-hidden="true" />
                      </div>
                    )}
                    <Box as="span" flex={1}>
                      {option.label}
                      {option.isRequired && (
                        <Text
                          fontSize={'xs'}
                          fontWeight="light"
                          fontStyle="italic"
                        >
                          <Trans>required for move</Trans>
                        </Text>
                      )}
                    </Box>
                    <Box className="checkmark" display="none">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Box>
                  </Checkbox>
                )}
              />
            );
          })}
          <Controller
            control={control}
            name="no"
            render={({ field: { name, value, onBlur, ref } }) => (
              <Checkbox
                ref={ref}
                name={name}
                value={name}
                onBlur={onBlur}
                isChecked={value}
                onChange={() => {
                  const { no, ...values } = getValues() as Dictionary<boolean>;
                  reset({
                    no: !no,
                    ...Object.fromEntries(
                      Object.keys(values).map((value) => [value, false]),
                    ),
                  });
                }}
                variant="column"
                w={
                  Object.keys(packingSupplies).length % 2
                    ? { base: '100%', sm: '50%' }
                    : '100%'
                }
              >
                <div className={`svg-container checkbox-svg`}>
                  <ReactSVG src={'/img/icon-supplies-provide-myself.svg'} />
                </div>
                <Box as="span" flex={1}>
                  <Trans>I'll provide everything myself</Trans>
                </Box>
                <Box className="checkmark" display="none">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
              </Checkbox>
            )}
          />
        </Flex>
      </fieldset>
    </UlfFormStep>
  );
};

// Filter packing supplies based on current inventory.
const usePackingSupplyOptions = () => {
  const { t } = useTranslation();
  const packingSuppliesDefinition = untranslatedPackingSupplies(t);
  const { data } = useInventoryDefinitions();
  const { inventoryKeyedById } = data || {};
  const leadInventory = useAppSelector(selectLeadInventory);
  const inventoryWithSupplies = leadInventory.filter(
    (item) =>
      inventoryKeyedById && inventoryKeyedById[item.itemType].supplies?.length,
  );

  const filteredPackingSupplyIds = Object.keys(
    packingSuppliesDefinition,
  ).filter((k) => {
    const packingSupply = packingSuppliesDefinition[k];
    return (
      packingSupply.isConditional !== true ||
      typeof inventoryWithSupplies.find(
        (item) =>
          inventoryKeyedById &&
          inventoryKeyedById[item.itemType].supplies?.includes(k),
      ) !== 'undefined'
    );
  });

  return pick(packingSuppliesDefinition, filteredPackingSupplyIds);
};

const useQuantity = () => {
  const { t } = useTranslation();
  const leadBoxes = useAppSelector(selectLeadBoxes);
  const leadBoxesByType = groupBy(leadBoxes, 'itemType');
  const boxTypes = useBoxTypes();
  const filteredBoxTypes = boxTypes.filter(
    (boxType) => leadBoxesByType[boxType.mwcId],
  );

  const filteredBoxTypesLength = filteredBoxTypes.length;

  const quantity = filteredBoxTypes
    .map((boxType, index) => {
      const boxesByThisType = leadBoxesByType[boxType.mwcId];
      const number = boxesByThisType.reduce(
        (acc, item) => acc + (item.quantity || 0),
        0,
      );
      let text = `${number} ${number === 1 ? boxType.singular : boxType.label}`;
      if (filteredBoxTypesLength > 2 && filteredBoxTypesLength > index + 1) {
        text += ',';
      }
      if (filteredBoxTypesLength > 1 && filteredBoxTypesLength === index + 1) {
        text = t('and') + ' ' + text;
      }
      return text;
    })
    .join(' ');

  return quantity;
};

const SuppliesStep = () => {
  const { packingSupplies, wantsSupplies } = useSelectSuppliesStepValues();
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const packingSupplyOptions = usePackingSupplyOptions();
  const quantity = useQuantity();

  const form = useForm<SuppliesStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      ...Object.fromEntries(
        Object.keys(rawPackingSupplies).map((value) => [
          value,
          (packingSupplies || []).includes(
            value as keyof typeof rawPackingSupplies,
          ),
        ]),
      ),
      no: typeof wantsSupplies !== 'undefined' ? !wantsSupplies : undefined,
    },
  });

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<SuppliesStepFormValues> => {
    return async ({ no, ...values }: SuppliesStepFormValues) =>
      fn &&
      fn({
        packingSupplies: Object.keys(values).filter(
          (v) => (values as Dictionary<unknown>)[v],
        ),
        wantsSupplies: !no,
      });
  };

  return (
    <FormProvider {...form}>
      <SuppliesStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
        packingSupplies={packingSupplyOptions}
        quantity={quantity}
      />
    </FormProvider>
  );
};

const SuppliesStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <SuppliesStep /> : <SuppliesStepSummary />;
};

export default SuppliesStepContainer;
