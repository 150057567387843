import { tmtLog } from './logging';

export function getIpLocation() {
  return ensureGeoIpConnectivity().then((geoip) => {
    return new Promise((resolve) => {
      geoip.city(
        (result) => {
          resolve(result.location);
          return;
        },
        (error) => {
          tmtLog(`GeoIp Lookup Error ${error}`);
          return;
        },
      );
    });
  });
}

const geoIp2Loader = () => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js';
    script.addEventListener('load', () => resolve(window.geoip2), false);
    script.addEventListener('error', () => reject(script), false);
    document.body.appendChild(script);
  });
};

const ensureGeoIpConnectivity = () => {
  return new Promise((resolve) => {
    if (typeof window.geoip2 !== 'undefined') {
      resolve(window.geoip2);
      return;
    }
    resolve(geoIp2Loader());
  });
};
