import { SubmitHandler } from 'react-hook-form';

import { INCOMPLETE_STEP } from '@backend/lib/lead/steps';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { saveStep } from 'actions/leadActions';
import { selectIsStepComplete } from 'slices/leadSlice';
import logger from 'logger';

export type UseStepMethodsReturn = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onContinue: SubmitHandler<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: SubmitHandler<any>;
};

const useStepMethods = (stepToSave: string): UseStepMethodsReturn => {
  const dispatch = useAppDispatch();
  const isStepComplete = useAppSelector((state) =>
    selectIsStepComplete(state, stepToSave),
  );

  // Continue = pick up where you left off. Go to next invalid OR if no invalid, go to last valid
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onContinue: SubmitHandler<any> = async (lead) => {
    // console.log('onContinue', lead);
    try {
      const result = await dispatch(
        saveStep({
          stepToSave,
          lead,
          stepToNagivateTo: INCOMPLETE_STEP,
        }),
      ).unwrap();
      return result;
    } catch (error) {
      logger.error('onContinue error', { error });
      throw error;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSave: SubmitHandler<any> = async (lead) => {
    // console.log('onSave', lead);
    try {
      const result = await dispatch(
        saveStep({
          stepToSave,
          lead,
          stepToNagivateTo: 1,
        }),
      ).unwrap();
      return result;
    } catch (error) {
      logger.error('onContinue error', { error });
      throw error;
    }
  };

  return {
    onContinue,
    onSave: isStepComplete ? onSave : undefined,
  };
};

export default useStepMethods;
