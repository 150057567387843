import { useMemo, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { UseComboboxStateChange, useCombobox } from 'downshift';
import debounce from 'lodash/debounce';

import { fetchMatchingGeocodedAddresses } from 'services/mapbox';
import RhfAutocompleteField, {
  RhfAutocompleteFieldProps,
} from './RhfAutocompleteField';
import { Trans } from 'react-i18next';

export const MANUAL_ADDRESS_ID = 'MANUAL_ADDRESS';
export const MANUAL_ADDRESS_KEY = 'MANUAL_ADDRESS';

export interface MapboxFeature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: Properties;
  text: string;
  place_name: string;
  center: number[];
  geometry: Geometry;
  address: string;
  context: Context[];
}

export interface Properties {
  accuracy: string;
  mapbox_id: string;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface Context {
  id: string;
  mapbox_id: string;
  text: string;
  wikidata?: string;
  short_code?: string;
}

export interface Suggestion extends MapboxFeature {
  label: string;
  // formattedSuggestion: {
  //   mainText: string;
  //   secondaryText: string;
  // };
}

export type RhfAddressAutocompleteFieldProps = Omit<
  RhfAutocompleteFieldProps,
  'combobox' | 'items' | 'isLoading'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchOptions: any;
};

export const RhfAddressAutocompleteField = ({
  searchOptions,
  ...props
}: RhfAddressAutocompleteFieldProps) => {
  const { field } = props;
  const [items, setItems] = useState<Suggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const { t } = useTranslation();

  const onInputValueChange = async ({
    inputValue,
  }: UseComboboxStateChange<Suggestion>) => {
    if (!inputValue) {
      setItems([]);
      setIsLoading(false);
      return;
    }

    const predictions = (await fetchMatchingGeocodedAddresses(
      inputValue,
      searchOptions,
    )) as MapboxFeature[];

    // const highlightFirstSuggestion = false;
    // const customAddressSuggestion = {
    //   id: MANUAL_ADDRESS_ID,
    //   description: '',
    //   placeId: MANUAL_ADDRESS_KEY,
    //   active: false,
    //   index: predictions.length,
    //   formattedSuggestion: {
    //     mainText: t(`Don't see your address?`),
    //     secondaryText: t('Enter your ZIP/Postal code instead'),
    //   },
    //   matchedSubstrings: '',
    //   terms: [],
    //   types: ['custom'],
    // };

    const suggestions: Suggestion[] = predictions.map((p) => ({
      ...p,
      label: p.place_name,
      // formattedSuggestion: {
      //   mainText: p.place_name,
      //   secondaryText: '',
      // },
      // id: p.id,
      // description: p.place_name,
      // placeId: p.id,
      // active: highlightFirstSuggestion && idx === 0 ? true : false,
      // index: idx,

      // matchedSubstrings: '',
      // terms: [],
      // types: p.place_type,
    }));

    // Disable manual zip code entry.
    // setItems(
    //   predictions.length > 0
    //     ? [...suggestions, customAddressSuggestion]
    //     : suggestions,
    // );

    setItems(suggestions);
    setIsLoading(false);
  };

  const debouncedOnInputValueChange = useMemo(
    () => debounce(onInputValueChange, 250),
    [],
  );
  const combobox = useCombobox({
    onSelectedItemChange: (item) =>
      item.selectedItem && field.onChange(item.selectedItem),
    onInputValueChange: (inputValue) => {
      setIsLoading(true);
      debouncedOnInputValueChange(inputValue);
    },
    items,
    inputId: field.name,
    initialInputValue: field.value?.place_name || '',
    itemToString(item) {
      return item ? item.place_name : '';
    },
    // stateReducer,
  });

  return (
    <RhfAutocompleteField
      {...props}
      isPrivate
      helpText={
        <>
          <Trans>
            Start typing your address and choose from the dropdown list.
          </Trans>{' '}
          <Trans>At least a postal code is required.</Trans>
        </>
      }
      combobox={combobox}
      items={items}
      isLoading={isLoading}
    />
  );
};

const RhfAddressAutocompleteFieldContainer = ({
  label,
  inputProps,
  isRequired,
  searchOptions,
  ...props // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UseControllerProps<any> &
  Pick<
    RhfAddressAutocompleteFieldProps,
    'label' | 'inputProps' | 'isRequired' | 'searchOptions'
  >) => {
  const controler = useController(props);
  return (
    <RhfAddressAutocompleteField
      label={label}
      inputProps={inputProps}
      isRequired={isRequired}
      searchOptions={searchOptions}
      {...controler}
    />
  );
};

export default RhfAddressAutocompleteFieldContainer;
