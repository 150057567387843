import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import get from 'lodash/get';
import { UseControllerReturn } from 'react-hook-form';
import { UseComboboxReturnValue } from 'downshift';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

import { shouldShowSuccess } from 'lib/form';
import FieldSuccessIcon from 'components/primatives/FieldSuccessIcon';

import {
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
} from 'components/primatives/Autocomplete';
import Loading from 'components/Loading';

export type RhfAutocompleteFieldProps = UseControllerReturn<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  string
> & {
  label: string;
  isRequired?: boolean;
  isPrivate?: boolean;
  inputProps?: InputProps & Record<`data-${string}`, string>;
  helpText?: string | JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  combobox: UseComboboxReturnValue<any>;
  items: {
    label: string;
  }[];
  isLoading: boolean;
  showIsLoading?: boolean;
};

export const RhfAutocompleteField = ({
  label,
  inputProps,
  isRequired = false,
  isPrivate = false,
  helpText,
  field,
  fieldState,
  formState,
  combobox,
  items,
  isLoading,
  showIsLoading = true,
}: RhfAutocompleteFieldProps) => {
  const { t } = useTranslation();
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = combobox;

  const { onChange, onBlur, ...comboBoxInputProps } = getInputProps({
    ref: field.ref,
  });

  const defaultValue = get(formState.defaultValues, field.name, '');
  const hadDefaultValue = !!defaultValue && defaultValue.length > 0;
  const showSuccess = shouldShowSuccess({ field, hadDefaultValue, fieldState });
  const isInvalid =
    (!isOpen || fieldState.isTouched) &&
    typeof fieldState.error !== 'undefined';

  return (
    <FormControl
      id={field.name}
      isRequired={isRequired}
      isInvalid={isInvalid}
      className={clsx(
        field.value && 'shrink-label',
        showSuccess && 'show-success',
      )}
      maxW="2xl"
      mx="auto"
      mb={8}
    >
      <Flex direction="column" position="relative">
        <Flex direction="column" flex="1 1 auto">
          <Flex direction="row" alignItems="baseline">
            <InputGroup>
              <ComboboxInput
                {...comboBoxInputProps}
                name={field.name}
                variant={showSuccess ? 'fancyValid' : 'fancy'}
                className={clsx(
                  field.value && 'shrink-label',
                  showSuccess && 'show-success',
                )}
                autoComplete="off"
                isOpen={isOpen && items.length > 0}
                data-1p-ignore
                data-private={isPrivate ? 'data-private' : undefined}
                {...inputProps}
                onBlur={(event) => {
                  field.onBlur();
                  onBlur && onBlur(event);
                }}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  onChange && onChange(event);
                }}
              />
              <FormLabel {...getLabelProps()}>{label}</FormLabel>
              <InputRightElement>
                {showSuccess && <FieldSuccessIcon />}
                {showIsLoading && isLoading && (
                  <Loading fontSize="inherit" color="inherit" />
                )}
              </InputRightElement>
            </InputGroup>
          </Flex>
          <ComboboxList
            {...getMenuProps()}
            isOpen={isOpen}
            py={0}
            data-test-id="autocomplete-combobox"
          >
            {items.map((item, index) => (
              <ComboboxItem
                key={index}
                {...getItemProps({ item, index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                data-private={isPrivate ? 'data-private' : undefined}
              >
                {item.label}
              </ComboboxItem>
            ))}
            {!showSuccess &&
              !isLoading &&
              field.value &&
              items.length === 0 && (
                <ComboboxItem
                  data-no-results="data-no-results"
                  key={'no-results'}
                  itemIndex={-1}
                  highlightedIndex={highlightedIndex}
                >
                  <em>{t('No results found.')}</em>
                </ComboboxItem>
              )}
          </ComboboxList>
        </Flex>
      </Flex>
      {isInvalid && (
        <FormErrorMessage variant="fancyField">
          {fieldState.error?.message}
        </FormErrorMessage>
      )}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default RhfAutocompleteField;
