/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const concerns = (t_fn?: any) => {
  const t = t_fn ? t_fn : (x: any) => x;

  return {
    'movers-care': {
      label: t('Care of movers'),
      icon: '/img/icon-concern-mover-care.svg',
      mwcID: 7,
      // mwc: 'Movers Care of Property',
      mwc: 'Care of Movers',
      conversationalReply: t(
        'Our movers always treat your property with care and respect.',
      ),
      customerContent: t(
        'Our team members are professionally trained and uniformed, background checked' +
          ', drug tested, and licensed before they handle your belongings, ensuring your items will be full' +
          'y protected from start to finish throughout the moving process.',
      ),
    },
    'hidden-fees': {
      label: t('Hidden fees'),
      icon: '/img/icon-concern-hidden-fees.svg',
      mwcID: 12,
      // mwc: 'Complicated Pricing and Hidden Fees',
      mwc: 'Hidden fees',
      conversationalReply: t("Worried about hidden fees? Don't be!"),
      customerContent: t(
        'What you see is what you get! We don’t believe in surprising customers with hidde' +
          'n fees for our services, and we stay in contact with you through each step of the moving process to ensur' +
          'e you’re up-to-date on everything, including price.',
      ),
    },
    price: {
      label: t('Cost of moving'),
      icon: '/img/icon-concern-cost.svg',
      mwcID: 12,
      // mwc: 'Price',
      mwc: 'Cost of Moving',
      conversationalReply: t(
        "Price is a concern for many people. Here's how we can help.",
      ),
      customerContent: t(
        'The price of moving is a major concern for those looking to relocate, but we alleviat' +
          'e some of that stress by offering the highest-quality moving services in the industry. We walk you throug' +
          'h each step of the estimate process so you’re up to speed on the price. Ask your move consultant for an' +
          'y money saving tips they might have, including weekday moves that are oftentimes cheaper than weekend rates.',
      ),
    },
    'new-home': {
      label: t('New home or lease'),
      icon: '/img/icon-concern-new-home-lease.svg',
      mwcID: 7,
      // mwc: 'Movers Care of Property',
      mwc: 'New home or lease',
      conversationalReply: t(
        'We take extra steps to ensure success when moving into a new space.',
      ),
      customerContent: t(
        'With more than 30 years of experience in the moving industry, we’ve learne' +
          'd a thing or two about the moving process. Pair that with the extensive training our team member' +
          's have, and you’re bound to have a seamless and stress-free experience.',
      ),
    },
    'movers-speed': {
      label: t('Speed of movers'),
      icon: '/img/icon-concern-mover-speed.svg',
      mwcID: 14,
      // mwc: 'Speed of Movers',
      mwc: 'Speed of movers',
      conversationalReply: t(
        "Concerned about moving speed? Let's get a move on!",
      ),
      customerContent: t(
        'We’ve all been there before – moving things on our own, which can take more time, m' +
          'oney, and even sore muscles. When you hire TWO MEN AND A TRUCK, you don’t have to worry! Our experience' +
          'd move teams have seen every sort of moving situation, and are pros when it comes to navigating tight c' +
          'orners or stairways. Let us do the heavy lifting for you.',
      ),
    },
    // 'movers-labor': {
    //   label: t('Movers - day labor'),
    //   icon: '/img/icon-concern-mover-day-labor.svg',
    //   mwcID: 17,
    //   // mwc: 'Trained Employees / No Day Labor',
    //   mwc: 'Movers - day labor',
    //   conversationalReply: t(
    //     'Worried about workers? You can trust us to move your belongings.',
    //   ),
    //   customerContent: t(
    //     'At TWO MEN AND A TRUCK, we never use the services of day labor or temporary mover' +
    //       's. Our movers are fully-trained, permanent employees who are eager to save the day and make your move a breeze.',
    //   ),
    // },
    'movers-training': {
      label: t('Trained movers / Day labor'),
      icon: '/img/icon-concern-mover-training.svg',
      // icon: '/img/icon-concern-mover-day-labor.svg',
      mwcID: 17,
      // mwc: 'Trained Employees / No Day Labor',
      mwc: 'Training of Movers',
      conversationalReply: t('Our movers undergo extensive training.'),
      customerContent: t(
        'Through years of service and our extensive company-wide training programs, you can r' +
          'est assured that when a member of our moving team steps into your home, they know what it takes to e' +
          'nsure your move is hassle-free.',
      ),
    },
    'movers-reliability': {
      label: t('Reliability of movers'),
      icon: '/img/icon-concern-mover-reliability.svg',
      mwcID: 13,
      // mwc: 'Reputation / Reliability',
      mwc: 'Reliability of movers',
      conversationalReply: t('Not to worry, you can rely on us.'),
      customerContent: t(
        'We know moving is a difficult process, and the last thing you want to worry about is u' +
          'nreliable movers. Our moving teams are dedicated to protecting your items, which includes padding and s' +
          'tretch wrapping to ensure their safety during the move.',
      ),
    },
    schedule: {
      label: t('Schedule and availability'),
      icon: '/img/icon-concern-schedule.svg',
      mwcID: 1,
      // mwc: 'Availability',
      mwc: 'Schedule and Availability',
      conversationalReply: t(
        'We will devote all our attention to your move around your schedule.',
      ),
      customerContent: t(
        'Be sure to ask your move consultant about extended hours, nights, or w' +
          'eekends if that’s what will work best with your schedule. For something as stressful as m' +
          'oving, we want to be sure scheduling isn’t a hassle for you.',
      ),
    },
    'last-minute': {
      label: t('Last minute move'),
      icon: '/img/icon-concern-last-minute-move.svg',
      mwcID: 1,
      // mwc: 'Availability',
      mwc: 'Last minute move',
      conversationalReply: t("Moving Last minute? We're ready in a snap!"),
      customerContent: t(
        'We know there are situations that require an urgent move, and we’re her' +
          'e to help. Give us a call when you know your move is coming up, and we’ll work with yo' +
          'u to find open times in the schedule and get you booked.',
      ),
    },
    'no-date': {
      label: t('No closing or move-in date'),
      icon: '/img/icon-concern-no-move-date.svg',
      mwcID: 1,
      // mwc: 'No Close / Lease Date',
      mwc: 'No closing or move-in date',
      conversationalReply: t(
        "We'll make sure you're ready when the time comes!",
      ),
      customerContent: t(
        "No closing date? Not sure when you'll have access to you" +
          'r new place? No worries! We can get your estimate going, and once you hav' +
          'e a date in mind, we can get that on the calendar for you.',
      ),
    },
  };
};

export default concerns;
