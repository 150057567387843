/**
 * A little helper for boilerplate constants used for action types, etc
 * @param {*} constants
 */
export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    return {
      ...acc,
      [constant]: constant,
    };
  }, {});
}

/**
 * provides a default room, or the active room for room steps
 * @param {*} rooms
 * @param {*} activeRoomIndex
 */
// export function ensureActiveRoom(rooms, activeRoomIndex) {
//   const defaultRoom = {
//     name: '',
//     optionalName: null,
//     roomType: null,
//   };
//   return rooms && rooms.length > activeRoomIndex
//     ? rooms[activeRoomIndex]
//     : defaultRoom;
// }

//Hexadecimal Operations
// export function Hex2Bin(hex) {
//   if (!hex) {
//     return null;
//   }
//   var bytes = [];
//   for (var i = 0; i < hex.length - 1; i += 2) {
//     bytes.push(parseInt(hex.substr(i, 2), 16));
//   }
//   return String.fromCharCode.apply(String, bytes);
// }

// export function hasPlaceholderSupport() {
//   return 'placeholder' in document.createElement('input');
// }

export function gtmLoad() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      // eslint-disable-next-line semi
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      '&gtm_auth=%REACT_APP_GTM_AUTH%&gtm_preview=%REACT_APP_GTM_PREVIEW%&gtm_cookies_win=x';
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GTM_KEY);
}

export const VITE_MODE = import.meta.env.MODE;

export const UFL_DOMAIN_EXT =
  window.location.href.search(/\.ca/) > -1 ? 'ca' : 'com';
// export const UFL_DOMAIN_EXT = 'ca'

export const UFL_PIPELINE = (
  import.meta.env.REACT_APP_PIPELINE || 'dev'
).replaceAll('ufl-', '');

export const UFL_STAGE = (
  import.meta.env.REACT_APP_PIPELINE || 'dev'
).replaceAll('-', '');

const uflEnvironments = {
  staging: 'staging',
  main: 'main',
  master: 'master',
};

const maxmindKeys = {
  dev: 'A0MJFMzaVmaJ',
  staging: 'A0MJFMzaVmaJ',
  prod: 'Ut3i07CrnQki',
};

const googleApiKeys = {
  dev: 'AIzaSyBfHdIabUZEmppQ-ZAq-XttGyYYYQJDXh0',
  staging: 'AIzaSyBfHdIabUZEmppQ-ZAq-XttGyYYYQJDXh0',
  main: 'AIzaSyCXaDG55KCvwECjzNeV5F0TyqGvlVVKTtQ',
  master: 'AIzaSyCXaDG55KCvwECjzNeV5F0TyqGvlVVKTtQ',
};

export const DEFAULT_FRANCHISE_ID = UFL_DOMAIN_EXT === 'ca' ? '3000' : '0000';

export const UFL_ENVIRONMENT = uflEnvironments[UFL_PIPELINE] || 'staging';

export const BUILD_TIME = import.meta.env.REACT_APP_BUILD_TIME || '';

export const GOOGLE_MAPS_API_KEY =
  googleApiKeys[UFL_ENVIRONMENT] || 'AIzaSyCXaDG55KCvwECjzNeV5F0TyqGvlVVKTtQ';

export const MAPBOX_KEY =
  'pk.eyJ1IjoidHJ1Y2tpZSIsImEiOiJjamtjemZmZXcwMGN4M3dubXV3dThnNXB4In0.1LdttlE9LE0x8Eh3Mj1uZw';

export const MAPBOX_COUNTRY_CODE = UFL_DOMAIN_EXT === 'ca' ? 'ca' : 'us';

export const HERE_COUNTRY_CODE =
  UFL_DOMAIN_EXT === 'ca' ? 'countryCode:CAN' : 'countryCode:USA';

export const MOVETRAC_COUNTRY_CODE = UFL_DOMAIN_EXT === 'ca' ? 'ca' : 'us';

export const MAXMIND_ACCOUNT_KEY = '135764';

export const MAXMIND_LICENSE_KEY =
  maxmindKeys[UFL_ENVIRONMENT] || 'Ut3i07CrnQki';

const GTM_KEY = 'GTM-PJMRZL';

export const API_ROOT =
  import.meta.env.REACT_APP_API_ROOT ||
  `http://${window.location.hostname}:3000/ufllocal`;

export const API_WEBSOCKET_ROOT =
  import.meta.env.REACT_APP_WEBSOCKET_ROOT ||
  `ws://${window.location.hostname}:3001`;

export const FIRST_STEP = 'contact';

export const FINISH_STEP = 'finish';

export const isDev = process.env.NODE_ENV !== 'production';
export const isProd = UFL_ENVIRONMENT === 'main';
export const validZipRegex =
  /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d|[A-Z]\d[A-Z]\s*$/i;

// https://stackoverflow.com/a/37438675/214001
export const createObjectId = (
  m = Math,
  d = Date,
  h = 16,
  s = (s) => m.floor(s).toString(h),
) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

export const isFileHeic = (file) => {
  // check file extension since windows returns blank mime for heic
  const x = file.type
    ? file.type.split('image/').pop()
    : file.name.toLowerCase().split('.').pop().toLowerCase();
  return x == 'heic' || x == 'heif';
};

export const displayFileName = (fileName) => {
  const fileNameParts = fileName.split('.');
  fileNameParts.pop();
  return fileNameParts.join('.');
};

export const determineCountry = () => {
  if (typeof window == 'undefined') {
    return 'us';
  }

  if (window.location.host.includes('localhost')) {
    return 'us';
  }

  const hostParts = window.location.host.split('.');
  const extension = hostParts[hostParts.length - 1];
  return extension === 'com' ? 'us' : extension;
};

export const uploadBucketName = isDev
  ? 'local-ulf-uploads'
  : `${UFL_STAGE}-uploads.${isProd ? 'twomenandatruck.com' : 'twomenbeta.com'}`;

export const awsRegion = 'us-east-2';

export const localS3Endpoint = 'http://127.0.0.1:9001';
