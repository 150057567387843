import { UnknownAction } from '@reduxjs/toolkit';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { locationApiClient } from '@backend/src/services/locationApi';
import types from 'constants/actionTypes';
import {
  CloseLocations,
  getCloseLocations,
  LocationApiLocation,
} from 'lib/locations';
import { AppThunk } from 'actions/leadActions';
import { fireGtmEvent } from 'actions/appActions';

export function updateCloseLocations(
  closeLocations: CloseLocations,
): UnknownAction {
  return {
    type: types.UPDATE_CLOSE_LOCATIONS,
    payload: closeLocations,
  };
}

export function updateCurrentLocation(
  location: LocationApiLocation,
): UnknownAction {
  return {
    type: types.UPDATE_CURRENT_LOCATION,
    payload: location,
  };
}

export const verifyCloseLocations = (
  address: Lead['fromAddress'],
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const closeLocations = state.app.closeLocations;
    if (
      address &&
      address.lat &&
      address.lon &&
      (!closeLocations || !closeLocations.length)
    ) {
      const locations = await getCloseLocations(address);
      dispatch(updateCloseLocations(locations));
    }
  };
};

export const verifyCurrentLocation = (
  locationID: Lead['locationID'],
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentLocation = state.app.currentLocation;
    if (locationID !== currentLocation?.id) {
      const { data: location } = await locationApiClient.GET(
        '/locationById/{locationId}',
        {
          params: {
            path: { locationId: (locationID || '').toString() },
          },
        },
      );

      if (!location) {
        throw new Error(`Unable to set franchiseNumber for ${locationID}`);
      }

      dispatch(updateCurrentLocation(location));

      const eventName = currentLocation ? 'locationChanged' : 'locationSet';
      dispatch(
        fireGtmEvent(eventName, { location }, eventName === 'locationSet'),
      );
    }
  };
};
