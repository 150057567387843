import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// import './index.css';
import components from './components';
import colors, { colorSemanticTokens } from './colors';
import { bodyFont, headingFont } from './fonts';
import * as layerStyles from './layerStyles';
// import * as layerStyles from './layerStyles';

export const theme = extendTheme({
  sizes: {
    ufl: {
      container: {
        md: '860px',
      },
      fieldWrapperMaxWidth: '400px',
    },
  },
  config: {
    // initialColorMode: 'light',
    // useSystemColorMode: false,
    initialColorMode: 'system',
    useSystemColorMode: true,
  },
  layerStyles,
  colors,
  // layerStyles,
  semanticTokens: {
    colors: colorSemanticTokens,
  },
  components,
  fonts: {
    // ...defaultTheme.fonts,
    heading: headingFont,
    body: bodyFont,
    // body: 'monospace',
    // mono: 'Menlo, monospace',
  },
  borders: {
    none: 0,
    '1px': '1px solid var(--chakra-border-color)',
    '2px': '2px solid var(--chakra-border-color)',
    '4px': '4px solid var(--chakra-border-color)',
    '8px': '8px solid var(--chakra-border-color)',
  },
  shadows: {
    outline: '0 0 0 4px var(--chakra-focus-outline-color)',
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      'chakra *, chakra *::before, chakra ::after': {
        borderColor: 'var(--chakra-border-color)',
      },
      ':host, :root, [data-theme]': {
        '--chakra-border-color': mode(
          'var(--chakra-colors-borderColor)',
          'var(--chakra-colors-darkBorderColor)',
        )(props),
        '--chakra-focus-outline-color': mode(
          'var(--chakra-colors-focusOutlineColor)',
          'var(--chakra-colors-darkFocusOutlineColor)',
        )(props),
      },
      html: {
        padding: 0,
      },
      body: {
        padding: '0 !important',
        color: mode('bodyFontColor', 'darkBodyFontColor')(props),
        bg: mode('bodyBgColor', 'darkBodyBgColor')(props),
      },
    }),
  },
});

export default theme;
