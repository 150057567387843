import { Box, Text, Link } from '@chakra-ui/react';

import UlfLayoutContainer from './UlfLayoutContainer';
import { Trans } from 'react-i18next';

const Disclaimer = () => {
  return (
    <UlfLayoutContainer>
      <Box layerStyle="uflContainerMd" my={4}>
        <Text textAlign="center">
          <Trans>
            By completing this form, I acknowledge and agree that my information
            will be collected and processed in accordance with TWO MEN AND A
            TRUCK's{' '}
            <Link
              variant="unstyled"
              href="https://www.servicemaster.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Trans>
        </Text>
      </Box>
    </UlfLayoutContainer>
  );
};

export default Disclaimer;
