import { useEffect, useRef, useCallback } from 'react';
// import { useInView } from 'react-intersection-observer';

const useScrollIntoView = (): ((node: HTMLDivElement) => void) => {
  const ref = useRef<HTMLDivElement>();
  // const { ref: inViewRef, inView } = useInView({
  //   /* Optional options */
  //   threshold: 1,
  // });

  // useEffect(() => {
  //   if (!inView && ref.current) {
  //     ref.current.scrollIntoView({
  //       behavior: 'smooth',
  //       // block: "end",
  //       inline: 'nearest',
  //     });
  //   }
  // }, [inView]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        // block: "end",
        inline: 'nearest',
      });
    }
  }, []);

  const setRefs = useCallback((node: HTMLDivElement) => {
    // Ref's from useRef needs to have the node assigned to `current`
    ref.current = node;
    // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
    // inViewRef(node);
  }, []);

  return setRefs;
};

export default useScrollIntoView;
