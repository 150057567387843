import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
} from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

import untranslatedSteps from '@backend/lib/lead/steps';
import { useFormDefaults } from 'lib/form';
import { selectLead } from 'slices/leadSlice';
import { useAppSelector } from 'store/configureStore';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import StepSummary from 'components/StepSummary';
import RhfTextField from 'components/fields/RhfTextField';
import RhfStyledRadioField from 'components/fields/RhfStyledRadioField';
import UlfFormStep from 'components/UlfFormStep';

const STEP_NAME = 'additionalStops';

const useSelectAdditionalStopsStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['hasAdditionalStops', 'additionalStops']),
    ),
  );

export const AdditionalStopsStepSummary = () => {
  const values = useSelectAdditionalStopsStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {values.hasAdditionalStops === false && <Trans>None</Trans>}
      {values.hasAdditionalStops === true && (
        <>
          {values.additionalStops || 0}&nbsp;
          {values.additionalStops === 1 ? (
            <Trans>Stop</Trans>
          ) : (
            <Trans>Stops</Trans>
          )}
        </>
      )}
    </StepSummary>
  );
};

const schema = z
  .object({
    hasAdditionalStops: z.string(),
    additionalStops: z.string().optional(),
  })
  .superRefine(({ hasAdditionalStops, additionalStops }, refinementContext) => {
    if (
      hasAdditionalStops === '1' &&
      (isNil(additionalStops) || (additionalStops || '').length === 0)
    ) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        params: { i18n: 'required' },
        path: ['additionalStops'],
      });
    }
    if (!isNil(additionalStops) && (additionalStops || '').length > 0) {
      try {
        const value = parseInt(additionalStops, 10);
        if (value < 1) {
          throw new Error('Not positive');
        }
      } catch (error) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          params: { i18n: 'positive_integer' },
          path: ['additionalStops'],
        });
      }
    }
  });

export type AdditionalStopsStepFormValues = z.infer<typeof schema>;

export const AdditionalStopsStepForm = ({
  onContinue,
  onSave,
}: UseStepMethodsReturn) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { watch, handleSubmit, formState, control } =
    useFormContext<AdditionalStopsStepFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <RhfStyledRadioField
        label={t('Additional stops?')}
        isRequired={true}
        control={control}
        name="hasAdditionalStops"
        options={[
          {
            value: '1',
            label: <Trans>Yes</Trans>,
            icon: faCheck,
          },
          {
            value: '0',
            label: <Trans>No</Trans>,
            icon: faXmark,
          },
        ]}
      />
      {watch('hasAdditionalStops') === '1' && (
        <Box mt={8}>
          <RhfTextField
            label={t('Number of additional stops')}
            control={control}
            name="additionalStops"
            isRequired
            showSuccess={false}
            inputProps={{
              type: 'number',
              min: 1,
            }}
          />
        </Box>
      )}
    </UlfFormStep>
  );
};

const AdditionalStopsStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { hasAdditionalStops, additionalStops } =
    useSelectAdditionalStopsStepValues();

  const form = useForm<AdditionalStopsStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      additionalStops: additionalStops ? `${additionalStops}` : undefined,
      hasAdditionalStops:
        typeof hasAdditionalStops !== 'undefined'
          ? `${Number(hasAdditionalStops)}`
          : undefined,
    },
  });

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<AdditionalStopsStepFormValues> => {
    return async ({
      additionalStops,
      hasAdditionalStops,
    }: AdditionalStopsStepFormValues) =>
      fn &&
      fn({
        additionalStops: additionalStops
          ? parseInt(additionalStops, 10)
          : undefined,
        hasAdditionalStops: hasAdditionalStops
          ? Boolean(parseInt(hasAdditionalStops, 10))
          : undefined,
      });
  };

  return (
    <FormProvider {...form}>
      <AdditionalStopsStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
      />
    </FormProvider>
  );
};

const AdditionalStopsStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? (
    <AdditionalStopsStep />
  ) : (
    <AdditionalStopsStepSummary />
  );
};

export default AdditionalStopsStepContainer;
