import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { column } from './radio';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    alignItems: 'top',
  },
  control: {
    marginTop: 1,
    _checked: {
      bg: 'tmtBlue',
      borderColor: 'tmtBlue',
      _hover: {
        bg: 'blue.700',
        borderColor: 'blue.700',
      },
    },
    _dark: {
      _checked: {
        bg: 'tmtGold',
        borderColor: 'tmtGold',
        _hover: {
          bg: 'orange.400',
          borderColor: 'orange.400',
        },
      },
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    column,
  },
});
