import { UFL_ENVIRONMENT } from 'utils';

// All of them initially, but expect this to be paired back to master
const shouldLog =
  UFL_ENVIRONMENT === 'master' ||
  UFL_ENVIRONMENT === 'main' ||
  UFL_ENVIRONMENT === 'dev' ||
  UFL_ENVIRONMENT === 'staging';

export function tmtLog(message, severity = 'error') {
  if (!shouldLog) {
    return;
  }
  ensureLoggingConnectivity()
    .then((tracker) => {
      if (severity !== 'error') {
        return tracker.push({
          message: message,
        });
      } else {
        return tracker.push({
          message: message,
        });
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Logging Failed', e);
    });
}

function initializeLogging(tracker) {
  tracker.push({
    logglyKey: 'efdf1c9f-8cb2-4f9b-bce4-a402ce4153da',
    sendConsoleErrors: true,
    tag: 'loggly-jslogger',
  });
  return tracker;
}

const ensureLoggingConnectivity = () => {
  return new Promise((resolve) => {
    if (window._LTracker) {
      resolve(window._LTracker);
      return;
    }
    resolve(logglyLoader());
  }).then((tracker) => {
    return initializeLogging(tracker);
  });
};

const logglyLoader = () => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js';
    script.addEventListener('load', () => resolve(window._LTracker), false);
    script.addEventListener('error', () => reject(script), false);
    document.body.appendChild(script);
  });
};
