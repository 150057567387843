import { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useCookies } from 'react-cookie';
import trimStart from 'lodash/trimStart';
import { Route, Switch } from 'wouter';

import logger from 'logger';
import {
  fetchGeoIpLocation,
  fireGtmEvent,
  // setWebSocketReadyState,
} from 'actions/appActions';
import { handleBrowserNavigation } from 'actions/leadActions';
import { verifyCurrentLocation } from 'actions/locationActions';
import {
  selectLeadCurrentStep,
  selectLeadId,
  selectLeadLocationId,
  selectLeadVisibleSteps,
} from 'slices/leadSlice';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
// import useWebsocket from 'hooks/useWebsocket';
import Reauth from 'components/Reauth';
import Continue from 'components/Continue';
import MainContentContainer from 'components/MainContentContainer';
import ErrorCard from 'components/ErrorCard';
import Disclaimer from 'components/Disclaimer';

const App = () => {
  const dispatch = useAppDispatch();
  // const { readyState } = useWebsocket();
  const [cookies, setCookie] = useCookies();
  const routerState = useAppSelector((state) => state.router);
  const leadId = useAppSelector(selectLeadId);
  const leadLocationId = useAppSelector(selectLeadLocationId);
  const visibleSteps = useAppSelector(selectLeadVisibleSteps);
  const currentStep = useAppSelector(selectLeadCurrentStep);

  const routerStateAction = routerState.action;
  const routerStateLocationPathname = routerState.location?.pathname;
  const routerStateLocationSearch = routerState.location?.search;
  const cookieLocationId = cookies.tmt_location_id;

  useEffect(() => {
    if (leadId) {
      dispatch(fireGtmEvent('leadIdAssigned', { leadId }));
    } else {
      dispatch(fireGtmEvent('leadStart'));
    }
  }, [leadId]);

  // @TODO: Enable when reconciliation and photos is ready.cc
  // useEffect(() => {
  //   //Sync the websocket state to Redux.
  //   dispatch(setWebSocketReadyState(readyState));
  // }, [readyState]);

  const setLocationCookie = useCallback(
    (locationId: string) => {
      if (locationId !== cookieLocationId) {
        // logger.info('Setting new TMT location cookie', locationId);

        setCookie('tmt_location_id', locationId, {
          maxAge: 3888000,
          path: '/',
          domain:
            '.' +
            window.location.hostname.replace(/^.*\.([^.]+\.[^.]+)$/, '$1'),
        });
      }
    },
    [cookieLocationId],
  );

  useEffect(() => {
    // Handle browser forward/backward buttons.
    if (routerStateAction === 'POP') {
      const routerCurrentPathName = routerStateLocationPathname
        ?.slice(1)
        .split('/')[0];
      if (
        visibleSteps.includes(routerCurrentPathName as string) &&
        currentStep !== routerCurrentPathName
      ) {
        logger.info('handleBrowserNavigation', {
          currentStep,
          routerCurrentPathName,
        });
        dispatch(
          handleBrowserNavigation({
            stepToSave: `${currentStep}`,
            stepToNagivateTo: `${routerCurrentPathName}`,
          }),
        );
      }
    }
  }, [
    visibleSteps,
    currentStep,
    routerStateAction,
    routerStateLocationPathname,
  ]);

  useEffect(() => {
    // If location is in URL, set the location in the cookie.
    const trimmedQS = trimStart(routerStateLocationSearch || '', '?');
    // const segmentArray = location.split('/', 2) || '';
    // const firstSegment = segmentArray.length >= 2 ? segmentArray[1] : 'name';

    if (trimmedQS) {
      const params = new URLSearchParams(trimmedQS);
      const locationId = params.get('location');
      if (locationId) {
        // logger.info('url location id is set and/or changed', leadLocationId);
        setLocationCookie(locationId);
      }
    }
  }, [routerStateLocationSearch]);

  useEffect(() => {
    if (leadLocationId) {
      // logger.info('lead location id is set and/or changed', leadLocationId);
      setLocationCookie(`${leadLocationId}`);
    }
  }, [leadLocationId]);

  useEffect(() => {
    // If the cookie has a location ID, make sure Redux has that location.
    if (cookieLocationId) {
      // logger.info('cookie location id is set and/or changed', cookieLocationId);
      dispatch(verifyCurrentLocation(cookieLocationId));
    }
  }, [cookieLocationId]);

  useEffect(() => {
    // dispatch(fetchClosedDates());
    dispatch(fetchGeoIpLocation());
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      <Switch>
        <Route path="/reauth">
          <Reauth />
          <Disclaimer />
        </Route>
        <Route path="/continue">
          <Continue />
          <Disclaimer />
        </Route>
        <Route>
          <MainContentContainer />
          <Disclaimer />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
