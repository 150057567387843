import _ from 'lodash';

import { DEFAULT_FRANCHISE_ID } from 'utils';

export function getPercentComplete(stepName, lead) {
  if (!lead.validSteps || lead.validSteps.length <= 0) {
    return 0;
  }
  const currentIndex = lead.validSteps.indexOf(stepName);
  return (currentIndex / lead.validSteps.length) * 100;
}

export function getGAValues() {
  const gaInfo = localStorage.getItem('persistDL');
  if (!gaInfo) {
    return {};
  }
  const gaJSON = JSON.parse(gaInfo);
  if (!_.get(gaJSON, 'session', null)) {
    return {};
  }
  return {
    source: _.get(gaJSON, 'session.source', ''),
    medium: _.get(gaJSON, 'session.medium', ''),
    campaign: _.get(gaJSON, 'session.campaign', ''),
    term: _.get(gaJSON, 'session.term', ''),
  };
}

// export function checkIfLocationIsOK(lead, closeLocations, currentLocation) {
//   const allowedDistance =
//     parseInt(_.get(currentLocation, 'serviceArea.distance', '30'), 10) || 30;
//   const locationID = _.toString(_.get(lead, 'locationID', 0));
//   const foundLocation = _.find(closeLocations, { locationId: locationID });
//   const csrInitiated = !_.isNull(lead.csrInitiated) && lead.csrInitiated;
//   return (
//     csrInitiated ||
//     (!_.isNil(foundLocation) && foundLocation.distance <= allowedDistance)
//   );
// }

export function isMissingLocationInformation(lead, currentStep) {
  const validSteps = _.get(lead, 'validSteps', []);
  const currentStepIndex = validSteps.indexOf(currentStep);
  const locationStepIndex = validSteps.indexOf('locationSelector');
  return (
    locationStepIndex > -1 &&
    (!_.get(lead, 'locationID', null) || !_.get(lead, 'franchiseID', null)) &&
    currentStepIndex > locationStepIndex
  );
}

export const calculateFranchiseId = ({ updatedLead }) => {
  // If locationId is set but not franchiseID, set a default franchise ID.
  const franchiseID =
    !_.isNil(_.get(updatedLead, 'locationID', null)) &&
    (_.isNil(updatedLead.franchiseID) || updatedLead.franchiseID === '0000')
      ? _.get(updatedLead, 'franchiseID', DEFAULT_FRANCHISE_ID)
      : updatedLead.franchiseID;

  return franchiseID;
};

// export const handleFromLocationOrServiceChange = ({
//   incomingLead,
//   existingLead,
//   stepToSave,
//   updatedLead,
// }) => {
//   if (
//     fromLocationDidChange(updatedLead, existingLead) ||
//     stepToSave === 'fromAddress' ||
//     stepToSave === 'services'
//   ) {
//     const sameAddressCondition =
//       _.isArray(updatedLead.services) &&
//       (!_.includes(updatedLead.services, 'move') ||
//         (_.includes(updatedLead.services, 'move') &&
//           _.includes(updatedLead.services, 'internal')));

//     const existingFromAddressBuildingId =
//       existingLead.fromAddress?.buildingTypeId;
//     const newFromAddressBuildingId = updatedLead.fromAddress?.buildingTypeId;
//     const changedFromBuildingTypes =
//       existingFromAddressBuildingId !== newFromAddressBuildingId;
//     const wasStorage = isFromAddressBuildingTypeStorage(existingLead);
//     const isNowStorage = isFromAddressBuildingTypeStorage(updatedLead);
//     const wasOrIsStorage = wasStorage || isNowStorage;

//     if (!updatedLead.oneSpace && changedFromBuildingTypes && wasOrIsStorage) {
//       const newRoom = isNowStorage
//         ? generateStorageSpace()
//         : generateDefaultSpace(updatedLead);

//       //Move existing items into new space.
//       if (updatedLead.rooms.length) {
//         incomingLead.inventory = updatedLead.inventory.map((item) => ({
//           ...item,
//           spaceId: newRoom._id,
//         }));
//         incomingLead.boxes = updatedLead.boxes.map((item) => ({
//           ...item,
//           spaceId: newRoom._id,
//         }));
//         incomingLead.photos = updatedLead.photos.map((item) => ({
//           ...item,
//           spaceId: newRoom._id,
//         }));
//       }
//       incomingLead.rooms = [newRoom];
//     }

//     return {
//       ...incomingLead,
//       toAddress: sameAddressCondition
//         ? updatedLead.fromAddress
//         : updatedLead.toAddress,
//       moveDriveDistance: sameAddressCondition
//         ? 0
//         : updatedLead.moveDriveDistance,
//     };
//   }

//   return incomingLead;
// };

export const handleCustomerTypeDidChange = ({ existingLead, updatedLead }) => {
  if (customerTypeDidChange(updatedLead, existingLead)) {
    return {
      fromAddress: _.isObjectLike(updatedLead.fromAddress)
        ? {
            ...updatedLead.fromAddress,
            buildingTypeId: null,
            buildingFloor: null,
            buildingComplex: null,
            buildingUnit: null,
          }
        : null,
      toAddress: _.isObjectLike(updatedLead.toAddress)
        ? {
            ...updatedLead.toAddress,
            buildingTypeId: null,
            buildingFloor: null,
            buildingComplex: null,
            buildingUnit: null,
          }
        : null,
      rooms: [],
      photos: [],
      inventory: [],
      boxes: [],
      supplies: [],
    };
  }

  return {};
};

export function selectedLocationDidChange(newLead, existingLead) {
  if (_.isUndefined(newLead.locationID)) {
    return false;
  }
  return (
    _.get(newLead, 'locationID', '') !== _.get(existingLead, 'locationID', '')
  );
}

/**
 * Determines if we need to check for closest locations and ultimately find out
 * if the location selected is okay
 *
 * @param newLead: Unsaved lead
 * @param existingLead: Saved lead
 */
export function fromLocationDidChange(newLead, existingLead) {
  if (_.isUndefined(newLead.fromAddress)) {
    return false;
  }
  return (
    _.get(newLead, 'fromAddress.fullAddress', '') !==
    _.get(existingLead, 'fromAddress.fullAddress', '')
  );
}

export function toLocationDidChange(newLead, existingLead) {
  if (_.isUndefined(newLead.toAddress)) {
    return false;
  }
  return (
    _.get(newLead, 'toAddress.fullAddress', '') !==
    _.get(existingLead, 'toAddress.fullAddress', '')
  );
}

/********* INTERNAL ***********/

/**
 * Determines if a room TYPE has changed
 * - Has implications that require changing all associated room data to the new key
 *
 * @param {object} newLead
 * @param {object} existingLead
 * @param {integer} roomIndex
 */
// function roomTypeDidChange(newLead, existingLead, roomIndex) {
//   return (
//     _.get(newLead, `rooms[${roomIndex}].roomType`, null) !==
//     _.get(existingLead, `rooms[${roomIndex}].roomType`, null)
//   );
// }

// /**
//  * Determines if a room key (name) has changed
//  * - Has implications that require changing all associated room data to the new key
//  *
//  * @param {object} newLead
//  * @param {object} existingLead
//  * @param {integer} roomIndex
//  */
// function roomNameDidChange(newLead, existingLead, roomIndex) {
//   return (
//     _.get(newLead, `rooms[${roomIndex}].name`, null) !==
//     _.get(existingLead, `rooms[${roomIndex}].name`, null)
//   );
// }

/**
 * Determines if the customerType has changed
 *
 * @param {object} newLead
 * @param {object} existingLead
 */
function customerTypeDidChange(newLead, existingLead) {
  return (
    _.get(newLead, 'customerType', '') !==
    _.get(existingLead, 'customerType', '')
  );
}
