import {
  ControllerFieldState,
  ControllerRenderProps,
  UseFormProps,
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFormDefaults: Pick<UseFormProps<any>, 'mode' | 'delayError'> = {
  mode: 'onTouched',
  // mode: 'onBlur',
  // mode: 'onChange',
  // delayError: 1000,
};

export const shouldShowSuccess = ({
  hadDefaultValue,
  // field,
  fieldState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: ControllerRenderProps<any, string>;
  fieldState: ControllerFieldState;
  hadDefaultValue: boolean;
}): boolean => {
  // console.log(`${field?.name} showSccess`, {
  //   // isRequired,
  //   // length: (field.value || '').length,
  //   isDirty: fieldState.isDirty,
  //   invalid: fieldState.invalid,
  //   error: fieldState.error,
  //   isTouched: fieldState.isTouched,
  // });
  return (
    // hadDefaultValue &&
    // (hadDefaultValue || fieldState.isDirty) &&
    (hadDefaultValue || fieldState.isTouched) &&
    typeof fieldState.error === 'undefined' &&
    !fieldState.invalid
  );
};
