import { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import Mailcheck from 'mailcheck';
import { Checkbox, Box, useToast } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import {
  faEnvelope,
  faPhone,
  faMobileRetro as faMobile,
  IconDefinition,
} from '@fortawesome/pro-duotone-svg-icons';
import { conformToMask } from 'react-text-mask';

import untranslatedSteps from '@backend/lib/lead/steps';
import { useFormDefaults } from 'lib/form';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import StepSummary from 'components/StepSummary';
import RhfTextField from 'components/fields/RhfTextField';
import UlfFormStep from 'components/UlfFormStep';

export const contactIcons = {
  phone: faPhone,
  email: faEnvelope,
  text: faMobile,
} as Record<string, IconDefinition>;

// eslint-disable-next-line prettier/prettier
const phoneMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const STEP_NAME = 'contact';

const useSelectContactStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['name', 'email', 'phone', 'emailOptIn', 'smsOptIn']),
    ),
  );

export const ContactStepSummary = () => {
  const values = useSelectContactStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid} data-private>
      {values.name?.first} {values.name?.last}
      {values.email && `, ${values.email}`}
      {values.phone && `, ${values.phone}`}
    </StepSummary>
  );
};

const schema = z.object({
  name: z.object({
    first: z.string().max(50),
    last: z.string().max(50),
  }),
  email: z.string().email(),
  emailOptIn: z.boolean(),
  phone: z.string().refine((v) => isValidPhoneNumber(v, 'US'), {
    // message: 'Invalid phone',
    params: { i18n: 'invalid_phone' },
  }),
  smsOptIn: z.boolean(),
});

export type ContactStepFormValues = z.infer<typeof schema>;

const emailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'comcast.net',
  'icloud.com',
  'outlook.com',
  'msn.com',
  'att.net',
  'sbcglobal.net',
  'me.com',
  'live.com',
  'bellsouth.net',
  'cox.net',
  'twomen.com',
  'verizon.net',
  'charter.net',
  'mac.com',
  'ymail.com',
  'rogers.com',
  'protonmail.com',
  'earthlink.net',
  'yahoo.ca',
  'lennar.com',
  'rocketmail.com',
  'wi.rr.com',
  'sympatico.ca',
  'shaw.ca',
  'frontier.com',
  'mchsi.com',
  'umich.edu',
  'twc.com',
  'cfl.rr.com',
  'roadrunner.com',
  'denvermattress.com',
  'juno.com',
  'mail.com',
  'live.ca',
  'windstream.net',
  'avera.org',
  'bell.net',
  'fuse.net',
  'hotmail.ca',
  'tampabay.rr.com',
  'schwartzfurniture.com',
  'mindspring.com',
  'privaterelay.appleid.com',
  'proton.me',
  'sasktel.net',
  'llflooring.com',
  'cogeco.ca',
  'pauldavis.com',
  'centurylink.net',
  'ameritech.net',
  'q.com',
  'oaktreesvcs.com',
  'swbell.net',
  'optonline.net',
  'nsm-seating.com',
  'msu.edu',
  'prodigy.net',
  'umn.edu',
  'aim.com',
  'nc.rr.com',
  'new.rr.com',
  'lumberliquidators.com',
  'sc.rr.com',
  'tds.net',
  'seniorlivingexp.com',
  'columbus.rr.com',
  'cfsbeds.com',
  'pm.me',
  'kw.com',
  'livingstonhabitat.org',
  'epbfi.com',
  'ferrazzano.com',
  'telus.net',
  'cinci.rr.com',
  'embarqmail.com',
  'drpartnersllc.com',
  'reagan.com',
  'duck.com',
  'netscape.net',
  'nixdevco.com',
  'triad.rr.com',
  'hillcrest.com',
  'cs.com',
  'boycelumber.com',
  'frontiernet.net',
  'email.com',
  'bridgettmazer.com',
  'sio.midco.net',
  '4westcabinetry.com',
  'neo.rr.com',
  'zoominternet.net',
  'usc.salvationarmy.org',
  'knology.net',
  'netzero.net',
  'idstudiointeriors.com',
  'woh.rr.com',
];

export const ContactStepForm = ({
  onContinue,
  onSave,
}: UseStepMethodsReturn) => {
  const toast = useToast();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { watch, register, handleSubmit, formState, control } =
    useFormContext<ContactStepFormValues>();
  const { isSubmitting, isValid } = formState;

  const email = watch('email');

  const isEmailTouched = formState.touchedFields.email || false;

  const onEmailChange = (email: string, isEmailTouched: boolean) => {
    if (!isEmailTouched) {
      return;
    }

    const result = Mailcheck.run({
      domains: emailDomains,
      email,
    });
    if (result) {
      if (toast.isActive('emailSuggestion')) {
        toast.update('emailSuggestion', {
          status: 'warning',
          description: t('Did you mean {{emailSuggestion}}?', {
            emailSuggestion: result.full,
          }),
        });
      } else {
        toast({
          id: 'emailSuggestion',
          description: t('Did you mean {{emailSuggestion}}?', {
            emailSuggestion: result.full,
          }),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      toast.close('emailSuggestion');
    }
  };

  const debouncedOnEmailChange = useMemo(
    () => debounce(onEmailChange, 500),
    [],
  );

  useEffect(() => {
    if (email) {
      debouncedOnEmailChange(email, isEmailTouched);
    } else {
      toast.close('emailSuggestion');
    }
  }, [email, isEmailTouched]);

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <RhfTextField
        label={t('First Name')}
        isRequired={true}
        isPrivate={true}
        control={control}
        name="name.first"
        inputProps={{
          maxLength: 50,
        }}
      />
      <RhfTextField
        label={t('Last Name')}
        isRequired={true}
        control={control}
        name="name.last"
        isPrivate={true}
        inputProps={{
          maxLength: 50,
        }}
      />
      <Box layerStyle="fieldWrapper">
        <RhfTextField
          label={t('Email')}
          control={control}
          isRequired={true}
          name="email"
          isPrivate={true}
          inputProps={{
            type: 'email',
          }}
        />
        <Checkbox {...register('emailOptIn')} mt={-3}>
          <Trans>
            You may send me marketing emails related to TWO MEN AND A TRUCK®
            and its partner brands.
          </Trans>
        </Checkbox>
      </Box>
      <Box layerStyle="fieldWrapper" mb={8}>
        <RhfTextField
          label={t('Phone')}
          isRequired={true}
          control={control}
          name="phone"
          isPrivate={true}
          maskedInputProps={{
            mask: phoneMask,
          }}
          inputProps={{
            type: 'tel',
          }}
        />
        <Checkbox {...register('smsOptIn')} mt={-3}>
          <Trans>
            You may send me messages via text (including automated texts)
            related to my services. Message and data rates may apply, reply STOP
            to any message to opt-out.
          </Trans>
        </Checkbox>
      </Box>
    </UlfFormStep>
  );
};

const ContactStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const defaultValues = useSelectContactStepValues();

  const form = useForm<ContactStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      ...defaultValues,
      phone: defaultValues.phone
        ? conformToMask(defaultValues.phone.replace(/[^\d]/g, ''), phoneMask, {
            guide: false,
          }).conformedValue
        : undefined,
    },
  });

  return (
    <FormProvider {...form}>
      <ContactStepForm onSave={onSave} onContinue={onContinue} />
    </FormProvider>
  );
};

const ContactStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <ContactStep /> : <ContactStepSummary />;
};

export default ContactStepContainer;
