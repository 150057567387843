import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createSelector } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { Box, Button } from '@chakra-ui/react';
import {
  faLoveseat,
  faPaperPlane,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';

import logger from 'logger';
import untranslatedSteps from '@backend/lib/lead/steps';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { saveStep } from 'actions/leadActions';
import { selectLead } from 'slices/leadSlice';
import useStepMethods from 'hooks/useStepMethods';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import UlfStepContainer, {
  UlfStepButtonsContainer,
  UlfStepInner,
} from 'components/UlfStepContainer';
import Intro from 'components/Intro';
import StepSummary from 'components/StepSummary';
import FontAwesomeSubIcon from 'components/primatives/FontAwesomeSubIcon';

const STEP_NAME = 'nextSteps';

const useNextStepsStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) => pick(lead, ['roomsStart'])),
  );

export const NextStepsStepSummary = () => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const values = useNextStepsStepValues();
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {isValid && values.roomsStart === true && <Trans>Add more details</Trans>}
      {isValid && values.roomsStart === false && (
        <Trans>Please contact me</Trans>
      )}
    </StepSummary>
  );
};

export const NextStepsStepForm = ({
  finish,
  saveStepState,
  handleContinue,
  handleFinish,
}: {
  finish?: boolean;
  saveStepState?: string | undefined;
  handleContinue(): void;
  handleFinish(): void;
}) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isLoading = saveStepState === 'pending';
  // const isError = saveStepState === 'rejected';

  return (
    <UlfStepContainer>
      <UlfStepInner pb={5}>
        <Intro
          title={stepDefinition.title}
          description={stepDefinition.description}
        />
      </UlfStepInner>
      <UlfStepButtonsContainer>
        <Button
          isLoading={!finish && isLoading}
          onClick={handleContinue}
          variant="stepSubmit"
          data-test-id="start"
        >
          <Box mr={3} position="relative">
            <FontAwesomeIcon icon={faLoveseat} />
            <FontAwesomeSubIcon icon={faPlus} />
          </Box>
          <Trans>Add more details</Trans>
        </Button>
        <Button
          isLoading={finish && isLoading}
          onClick={handleFinish}
          variant="stepSubmit"
          data-test-id="finish"
          leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        >
          <Trans>Please contact me</Trans>
        </Button>
      </UlfStepButtonsContainer>
    </UlfStepContainer>
  );
};

const NextStepsStep = ({ isCurrentStep }: { isCurrentStep: boolean }) => {
  const [finish, setFinish] = useState<boolean | undefined>();
  const saveStepState = useAppSelector((state) => state.app.saveStepState);
  const dispatch = useAppDispatch();
  const { onContinue } = useStepMethods(STEP_NAME);

  const handleContinue = async () => {
    setFinish(false);
    return onContinue({
      roomsStart: true,
    });
  };

  const handleFinish = async () => {
    setFinish(true);
    try {
      const result = await dispatch(
        saveStep({
          stepToSave: STEP_NAME,
          lead: {
            roomsStart: false,
          },
          stepToNagivateTo: 'finish',
        }),
      ).unwrap();
      return result;
    } catch (error) {
      logger.error('onContinue error', { error });
      throw error;
    }
  };

  return isCurrentStep ? (
    <NextStepsStepForm
      finish={finish}
      saveStepState={saveStepState}
      handleContinue={handleContinue}
      handleFinish={handleFinish}
    />
  ) : (
    <NextStepsStepSummary />
  );
};

export default NextStepsStep;
