import type { PartialDeep } from 'type-fest/source/partial-deep';

import { isFromAddressBuildingTypeStorage } from '@backend/lib/lead/helperFunctions';
import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { fromLocationDidChange } from 'actions/actionUtilities';
import {
  generateDefaultSpace,
  generateStorageSpace,
  isSpaceValid,
} from 'lib/space';
// import logger from 'logger';

export const handleFromLocationOrServiceChange = ({
  incomingLead,
  existingLead,
  stepToSave,
  updatedLead,
}: {
  incomingLead: PartialDeep<Lead>;
  existingLead: PartialDeep<Lead>;
  stepToSave: string;
  updatedLead: PartialDeep<Lead>;
}): PartialDeep<Lead> => {
  // logger.info(
  //   'fromLocationDidChange(updatedLead, existingLead)',
  //   fromLocationDidChange(updatedLead, existingLead),
  // );
  // logger.info('stepToSave', stepToSave);

  if (
    fromLocationDidChange(updatedLead, existingLead) ||
    stepToSave === 'fromAddress' ||
    stepToSave === 'services'
  ) {
    const changedFromBuildingTypes =
      existingLead.fromAddress?.buildingTypeId !==
      updatedLead.fromAddress?.buildingTypeId;

    const wasStorage = isFromAddressBuildingTypeStorage(existingLead);
    const isNowStorage = isFromAddressBuildingTypeStorage(updatedLead);
    const wasOrIsStorage = wasStorage || isNowStorage;

    if (changedFromBuildingTypes && wasOrIsStorage) {
      // logger.info('generate new single space');

      const newRoom = isNowStorage
        ? generateStorageSpace()
        : generateDefaultSpace(updatedLead);

      //Move existing items into new space.
      if ((updatedLead.rooms || []).length) {
        incomingLead.inventory = (updatedLead.inventory || []).map((item) => ({
          ...item,
          spaceId: newRoom._id as unknown as string,
        }));
        incomingLead.boxes = (updatedLead.boxes || []).map((item) => ({
          ...item,
          spaceId: newRoom._id as unknown as string,
        }));
        incomingLead.photos = (updatedLead.photos || []).map((item) => ({
          ...item,
          spaceId: newRoom._id as unknown as string,
        }));
      }

      //Add new space
      incomingLead.rooms = [
        {
          ...newRoom,
          isValid: isSpaceValid({
            inventoryCount: (incomingLead.inventory || [])?.length,
          }),
        },
      ];
    }

    const services = updatedLead.services || [];
    const sameAddressCondition =
      services.length > 0 &&
      (!services.includes('move') || services.includes('internal'));

    // logger.info('info', {
    //   services,
    //   sameAddressCondition,
    //   changedFromBuildingTypes,
    //   wasStorage,
    //   isNowStorage,
    //   wasOrIsStorage,
    //   oneSpace: updatedLead.oneSpace,
    // });

    return {
      ...incomingLead,
      toAddress: sameAddressCondition
        ? updatedLead.fromAddress
        : updatedLead.toAddress,
      moveDriveDistance: sameAddressCondition
        ? 0
        : updatedLead.moveDriveDistance,
    };
  }

  return incomingLead;
};

export default handleFromLocationOrServiceChange;
