import { Trans, useTranslation } from 'react-i18next';
import get from 'lodash/get';
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Grid,
  GridItem,
  Link,
} from '@chakra-ui/react';

import { UFL_DOMAIN_EXT } from 'utils';
// import { useAppDispatch } from 'store/configureStore';
// import { toggleCookieBanner } from 'actions/cookieActions';
import LanguageSwitcher from 'components/LanguageSwitcher';
import UlfLayoutContainer from 'components/UlfLayoutContainer';
import { useAppSelector } from 'store/configureStore';
import { selectCurrentLocation } from 'slices/app';

const Footer = () => {
  const { t } = useTranslation();
  const currentLocation = useAppSelector(selectCurrentLocation);
  // const dispatch = useAppDispatch();
  // const isCookieBannerVisible = useSelector(
  //   (state: RootState) => state.app.cookieBannerVisible,
  // ) as boolean;

  const canada = UFL_DOMAIN_EXT === 'ca';
  const d = new Date();
  const year = d.getFullYear();
  const content = {
    questions: t(`Have a question? We're here to help.`),
    customerService: canada ? '866.684.6448' : '800.345.1070',
    customerServiceARIA: t(`Call Customer Service {{ phone }}.`, {
      phone: canada ? '8 6 6. 6 8 4. 6 4 4 8' : '8 0 0. 3 4 5. 1 0 7 0',
    }),
    findLocation: canada ? '866.684.6448' : '877.720.0411',
    // findLocationARIA: t(`Call to Find a Location {{ phone }}.)`, {
    //   phone: canada ? '8 6 6. 6 8 4. 6 4 4 8' : '8 7 7. 7 2 0. 0 4 1 1',
    // }),
    copyright: `${t(
      'Copyright',
    )} ${year} TWO MEN AND A TRUCK®/International, LLC. 3400 Belle Chase Way, Lansing, MI 48911`,
    contact: `https://twomenandatruck.${canada ? 'ca' : 'com'}/${
      canada ? 'about-us/contact-us' : 'contact'
    }`,
    privacy: `https://twomenandatruck.${canada ? 'ca' : 'com'}/privacy-policy`,
  };

  const findLocationPhoneFallback = canada ? '866.684.6448' : '877.720.0411';
  const findLocationPhone = get(
    currentLocation || {},
    'phone.organic',
    findLocationPhoneFallback,
  );

  // const optionsCountry = [
  //   { value: 'United States', label: 'United States' },
  //   { value: 'Canada', label: 'Canada' },
  //   { value: 'United Kingdom', label: 'United Kingdom' },
  //   { value: 'Ireland', label: 'Ireland' }
  // ]

  // const optionsLanguage = [
  //   { value: 'English', label: 'English', selectedState: true },
  //   { value: 'Espanol', label: 'Español' }
  // ]

  return (
    <>
      <Flex
        as="footer"
        // id="footer"
        // className="App-footer footer"
        // marginBottom={
        //   (isCookieBannerVisible &&
        //     document.getElementById('cc-banner') &&
        //     `${document.getElementById('cc-banner')?.clientHeight}px`) ||
        //   undefined
        // }
        direction="column"
        bg="gray.700"
        color="white"
        textAlign={{ base: 'center', md: 'inherit' }}
      >
        <UlfLayoutContainer px={{ base: 4, md: 8 }}>
          <Grid
            // align="center"
            // direction={{ base: 'column', md: 'row' }}
            gap={{ base: 4, md: 8 }}
            py={12}
            px={0}
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          >
            <Heading as="h3" size="h4">
              {content.questions}
            </Heading>
            <Flex gap={{ base: 4, sm: 2, md: 2 }} direction="column">
              <Flex
                direction={{ base: 'column', sm: 'row', md: 'column' }}
                gap="inherit"
                justify="center"
                fontSize="sm"
              >
                <Box>
                  <Box as="span" mr={1}>
                    <Trans>Call Customer Service:</Trans>
                  </Box>
                  <Link
                    href={`tel:${content.customerService}`}
                    // aria-label={content.customerServiceARIA}
                    className="noSwap"
                    variant="footerLink"
                    fontWeight="bold"
                  >
                    {content.customerService}
                  </Link>
                </Box>
                <Box>
                  <Box as="span" mr={1}>
                    {currentLocation ? (
                      <Trans>Call Now:</Trans>
                    ) : (
                      <Trans>Call to Find a Location:</Trans>
                    )}
                  </Box>
                  <Link
                    href={`tel:${findLocationPhone}`}
                    // aria-label={content.findLocationARIA}
                    variant="footerLink"
                    fontWeight="bold"
                  >
                    {findLocationPhone}
                  </Link>
                </Box>
              </Flex>
            </Flex>
            <Box textAlign={{ base: 'center', md: 'right' }}>
              <Button
                as={Link}
                href={content.contact}
                target="_blank"
                rel="noopener noreferrer"
                w="auto"
                variant="orange"
                _focus={{ outlineColor: 'tmtGold' }}
              >
                <Trans>Contact Us</Trans>
              </Button>
            </Box>
          </Grid>
        </UlfLayoutContainer>
        <Box borderTop="1px" borderColor="gray.500">
          <UlfLayoutContainer px={{ base: 4, md: 8 }}>
            <Grid
              columnGap={{ md: 8 }}
              rowGap={8}
              py={12}
              px={0}
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
            >
              <GridItem colSpan={3}>
                <Flex direction="column" gap={4} px={0} fontSize="sm">
                  <Text>{content.copyright}</Text>
                  <Link
                    href="https://www.servicemaster.com/our-brands/"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="footerLink"
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                  >
                    <Trans>A ServiceMaster Company®</Trans>
                  </Link>
                  <Text
                    m={0}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent={{ base: 'center', md: 'inherit' }}
                    gap={{ base: 4, md: 5 }}
                  >
                    <Link
                      href="https://www.servicemaster.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="footerLink"
                    >
                      <Trans>Privacy Policy</Trans>
                    </Link>
                    {/* <Button
                      variant="footerLink"
                      onClick={() => dispatch(toggleCookieBanner())}
                      fontSize="inherit"
                      p={0}
                      m={0}
                      h="auto"
                    >
                      <Trans>Cookie Settings</Trans>
                    </Button> */}
                    <Link
                      href="https://www.servicemaster.com/accessibility-statement/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="footerLink"
                    >
                      <Trans>Accessibility Statement</Trans>
                    </Link>
                    <Link
                      href="https://twomenandatruck.com/cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="footerLink"
                    >
                      <Trans>Do Not Sell/Share My Info</Trans>
                    </Link>
                  </Text>
                </Flex>
              </GridItem>
              <Box
                w={{ base: '50%', md: '100%' }}
                textAlign={{ base: 'center', md: 'right' }}
                ml="auto"
                mr={{ base: 'auto', md: 0 }}
              >
                <LanguageSwitcher />
              </Box>
            </Grid>
          </UlfLayoutContainer>
        </Box>
      </Flex>
    </>
  );
};

export default Footer;
