import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

import FontAwesomeIcon, {
  FontAwesomeIconProps,
} from 'components/primatives/FontAwesomeIcon';

const Loading = (props: Omit<FontAwesomeIconProps, 'icon'>) => {
  return (
    <FontAwesomeIcon
      color="#294a93"
      fontSize="42px"
      icon={faCircleNotch}
      iconProps={{ spin: true }}
      {...props}
    />
  );
};

export default Loading;
