import _ from 'lodash';

import types from 'constants/actionTypes';
import { initialAppState } from 'store/utils';

export const networkErrorMessage = 'A network error occured, sorry.';

export default function app(state = initialAppState, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        ...state,
        ..._.get(action, 'payload.app', {}),
        mapApiLoaded: false,
        wip: {
          ...state.wip,
          fetchingLocation: false,
          updating: false,
        },
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language || 'en',
      };
    case types.SET_WEBSOCKET_READYSTATE:
      return {
        ...state,
        readyState: action.readyState,
      };
    case types.FIND_ESTIMATE_LEAD_SUCCESS:
      return {
        ...state,
        token: action.response.result.token,
        userErrorMessage: null,
      };
    case types.REMOVE_FINISHED_LEAD:
      return {
        ...state,
        finishedLead: null,
      };
    case 'createLead/fulfilled':
      return {
        ...state,
        token: action.payload.token,
        activeLeadID: action.payload.id,
        apiError: null,
        finishedLead: null,
        userErrorMessage: null,
        backendPending: false,
      };
    case types.CREATE_LEAD_SUCCESS:
      return {
        ...state,
        token: action.response.result.token,
        activeLeadID: action.response.result.id,
        apiError: null,
        finishedLead: null,
        userErrorMessage: null,
        backendPending: false,
      };
    case 'createLead/rejected':
    case types.CREATE_LEAD_FAILURE:
      return {
        ...state,
        finishedLead: null,
        apiError: action.error,
        userErrorMessage: networkErrorMessage,
      };
    case types.UPDATE_LEAD_REQUEST:
    case types.LOCATION_LOAD_REQUEST:
      return {
        ...state,
        userErrorMessage: null,
        apiError: null,
        wip: {
          ...state.wip,
          fetchingLocation: true,
        },
      };
    case types.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        userErrorMessage: null,
      };
    case types.UPDATE_LEAD_FAILURE:
      return {
        ...state,
        // userErrorMessage: networkErrorMessage
      };
    case types.LOCATION_LOAD_SUCCESS:
      return {
        ...state,
        currentLocation: action.response.result[0],
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
        userErrorMessage: null,
      };
    case types.UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
        userErrorMessage: null,
      };
    case types.LOCATION_LOAD_FAILURE:
      return {
        ...state,
        currentLocation: null,
        apiError: networkErrorMessage,
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
      };
    case types.LOCATION_SEARCH_REQUEST:
      return {
        ...state,
        wip: {
          ...state.wip,
          fetchingCloseLocations: true,
        },
        userErrorMessage: null,
      };
    case types.LOCATION_SEARCH_SUCCESS:
      return {
        ...state,
        closeLocations: _.map(action.response.result.results, (o) => {
          return _.omit(o, 'distance');
        }),
        wip: {
          ...state.wip,
          fetchingCloseLocations: false,
        },
        userErrorMessage: null,
      };
    case types.LOCATION_SEARCH_FAILURE:
      return {
        ...state,
        closeLocations: null,
        apiError: networkErrorMessage,
        wip: {
          ...state.wip,
          fetchingCloseLocations: false,
        },
      };
    case types.THEME_CLASS_REQUEST:
      return {
        ...state,
        themeClass: null,
      };
    case types.THEME_CLASS_SUCCESS:
      return {
        ...state,
        themeClass: action.response.result.colorScheme || 'default',
      };
    case types.CHANGE_ROOM_INDEX:
      return {
        ...state,
        activeRoomIndex: action.roomIndex || 0,
      };
    case types.SAVE_PERCENT_COMPLETE:
      return {
        ...state,
        percentageComplete: action.payload,
      };
    case types.UPDATE_CLOSE_LOCATIONS:
      return {
        ...state,
        closeLocations: action.payload,
      };
    case types.SET_UPDATING:
      return {
        ...state,
        wip: {
          ...state.wip,
          updating: action.value,
        },
      };
    case types.AUTHENTICATE_CSR_REQUEST:
      return {
        ...state,
        activeLeadID: null,
        apiError: null,
        wip: {
          ...state.wip,
          fetchingLocation: true,
        },
        userErrorMessage: null,
      };
    case types.AUTHENTICATE_CSR_SUCCESS:
      return {
        ...state,
        token: action.response.result.token,
        activeLeadID: action.response.result.id,
        apiError: null,
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
        userErrorMessage: null,
      };
    case types.AUTHENTICATE_CSR_FAILURE:
      return {
        ...state,
        activeLeadID: null,
        apiError: action.error || 'Unknown lead retrival error',
        userErrorMessage: null,
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
      };
    case types.RESET_LEAD:
      return {
        ...state,
        activeLeadID: null,
        apiError: null,
        finishedLead: null,
      };
    case types.FULL_RESET:
      return initialAppState;
    case types.STASH_FINISHED_LEAD:
      return {
        ...state,
        finishedLead: action.lead,
      };
    case types.AUTHENTICATE_RETURNING_REQUEST:
      return {
        ...state,
        activeLeadID: null,
        apiError: null,
        wip: {
          ...state.wip,
          fetchingLocation: true,
        },
        userErrorMessage: null,
      };
    case types.AUTHENTICATE_RETURNING_SUCCESS:
      return {
        ...state,
        token: _.get(action, 'response.result.token', null),
        activeLeadID: _.get(action, 'response.result.lead.id', null),
        apiError: null,
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
      };
    case types.AUTHENTICATE_RETURNING_FAILURE:
      return {
        ...state,
        activeLeadID: null,
        apiError: action.error || 'Unknown UFL Lead Fetching Error',
        wip: {
          ...state.wip,
          fetchingLocation: false,
        },
      };
    case types.CLOSED_DATES_SUCCESS:
      return {
        ...state,
        closedDates: action.response.result,
      };
    case types.CLOSED_DATES_FAILURE:
      return {
        ...state,
        closedDates: [],
      };
    case types.GTM_EVENT:
      return {
        ...state,
        gtmEvents: [...state.gtmEvents, action.event],
      };
    case types.SET_USER_MESSAGE:
      return {
        ...state,
        userMessage: action.message || null,
      };
    case types.MAPS_LIBRARY_LOADED:
      return {
        ...state,
        mapApiLoaded: action.loaded,
      };
    case types.SET_GEO_IP_LOCATION:
      return {
        ...state,
        geoIpLocation: action.data,
      };
    case types.ENABLE_COOKIES:
      return {
        ...state,
        cookieConsent: action.data,
        cookieBannerVisible: false,
      };
    case types.TOGGLE_COOKIE_BANNER:
      return {
        ...state,
        cookieBannerVisible: true,
      };
    // @TODO: Convert these when we move this to a slice.
    case 'createLead/pending':
    case 'saveLead/pending':
    case 'fetchLead/pending':
      return {
        ...state,
        backendPending: true,
      };
    // @TODO: Convert these when we move this to a slice.
    case 'saveLead/fulfilled':
    case 'fetchLead/fulfilled':
      return {
        ...state,
        backendPending: false,
      };
    case 'saveStep/pending':
      return {
        ...state,
        saveStepState: 'pending',
        userErrorMessage: null,
      };
    case 'saveStep/fulfilled':
      return {
        ...state,
        saveStepState: 'fulfilled',
        userErrorMessage: null,
      };
    case 'saveStep/rejected':
      return {
        ...state,
        saveStepState: 'rejected',
        userErrorMessage: networkErrorMessage,
      };
    default:
      return state;
  }
}
