import React from 'react';
import {
  Input,
  List,
  ListItem,
  ListProps,
  InputProps,
  ListItemProps,
} from '@chakra-ui/react';

export const ComboboxInput = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    isOpen: boolean;
  }
>(({ isOpen, ...props }, ref) => {
  return (
    <Input
      ref={ref}
      flex="0 0 auto"
      borderTopRadius="3xl"
      borderBottomRadius={isOpen ? 'none' : '3xl'}
      {...props}
    />
  );
});

export const ComboboxList = React.forwardRef<
  HTMLUListElement,
  ListProps & {
    isOpen: boolean;
  }
>(({ isOpen, ...props }, ref) => {
  return (
    <List
      ref={ref}
      display={isOpen ? undefined : 'none'}
      flex={1}
      overflowY="auto"
      mt={0}
      pos="absolute"
      top="100%"
      w="100%"
      borderX="1px"
      p={0}
      zIndex="dropdown"
      borderBottomRadius="3xl"
      layerStyle="lightContrastBox"
      py={2}
      {...props}
    />
  );
});

export const ComboboxItem = React.forwardRef<
  HTMLLIElement,
  ListItemProps & {
    itemIndex: number;
    highlightedIndex: number;
  }
>(({ itemIndex, highlightedIndex, ...props }, ref) => {
  const isActive = itemIndex === highlightedIndex;

  return (
    <ListItem
      transition="background-color 220ms, color 220ms"
      bg={isActive ? 'tmtGold' : undefined}
      _dark={{ bg: isActive ? 'tmtBlue' : undefined }}
      px={4}
      py={2}
      cursor="pointer"
      borderBottom="1px"
      {...props}
      ref={ref}
    />
  );
});
