import type { PartialDeep } from 'type-fest/source/partial-deep';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import type { LeadCreateResponse } from '@backend/src/endpoints/leadCreate';
import {
  LeadContinueArgsType,
  LeadContinueReturnType,
} from '@backend/src/endpoints/leadContinue';
import {
  LeadAuthArgsType,
  LeadAuthReturnType,
} from '@backend/src/endpoints/leadReauth';
import type { CredentialsResponse } from '@backend/src/services/sts';
import logger from 'logger';
import { API_ROOT } from 'utils';

const sharedHeaders = {
  'Content-Type': 'application/json',
};

const isResponseNotOk = (response: Response) =>
  ['4', '5'].includes(`${response.status}`[0]) || !response.ok;

export const postContinue = async ({
  encodedID,
  encodedToken,
}: LeadContinueArgsType): Promise<LeadContinueReturnType> => {
  const response = await fetch(`${API_ROOT}/continue`, {
    method: 'POST',
    headers: {
      ...sharedHeaders,
    },
    body: JSON.stringify({
      encodedID,
      encodedToken,
    }),
  });
  return response.json();
};

export const postReauth = async ({
  email,
  lastName,
}: LeadAuthArgsType): Promise<LeadAuthReturnType> => {
  const response = await fetch(`${API_ROOT}/reauth`, {
    method: 'POST',
    headers: {
      ...sharedHeaders,
    },
    body: JSON.stringify({
      email,
      lastName,
    }),
  });

  if (isResponseNotOk(response)) {
    logger.error('postReauth response not ok', { response });
    throw response;
  }

  return response.json();
};

export const getCredentials = async ({
  activeLeadID,
  token,
}: {
  activeLeadID: string;
  token: string;
}): Promise<NonNullable<CredentialsResponse>> => {
  const response = await fetch(`${API_ROOT}/lead/${activeLeadID}/credentials`, {
    method: 'POST',
    headers: {
      ...sharedHeaders,
      Authorization: 'Bearer ' + token,
    },
  });

  if (isResponseNotOk(response)) {
    logger.error('getCredentials response not ok', { response });
    throw response;
  }

  return response.json();
};

export const ensureCroppedImage = async ({
  activeLeadID,
  token,
  photoId,
  tagId,
}: {
  activeLeadID: string;
  token: string;
  photoId: string;
  tagId: string;
}): Promise<string> => {
  const response = await fetch(
    `${API_ROOT}/lead/${activeLeadID}/ensureCroppedImage/${photoId}/${tagId}`,
    {
      method: 'POST',
      headers: {
        ...sharedHeaders,
        Authorization: 'Bearer ' + token,
      },
    },
  );

  if (isResponseNotOk(response)) {
    logger.error('ensureCroppedImage response not ok', { response });
    throw response;
  }

  return response.json();
};

export const leadGet = async ({
  activeLeadID,
  token,
}: {
  activeLeadID: string;
  token: string;
}): Promise<Lead> => {
  const response = await fetch(`${API_ROOT}/lead/${activeLeadID}`, {
    headers: {
      ...sharedHeaders,
      Authorization: 'Bearer ' + token,
    },
  });

  if (isResponseNotOk(response)) {
    logger.error('getLead response not ok', { response });
    throw response;
  }

  return response.json();
};

export const leadUpdate = async ({
  activeLeadID,
  token,
  lead,
}: {
  activeLeadID: string;
  token: string;
  lead: PartialDeep<Lead>;
}): Promise<Lead> => {
  const response = await fetch(`${API_ROOT}/lead/${activeLeadID}`, {
    method: 'POST',
    headers: {
      ...sharedHeaders,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(lead),
  });

  if (isResponseNotOk(response)) {
    logger.error('leadUpdate response not ok', { response });
    throw response;
  }

  return response.json();
};

export const leadCreate = async ({
  lead,
}: {
  lead: PartialDeep<Lead>;
}): Promise<LeadCreateResponse> => {
  const response = await fetch(`${API_ROOT}/lead`, {
    method: 'POST',
    headers: {
      ...sharedHeaders,
    },
    body: JSON.stringify(lead),
  });

  if (isResponseNotOk(response)) {
    logger.error('leadCreate response not ok', { response });
    throw response;
  }

  return response.json();
};
