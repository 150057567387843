import { forwardRef, Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';

import { uflContainerMd } from 'chakra/layerStyles';
import useScrollIntoView from 'hooks/useScrollIntoView';
import UlfLayoutContainer from 'components/UlfLayoutContainer';
import { headerId } from 'components/Intro';

export const UlfStepButtonsContainer = ({ children, ...props }: FlexProps) => (
  <UlfLayoutContainer>
    <Flex
      className="stepButtonsContainer"
      my={4}
      justify="center"
      gap={2}
      direction={{ base: 'column', sm: 'row' }}
      {...uflContainerMd}
      {...props}
    >
      {children}
    </Flex>
  </UlfLayoutContainer>
);

export const UlfStepInner = ({ children, ...props }: BoxProps) => (
  <Box
    w="100%"
    // textAlign="center"
    px={{ base: 5, md: 8 }}
    pt={5}
    pb={{ base: 8, md: 12 }}
    border="1px"
    {...uflContainerMd}
    layerStyle="lightContrastBox"
    {...props}
  >
    {children}
  </Box>
);

const UlfStepContainer = ({ children, ...props }: BoxProps) => {
  const ref = useScrollIntoView();
  return (
    <UlfLayoutContainer ref={ref} tabIndex={-1} id={headerId} {...props}>
      {children}
    </UlfLayoutContainer>
  );
};

export const UlfCard = forwardRef<
  BoxProps & {
    outerProps?: BoxProps;
  },
  'div'
>(({ children, outerProps, ...props }, ref) => (
  <UlfLayoutContainer ref={ref} {...outerProps}>
    <UlfStepInner {...props}>{children}</UlfStepInner>
  </UlfLayoutContainer>
));

export default UlfStepContainer;
