import { alertAnatomy } from '@chakra-ui/anatomy';
// import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import {
  createMultiStyleConfigHelpers,
  cssVar,
} from '@chakra-ui/styled-system';

const $fg = cssVar('alert-fg');
// const $bg = cssVar('alert-bg');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    border: '1px',
    borderColor: $fg.reference,
    alignItems: 'flex-start',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
