import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import { setLanguage } from 'actions/formActions';
import { useAppDispatch } from 'store/configureStore';

const LanguageSwitcher = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const optionsLanguage = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
  ];

  const handleChange = (event) => {
    const value = event.target.value;
    i18n.changeLanguage(value);
    dispatch(setLanguage(value));
    window.location.reload();
  };

  return (
    <>
      <FormControl position="relative">
        <Select
          name="select-language"
          id="select-language"
          onChange={handleChange}
          value={i18n.resolvedLanguage}
          variant="footerField"
          fontSize="sm"
        >
          {optionsLanguage.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <FormLabel
          htmlFor="select-language"
          position="absolute"
          color="white"
          top={0.25}
          left={4}
          fontSize="sm"
        >
          <Trans>Language</Trans>
        </FormLabel>
      </FormControl>
    </>
  );
};

export default LanguageSwitcher;
