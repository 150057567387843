import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
} from 'react-hook-form';
import {
  Heading,
  Checkbox,
  ListItem,
  OrderedList,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { omitBy, type Dictionary } from 'lodash';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import untranslatedSteps from '@backend/lib/lead/steps';
import untranslatedServices from '@backend/lib/mwc/services';
import { useAppSelector } from 'store/configureStore';
import { useFormDefaults } from 'lib/form';
import { selectLead, selectLeadFranchiseId } from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import StepSummary from 'components/StepSummary';
import UlfFormStep from 'components/UlfFormStep';

const STEP_NAME = 'services';

const useSelectServicesStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['locationID', 'fromAddress', 'locationOK', 'services']),
    ),
  );

const rawServices = omitBy(
  untranslatedServices(),
  (service) => service.enabled === false,
);

export const ServicesStepSummary = () => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const services = untranslatedServices(t);
  const values = useSelectServicesStepValues();
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      <OrderedList>
        {(values.services || []).map((service: keyof typeof services) => (
          <ListItem key={service}>{services[service].label}</ListItem>
        ))}
      </OrderedList>
    </StepSummary>
  );
};

const schema = z
  .object(
    Object.fromEntries(
      Object.keys(rawServices).map((value) => [value, z.boolean()]),
    ),
  )
  .superRefine((values, refinementContext) => {
    if (Object.values(values).filter((value) => value).length === 0) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        params: { i18n: 'required' },
        path: Object.keys(rawServices),
      });
    }
  });

export type ServicesStepFormValues = z.infer<typeof schema>;

export const ServicesStepForm = ({
  onContinue,
  onSave,
  franchiseId,
}: UseStepMethodsReturn & {
  franchiseId: Lead['franchiseID'];
}) => {
  const { t } = useTranslation();
  const services = omitBy(
    untranslatedServices(t),
    (service) => service.enabled === false,
  );
  const serviceEntries = Object.entries(services);
  const serviceCount = serviceEntries.length;
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { watch, handleSubmit, formState, register } =
    useFormContext<ServicesStepFormValues>();
  const { isSubmitting, isValid } = formState;

  const unavailableValues = serviceEntries
    .filter(([, service]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const availableFranchises = (service as Dictionary<any>)
        .availableFranchises;
      return (
        Array.isArray(availableFranchises) &&
        !availableFranchises.includes(franchiseId)
      );
    })
    .map(([value]) => value);

  // Incompatible values is an array of all strings in the incompatible property of all selected values.
  const incompatibleValues: string[] = serviceEntries
    .filter(
      ([optionValue, service]) =>
        watch(optionValue) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.isArray((service as Dictionary<any>).incompatible),
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map(([, service]) => (service as Dictionary<any>).incompatible)
    .flat();

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <fieldset>
        <legend id="legend-services" style={{ width: '100%' }}>
          <Heading size="md" textAlign="center">
            <Trans>Select your services</Trans>
          </Heading>
        </legend>
        <Flex
          aria-labelledby="legend-services"
          wrap="wrap"
          role="group"
          textAlign="left"
          my={2}
        >
          {serviceEntries.map(([value, option], index) => {
            const isIncompatible =
              Array.isArray(incompatibleValues) &&
              incompatibleValues.includes(value);

            const isUnavailable =
              Array.isArray(unavailableValues) &&
              unavailableValues.includes(value);

            const smWidth =
              serviceCount % 2 !== 0 && index + 1 === serviceCount
                ? '100%'
                : '50%';

            return (
              <Checkbox
                key={value}
                {...register(value)}
                variant="column"
                w={{ base: '100%', sm: smWidth }}
                isDisabled={isIncompatible || isUnavailable}
                aria-describedby={clsx(
                  isIncompatible && 'incompatible-services',
                  isUnavailable && 'unavailable-services',
                )}
              >
                {option.icon && (
                  <div className={`svg-container checkbox-svg`}>
                    <ReactSVG src={option.icon} />
                  </div>
                )}
                <Box as="span" flex={1}>
                  {option.label}
                  <sup>
                    {isIncompatible && '*'}
                    {isUnavailable && '+'}
                  </sup>
                </Box>
                <Box className="checkmark" display="none">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
              </Checkbox>
            );
          })}
        </Flex>
        {Array.isArray(incompatibleValues) && incompatibleValues.length > 0 && (
          <Text id="incompatible-services">
            <sup>*</sup>{' '}
            <Trans>
              Already included in or incompatible with a selected service.
            </Trans>
          </Text>
        )}
        {Array.isArray(unavailableValues) && unavailableValues.length > 0 && (
          <Text id="unavailable-services">
            <sup>+</sup> <Trans>Unavailable at the current location.</Trans>
          </Text>
        )}
      </fieldset>
    </UlfFormStep>
  );
};

const ServicesStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { services } = useSelectServicesStepValues();
  const franchiseId = useAppSelector(selectLeadFranchiseId);

  const form = useForm<ServicesStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: Object.fromEntries(
      Object.keys(rawServices).map((value) => [
        value,
        (services || []).includes(value),
      ]),
    ),
  });

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<ServicesStepFormValues> => {
    return async (values: ServicesStepFormValues) =>
      fn &&
      fn({
        services: Object.keys(values).filter((service) => values[service]),
      });
  };

  return (
    <FormProvider {...form}>
      <ServicesStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
        franchiseId={franchiseId}
      />
    </FormProvider>
  );
};

const ServicesStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <ServicesStep /> : <ServicesStepSummary />;
};

export default ServicesStepContainer;
