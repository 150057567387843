import { Trans } from 'react-i18next';
import { Button, BoxProps } from '@chakra-ui/react';
import { FormState, UseFormReturn } from 'react-hook-form';
import {
  IconDefinition,
  faArrowDown,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';

import { UseStepMethodsReturn } from 'hooks/useStepMethods';
import Intro from 'components/Intro';
import UlfStepContainer, {
  UlfStepButtonsContainer,
  UlfStepInner,
} from 'components/UlfStepContainer';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
// import FontAwesomeSubIcon from 'components/primatives/FontAwesomeSubIcon';

const UlfFormStep = ({
  title,
  description,
  rawDescription,
  handleSubmit,
  isSubmitting,
  isValid,
  onContinue,
  onSave,
  submitIcon,
  submitText,
  children,
}: UseStepMethodsReturn &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Pick<UseFormReturn<any>, 'handleSubmit'> &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Pick<FormState<any>, 'isSubmitting' | 'isValid'> &
  Pick<BoxProps, 'children'> & {
    title: string | JSX.Element;
    description?: string;
    rawDescription?: string | JSX.Element;
    submitIcon?: IconDefinition;
    submitText?: string;
  }) => {
  return (
    <UlfStepContainer>
      <form onSubmit={handleSubmit(onContinue)}>
        <UlfStepInner>
          <Intro
            title={title}
            description={description}
            rawDescription={rawDescription}
          />
          {children}
        </UlfStepInner>
        <UlfStepButtonsContainer>
          {onSave ? (
            <Button
              isLoading={isSubmitting}
              isDisabled={!isValid}
              onClick={handleSubmit(onSave)}
              variant="stepSubmit"
              data-test-id="save"
            >
              <FontAwesomeIcon icon={faCheck} mr={2} />
              <Trans>Save</Trans>
            </Button>
          ) : (
            <Button
              isLoading={isSubmitting}
              isDisabled={!isValid}
              type="submit"
              variant="stepSubmit"
              data-test-id="next"
            >
              <>
                <FontAwesomeIcon
                  icon={submitIcon ? submitIcon : faArrowDown}
                  mr={2}
                />
                {submitText ? submitText : <Trans>Next</Trans>}
              </>
            </Button>
          )}
        </UlfStepButtonsContainer>
      </form>
    </UlfStepContainer>
  );
};

export default UlfFormStep;
