/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import get from 'lodash/get';
import type { Dictionary } from 'lodash';
import { isDate } from 'date-fns';
import type { PartialDeep } from 'type-fest';

import {
  collectRoomAndInventory,
  endsWithCTA,
  hasDestination,
  isCustomerTypeHome,
  leadIsLocationOK,
  showInventory,
  validAddressStep,
  shouldSkipSqFt,
  shouldSkipConcerns,
} from './helperFunctions';
import { Lead } from '../../src/interfaces/mongoose.gen';

export type Step = {
  name: string;
  title: string;
  subtitle?: string;
  summaryName?: string;
  description: string;
  isValid: (lead: PartialDeep<Lead>) => boolean;
  canBeFinal?: boolean;
};

export const finalJunkStep = 'serviceDate';

const steps = (t_fn?: any) => {
  const t = t_fn ? t_fn : (x: any) => x;

  const steps = {} as Dictionary<Step>;

  steps['contact'] = {
    name: t('Contact Information'),
    title: t('Tell us a little about yourself'),
    description: 'We just need the basics.',
    // Only fields pulled into this step's form will be available here.
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return (
        (lead.name?.first || '').length > 0 &&
        (lead.name?.last || '').length > 0 &&
        (lead.email || '').length > 0 &&
        (lead.phone || '').length > 0
      );
    },
  };

  steps['customerType'] = {
    name: t('Customer type'),
    title: t('Are these services for a home or business?'),
    description: t(
      'Moving a household is very different than moving a business! This information helps us allocate people and supplies to expertly plan your move.',
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return (
        (lead.customerType || '').length > 0 &&
        (lead.customerType !== 'business' ||
          ((lead.businessName || '').length > 0 &&
            (lead.businessTitle || '').length > 0))
      );
    },
  };

  steps['fromAddress'] = {
    name: t('Service address'),
    title: t('Where will you need us?'),
    description: t(
      `We'll send our professionally trained crew to this address for the services you select.`,
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return validAddressStep(lead, 'fromAddress');
    },
  };

  // under review whether this is conditional based on incoming D8 selected location or automatically takes users through
  steps['locationSelector'] = {
    name: t('Location selector'),
    summaryName: t('TWO MEN AND A TRUCK Location'),
    title: t("We've got locations to serve you!"),
    description: t(
      'These are the {{pluralNumber}} locations closest to you. Please select your preferred location.',
    ),
    // Only fields pulled into this step's form will be available here.
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return leadIsLocationOK(lead);
    },
  };

  steps['services'] = {
    name: t('Services'),
    summaryName: t('How can we help'),
    title: t('How can we help you?'),
    description: t(
      'We offer a variety of services to give you the best experience possible. Which services will you need?',
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return (lead.services || []).length > 0;
    },
  };

  steps['endOfTheRoad'] = {
    name: t('End of the road'),
    title: t("Looks like it's the end of the road"),
    description: t(
      "Sorry, we don't give quotes for the combination of services you need. Please visit the page(s) below for more information.",
    ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValid: (_lead: PartialDeep<Lead>): boolean => {
      return false;
    },
    canBeFinal: true,
  };

  steps['squareFeet'] = {
    name: t('Square feet'),
    title: t('How big is your space?'),
    description: t(
      "You may not know this off the top of your head (and that's ok!). Having your exact square footage will allow us to give you a much more accurate estimate.",
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      const knownSquareFeet =
        lead.knowSquareFeet === true &&
        typeof lead.squareFeet === 'number' &&
        lead.squareFeet > 0;
      const unknownSquareFeetButPopulated =
        lead.knowSquareFeet === false &&
        typeof lead.knowSquareFeetUnder2000 === 'boolean';
      return knownSquareFeet || unknownSquareFeetButPopulated;
    },
  };

  steps['serviceDate'] = {
    name: t('Service date'),
    title: t('When will you need us?'),
    description: t(
      "If you don't have an exact date just yet, please pick an approximate date. This does not guarantee our availability on your preferred date. Keep in mind, weekday rates may be lower than weekends!",
    ),
    canBeFinal: true, // For junk.
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return typeof lead.preferredServiceDate === 'object'
        ? isDate(lead.preferredServiceDate)
        : (lead.preferredServiceDate || '').length > 0;
    },
  };

  steps['toAddress'] = {
    name: t('Destination address'),
    title: t('What is the destination for your belongings?'),
    description: t(
      "Any move big or small, local or long distance. We've got this!",
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return validAddressStep(lead, 'toAddress');
    },
  };

  steps['additionalStops'] = {
    name: t('Additional stops'),
    title: t('Do you have stops to make along the way?'),
    description: t(
      "Whether it's a straight shot from point A to point B, or you have to swing by and pick up items at a different location. No worries! We'll get whatever you need to your new place. Just indicate how many stops you need us to make.",
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return (
        typeof lead.hasAdditionalStops !== 'undefined' &&
        (lead.hasAdditionalStops === false ||
          get(lead, 'additionalStops', 0) > 0)
      );
    },
  };

  steps['concerns'] = {
    name: t('Concerns'),
    title: t(`What's important to you?`),
    description: t(
      `We know moving can be quite the process, so it's natural to have some concerns. Please let us know below if there's anything we can pay close attention to and put your mind at ease!`,
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return typeof lead.hasConcerns === 'boolean';
    },
  };

  // User chooses to start inventory or skip to contactTime.
  // Inventory button will conditionally go to full addresses step or to specialty items if full condition met.
  steps['nextSteps'] = {
    name: t('Next steps'),
    title: t('The final details'),
    description: t(
      `We'd like to know a bit more about your belongings. The quickest way is to provide that here, or if you prefer, we'll call you to collect those details.`,
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return typeof lead.roomsStart === 'boolean';
    },
    canBeFinal: true,
  };

  steps['inventory'] = {
    name: t('Items'),
    title: t(`Let's start your inventory!`),
    description: t(
      `Keeping track of all your belongings can be difficult. But we're here to help! Keep track of your inventory by grouping it in the rooms and spaces they're in.`,
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return (
        (lead.rooms || []).length > 0 &&
        typeof (lead.rooms || []).find((room) => room.isValid !== true) ===
          'undefined'
      );
    },
  };

  steps['supplies'] = {
    title: t('In need of supplies?'),
    name: t('Supplies'),
    subtitle: t('Are you sure?'),
    description: t(
      `Some of your items may require specific packing by law, whether supplied by you or by us.`,
    ),
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return typeof lead.wantsSupplies === 'boolean';
    },
    canBeFinal: true,
  };

  steps['finish'] = {
    // These values are dynamic, so they are baked into the component.
    name: '',
    title: '',
    description: '',
    isValid: (lead: PartialDeep<Lead>): boolean => {
      return lead.formComplete === true;
    },
    canBeFinal: true,
  };

  return steps;
};

export const INCOMPLETE_STEP = 'INCOMPLETE';

export const calcValidSteps = (updatedLead: PartialDeep<Lead>): string[] => {
  const steps = [
    'contact',
    'customerType',
    'fromAddress',
    'locationSelector',
    'services',
  ];

  if (endsWithCTA(updatedLead)) {
    return [...steps, 'endOfTheRoad'];
  }

  if (!shouldSkipSqFt(updatedLead)) {
    steps.push('squareFeet');
  }

  steps.push('serviceDate');

  if (hasDestination(updatedLead)) {
    steps.push('toAddress');
  }

  if (isCustomerTypeHome(updatedLead) && hasDestination(updatedLead)) {
    steps.push('additionalStops');
  }

  if (!shouldSkipConcerns(updatedLead)) {
    steps.push('concerns');
  }

  if (
    collectRoomAndInventory(updatedLead) &&
    typeof updatedLead.roomsStart === 'undefined'
  ) {
    steps.push('nextSteps');
  }

  if (showInventory(updatedLead)) {
    steps.push('inventory');
    steps.push('supplies');
  }

  steps.push('finish');

  return steps;
};

export default steps;
