import { useRef } from 'react';
import type { Dictionary } from 'lodash';
import { CalendarState } from 'react-stately';
import {
  useCalendar,
  useLocale,
  useButton,
  AriaButtonProps,
  useCalendarGrid,
  useCalendarCell,
} from 'react-aria';
import {
  CalendarDate,
  getWeeksInMonth,
  endOfMonth,
  isSameMonth,
} from '@internationalized/date';
import { Box, Heading, Button as ChakraButton, Flex } from '@chakra-ui/react';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';

export function CalendarCell({
  state,
  date,
  currentMonth,
}: {
  state: CalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
}) {
  const ref = useRef<HTMLButtonElement>(null);
  const { cellProps, buttonProps, isSelected, formattedDate } = useCalendarCell(
    { date },
    state,
    ref,
  );

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <Box as="td" {...cellProps} textAlign="center">
      <ChakraButton
        {...buttonProps}
        ref={ref}
        hidden={isOutsideMonth}
        variant={isSelected ? 'secondary' : 'unstyled'}
        px={0}
        py={2.5}
        mx="auto"
        _dark={{ mx: 'auto', px: 0, py: 2.5 }}
      >
        {formattedDate}
      </ChakraButton>
    </Box>
  );
}

export function CalendarGrid({
  state,
  offset = {},
}: {
  state: CalendarState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offset?: Dictionary<any>;
}) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <Box
      as="table"
      {...gridProps}
      width="100%"
      maxWidth="ufl.fieldWrapperMaxWidth"
    >
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </Box>
  );
}

export function CalendarButton(props: AriaButtonProps<'button'>) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <ChakraButton {...buttonProps} ref={ref} variant="unstyled">
      {props.children}
    </ChakraButton>
  );
}

const DayPicker = ({ state }: { state: CalendarState }) => {
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar({}, state);

  return (
    <Flex direction="column" align="center" {...calendarProps}>
      <Flex align="center" pb={4}>
        <CalendarButton {...prevButtonProps}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </CalendarButton>
        <Heading as="h2" size="md" flex="1" textAlign="center">
          {title}
        </Heading>
        <CalendarButton {...nextButtonProps}>
          <FontAwesomeIcon icon={faAngleRight} />
        </CalendarButton>
      </Flex>
      <CalendarGrid state={state} />
    </Flex>
  );
};

export default DayPicker;
