import { PersistGate } from 'redux-persist/integration/react';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Flex,
  Center,
  Alert,
  AlertIcon,
  AlertDescription,
  ChakraProvider,
  ColorModeScript,
  Box,
  VisuallyHidden,
} from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SkipNavLink, SkipNavContent } from '@chakra-ui/skip-nav';

import { UFL_DOMAIN_EXT, UFL_ENVIRONMENT, isProd, gtmLoad } from 'utils';
import i18n from 'i18n';
import theme from 'chakra';
import { persistor, store } from 'store/configureStore';
import Loading from 'components/Loading';
import App from 'components/App';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FooterIndicator from 'components/FooterIndicator';

const queryClient = new QueryClient();

const TopLevelLoading = () => {
  return (
    <Center h="100vh">
      <Loading />
    </Center>
  );
};

const RootContainer = () => {
  const { ready } = useTranslation('translation', { useSuspense: false });

  useEffect(() => {
    gtmLoad();
  }, []);

  // if (!ready) {
  //   return (
  //     <ChakraProvider theme={theme}>
  //       <TopLevelLoading />
  //     </ChakraProvider>
  //   );
  // }

  const canada = UFL_DOMAIN_EXT === 'ca';

  const customerServicePhone = canada ? '866.684.6448' : '800.345.1070';

  const homeURL = isProd
    ? `https://twomenandatruck.${UFL_DOMAIN_EXT}`
    : `https://brand-d8-${UFL_ENVIRONMENT}.twomenbeta.${UFL_DOMAIN_EXT}`;

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          fallback={
            <Box p={4}>
              <Center>
                <Box
                  as="a"
                  name="Home"
                  id="logo"
                  href={homeURL}
                  target="_blank"
                  rel="noopener"
                  // aria-label={t('Two Men and a Truck, Movers Who Care')}
                  layerStyle="siteLogo"
                  mb={4}
                >
                  <VisuallyHidden>TWO MEN AND A TRUCK</VisuallyHidden>
                </Box>
              </Center>
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Something went wrong! Please contact us at{' '}
                  <Box
                    as="a"
                    href={`tel:${customerServicePhone}`}
                    textDecoration="underline"
                    fontWeight="bold"
                  >
                    {customerServicePhone}
                  </Box>{' '}
                  to continue your quote.
                </AlertDescription>
                {/* </Box> */}
              </Alert>
            </Box>
          }
        >
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <PersistGate persistor={persistor} loading={<TopLevelLoading />}>
            <Suspense fallback={<TopLevelLoading />}>
              <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                  {/* <Router history={props.history}> */}
                  <CookiesProvider>
                    <SkipNavLink>Skip to Main Content</SkipNavLink>
                    {/* <CookieConsent /> */}
                    <Header isTranslationReady={ready} />
                    {!ready && <TopLevelLoading />}
                    <Flex
                      as="main"
                      role="main"
                      py={{ base: 8, md: 12 }}
                      direction="column"
                      gap={{ base: 2, md: 4 }}
                      px={{ base: 4, md: 8 }}
                      flexGrow="1"
                    >
                      <SkipNavContent />
                      {ready && <App />}
                    </Flex>
                    {ready && <Footer />}
                    {ready && <FooterIndicator />}
                  </CookiesProvider>
                  {/* </Router> */}
                </Provider>
              </I18nextProvider>
            </Suspense>
          </PersistGate>
        </ErrorBoundary>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default RootContainer;
