export type PackingSupply = {
  label: string;
  icon: string;
  isConditional?: boolean;
  isRequired?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const packingSupplies = (t_fn?: any): Record<string, PackingSupply> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const t = t_fn ? t_fn : (x: any) => x;

  const supplies = {
    mattressBag: {
      label: t('Mattress bag'),
      icon: '/img/icon-supplies-mattress-bag.svg',
      isConditional: true,
      isRequired: true,
    },
    tvBox: {
      label: t('TV box'),
      icon: '/img/icon-supplies-tv-box.svg',
      isConditional: true,
      isRequired: true,
    },
    boxes: {
      label: t('Boxes'),
      icon: '/img/icon-supplies-boxes.svg',
    },
    other: {
      label: t('Other moving supplies'),
      icon: '/img/icon-supplies-other.svg',
    },
  };

  return supplies;
};

export default packingSupplies;
