import React from 'react';
import { Center } from '@chakra-ui/react';

import Loading from 'components/Loading';
import UlfStepContainer, { UlfStepInner } from 'components/UlfStepContainer';

const LoadingCard = () => {
  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Center p={4}>
          <Loading />
        </Center>
      </UlfStepInner>
    </UlfStepContainer>
  );
};

export default LoadingCard;
