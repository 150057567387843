import { darken, lighten } from 'color2k';

const tmtGold = '#f2a900';
const tmtBlue = '#294a93';
const tmtGray05 = '#f5f5f5';
const tmtGray10 = '#ebebeb';
const tmtGray20 = '#d2d2d2';
const tmtGray45 = '#848484';
const tmtGray55 = '#6e6e6e';
const tmtGray75 = '#464646';
const tmtGray80 = '#333333';
const tmtBlack = '#000000';
const tmtBlackTranslucent = 'rgba(0, 0, 0, 0.9)';
const tmtPurple = '#8e7ed9';
const tmtCareersBlue = '#00819c';
const tmtCareersGreen = '#5db234';
const primaryColor = tmtGold;
const accentColor = tmtBlue;
const borderColor = tmtGray20;
const darkBorderColor = tmtGray45;
const bodyBgColor = tmtGray05;
const darkBodyBgColor = tmtGray80;
const bodyFontColor = tmtBlack;
const darkBodyFontColor = '#fff';
const grayText = tmtGray20;
const linkColor = primaryColor;
const linkColorHover = darken(primaryColor, 0.1);
const linkColorActive = linkColorHover;
const linkColorFocus = linkColorHover;
const placeholderColor = tmtGray45;
const darkPlaceholderColor = tmtGray20;
const placeholderColorFocus = '#fff';
const validColor = tmtCareersGreen;
const errorColor = '#ec0448';
const errorHoverColor = darken(errorColor, 0.1);
const successColor = tmtCareersGreen;
const warningColor = tmtGold;
const infoColor = tmtBlue;
const hoverOutlineColor = tmtBlue;
const focusOutlineColor = tmtBlue;
const darkHoverOutlineColor = tmtGold;
const darkFocusOutlineColor = tmtGold;
const primaryButtonColor = tmtGold;
const darkPrimaryButtonColor = tmtBlue;
const primaryButtonHoverColor = 'orange.400';
const darkPrimaryButtonHoverColor = 'blue.600';

export const colorSemanticTokens = {
  tmtGold,
  tmtBlue,
  tmtGray05,
  tmtGray10,
  tmtGray20,
  tmtGray45,
  tmtGray55,
  tmtGray75,
  tmtGray80,
  tmtBlack,
  tmtBlackTranslucent,
  tmtPurple,
  tmtCareersBlue,
  tmtCareersGreen,
  primaryColor,
  accentColor,
  borderColor,
  darkBorderColor,
  bodyFontColor,
  darkBodyFontColor,
  grayText,
  linkColor,
  linkColorHover,
  linkColorActive,
  linkColorFocus,
  placeholderColor,
  darkPlaceholderColor,
  placeholderColorFocus,
  validColor,
  errorColor,
  errorHoverColor,
  successColor,
  warningColor,
  infoColor,
  bodyBgColor,
  darkBodyBgColor,
  hoverOutlineColor,
  focusOutlineColor,
  darkHoverOutlineColor,
  darkFocusOutlineColor,
  primaryButtonColor,
  darkPrimaryButtonColor,
  primaryButtonHoverColor,
  darkPrimaryButtonHoverColor,
};

const colors = {
  gray: {
    '50': tmtGray05,
    '100': tmtGray10,
    '200': tmtGray20,
    '500': tmtGray45,
    '600': tmtGray55,
    '700': tmtGray75,
    '800': tmtGray80,
    '900': darken(tmtGray80, 0.025),
  },
  blue: {
    '50': lighten(tmtBlue, 0.6),
    '100': lighten(tmtBlue, 0.55),
    '200': '#94a5c9',
    '300': '#6980b3',
    '400': lighten(tmtBlue, 0.25),
    '500': tmtBlue,
    '600': '#24438b',
    '700': '#1f3a80',
    '800': '#193276',
    '900': '#0f2264',
  },
  orange: {
    '50': '#fdf5e0',
    '100': '#fbe5b3',
    '200': '#f9d480',
    '300': '#f6c34d',
    '400': '#f4b626',
    '500': tmtGold,
    '600': darken(tmtGold, 0.025), // '#f0a200',
    '700': '#ee9800',
    '800': '#ec8f00',
    '900': darken(tmtGold, 0.35),
  },
};

export default colors;
