/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const customerType = (t_fn?: any) => {
  const t = t_fn ? t_fn : (x: any) => x;

  return {
    home: {
      label: t('Home'),
    },
    business: {
      label: t('Business'),
    },
  };
};

export default customerType;
