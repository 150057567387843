import { createConstants } from 'utils';

export default createConstants(
  'CREATE_LEAD_REQUEST',
  'CREATE_LEAD_SUCCESS',
  'CREATE_LEAD_FAILURE',

  'FIND_LEAD_REQUEST',
  'FIND_LEAD_SUCCESS',
  'FIND_LEAD_FAILURE',

  'FIND_ESTIMATE_REQUEST',
  'FIND_ESTIMATE_SUCCESS',
  'FIND_ESTIMATE_FAILURE',

  'UPDATE_ESTIMATE_REQUEST',
  'UPDATE_ESTIMATE_SUCCESS',
  'UPDATE_ESTIMATE_FAILURE',

  'FIND_ESTIMATE_LEAD_REQUEST',
  'FIND_ESTIMATE_LEAD_SUCCESS',
  'FIND_ESTIMATE_LEAD_FAILURE',

  'UPDATE_LEAD_REQUEST',
  'UPDATE_LEAD_SUCCESS',
  'UPDATE_LEAD_FAILURE',

  'AUTHENTICATE_RETURNING_REQUEST',
  'AUTHENTICATE_RETURNING_SUCCESS',
  'AUTHENTICATE_RETURNING_FAILURE',

  'AUTHENTICATE_CSR_REQUEST',
  'AUTHENTICATE_CSR_SUCCESS',
  'AUTHENTICATE_CSR_FAILURE',

  'LOCATION_SEARCH_REQUEST',
  'LOCATION_SEARCH_SUCCESS',
  'LOCATION_SEARCH_FAILURE',

  'LOCATION_LOAD_REQUEST',
  'LOCATION_LOAD_SUCCESS',
  'LOCATION_LOAD_FAILURE',

  'THEME_CLASS_REQUEST',
  'THEME_CLASS_SUCCESS',
  'THEME_CLASS_FAILURE',

  'CLOSED_DATES_REQUEST',
  'CLOSED_DATES_SUCCESS',
  'CLOSED_DATES_FAILURE',

  'GTM_EVENT',
  'SAVE_LEAD_DETAILS',
  'SAVE_LOCATION_DETAILS',
  'CHANGE_LANGUAGE',
  'CHANGE_ROOM_INDEX',
  'SAVE_PERCENT_COMPLETE',
  'UPDATE_CLOSE_LOCATIONS',
  'SET_UPDATING',
  'RESET_LEAD',
  'FULL_RESET',
  'STASH_FINISHED_LEAD',
  'REMOVE_FINISHED_LEAD',

  'SET_ESTIMATE_POSTAL_CODE',
  'INCREMENT_ATTEMPTS',
  'SET_DETAIL_ERROR',
  'SET_DETAIL_DATA_COMPLETE',
  'SET_RESPONSE_ACTION_TYPE',
  'SET_USER_MESSAGE',
  'MAPS_LIBRARY_LOADED',
  'SET_GEO_IP_LOCATION',

  'ENABLE_COOKIES',
  'TOGGLE_COOKIE_BANNER',

  'SET_WEBSOCKET_READYSTATE',
  'UPDATE_CURRENT_LOCATION',
);
