import { Link } from 'wouter';
import trimStart from 'lodash/trimStart';
import { Trans, useTranslation } from 'react-i18next';
import { UnknownAction } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from '@chakra-ui/react';

import logger from 'logger';
import { postContinue } from 'services/backend';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { reauthenticate } from 'actions/appActions';
import UlfStepContainer, {
  UlfStepButtonsContainer,
  UlfStepInner,
} from 'components/UlfStepContainer';
import Intro from 'components/Intro';
import { contactIcons } from 'components/steps/1-ContactStep';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import LoadingCard from './LoadingCard';

export const ContinueError = () => {
  const { t } = useTranslation();
  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Intro title={t(`Uh oh!`)} />
        <Alert status="error" mb={4}>
          <AlertIcon />
          <Box>
            <AlertTitle>
              <Trans>There was a problem with your request!</Trans>{' '}
            </AlertTitle>
            <AlertDescription>
              <Trans>Were you trying to get moving again on your quote?</Trans>
            </AlertDescription>
          </Box>
        </Alert>
      </UlfStepInner>
      <UlfStepButtonsContainer>
        <Button
          as={Link}
          href="/reauth"
          variant="stepSubmit"
          leftIcon={<FontAwesomeIcon icon={contactIcons.email} />}
        >
          <Trans>Request a new link</Trans>
        </Button>
      </UlfStepButtonsContainer>
    </UlfStepContainer>
  );
};

const Continue = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector((state) => state.router.location?.search);
  const trimmedQS = trimStart(searchParams || '', '?') || '';
  const params = new URLSearchParams(trimmedQS);
  const ui = params.get('ui') || '';
  const uiParts = ui.split('/');

  const response = useQuery({
    queryKey: ['continue'],
    queryFn: async () => {
      try {
        const result = await postContinue({
          encodedID: uiParts[0],
          encodedToken: uiParts[1],
        });
        dispatch(
          reauthenticate({
            result,
          }) as unknown as UnknownAction,
        );
        toast({
          title: t('Welcome back!'),
          description: t(`Let's pick up where you left off.`),
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        return 'success';
      } catch (error) {
        logger.error(error as string);
        throw error;
      }
    },
    enabled: Boolean(ui && uiParts.length === 2),
    retry: false,
  });

  if (ui.length === 0 || uiParts.length !== 2 || response.isError) {
    return <ContinueError />;
  }

  return <LoadingCard />;
};

export default Continue;
