import { Trans, useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import type { Dictionary } from 'lodash';
import {
  Box,
  Button,
  Flex,
  Text,
  LinkBox,
  LinkOverlay,
  Heading,
} from '@chakra-ui/react';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import untranslatedSteps from '@backend/lib/lead/steps';
import untranslatedServices from '@backend/lib/mwc/services';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { saveStep } from 'actions/leadActions';
import { selectLeadServices } from 'slices/leadSlice';
import UlfStepContainer, { UlfStepInner } from 'components/UlfStepContainer';
import Intro from 'components/Intro';

const STEP_NAME = 'endOfTheRoad';

export const EndOfTheRoadStepForm = ({
  services,
  handleGoBack,
}: {
  services: Lead['services'];
  handleGoBack(): void;
}) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const serviceDefinitions = untranslatedServices(t);

  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Intro
          title={stepDefinition.title}
          description={stepDefinition.description}
        />
        <Flex direction="column" gap={{ base: 2, md: 4 }} mb={8}>
          {services.map((s) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const service: Dictionary<any> = serviceDefinitions[s];
            return (
              <LinkBox key={s}>
                <LinkOverlay
                  href={service.ctaURL}
                  target="_blank"
                  display="flex"
                  gap={4}
                  flexDirection={{ base: 'column', sm: 'row' }}
                  border="1px"
                  p={4}
                  _hover={{ borderColor: 'tmtBlue' }}
                  _focus={{
                    borderColor: 'tmtBlue',
                    outline: '4px solid',
                    outlineColor: 'tmtBlue',
                    outlineOffset: '4px',
                  }}
                  _dark={{
                    _hover: { borderColor: 'tmtGold' },
                    _focus: { borderColor: 'tmtGold', outlineColor: 'tmtGold' },
                  }}
                >
                  <Box width="72px" height="72px" mx="auto" flexShrink="0">
                    <ReactSVG src={service.icon} />
                  </Box>
                  <Box textAlign={{ base: 'center', sm: 'left' }}>
                    <Heading size="h3">{service.label}</Heading>
                    <Heading
                      size="h4"
                      textTransform="none"
                      fontWeight="400"
                      fontStyle="oblique"
                    >
                      {service.conversationalReply}
                    </Heading>
                    <Text>{service.customerContent}</Text>
                  </Box>
                </LinkOverlay>
              </LinkBox>
            );
          })}
        </Flex>
        <Flex direction="column" align="center" gap={4}>
          <Text>
            <Trans>Need to change your requested services?</Trans>
          </Text>
          <Box>
            <Button onClick={handleGoBack} size="lg">
              <Trans>Back to Services</Trans>
            </Button>
          </Box>
        </Flex>
      </UlfStepInner>
    </UlfStepContainer>
  );
};

const EndOfTheRoadStep = ({ isCurrentStep }: { isCurrentStep: boolean }) => {
  const dispatch = useAppDispatch();
  const services = useAppSelector(selectLeadServices);
  const handleGoBack = () => {
    dispatch(
      saveStep({
        stepToSave: STEP_NAME,
        lead: {},
        stepToNagivateTo: 'services',
      }),
    );
  };
  return isCurrentStep ? (
    <EndOfTheRoadStepForm services={services} handleGoBack={handleGoBack} />
  ) : (
    <></>
  );
};

export default EndOfTheRoadStep;
