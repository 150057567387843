// JS version of the JSON we'll get from the real endpoint.

export type InventoryType = {
  id: number;
  label: string;
  mwcLabel: string;
  mwcId: number;
  locationCapabilityName?: string;
  specialtyItem?: number;
  rooms?: number[];
  supplies?: string[];
};

const getInventory = (): InventoryType[] => [
  {
    id: 480,
    label: 'Small Air Compressor',
    mwcLabel: 'Air Compressor',
    mwcId: 480,
    rooms: [4, 11, 5],
  },
  {
    id: 481,
    label: 'Large Air Compressor',
    mwcLabel: 'Air Compressor Commercial',
    mwcId: 481,
    rooms: [4, 11, 5],
  },
  {
    id: 67,
    label: 'Air Conditioner',
    mwcLabel: 'Air Cond.',
    mwcId: 67,
    rooms: [4, 5, 1, 3],
  },
  {
    id: 256,
    label: 'Air Hockey Table',
    mwcLabel: 'Air Hockey Table',
    mwcId: 256,
    rooms: [5, 4],
  },
  {
    id: 445,
    label: 'Arcade Game',
    mwcLabel: 'Arcade Game',
    mwcId: 445,
    rooms: [5, 4, 1],
  },
  {
    id: 72,
    label: 'Armoire',
    mwcLabel: 'Armoire',
    mwcId: 72,
    rooms: [1, 3],
  },
  {
    id: 571,
    label: 'Awning',
    mwcLabel: 'Awning',
    mwcId: 571,
    rooms: [4],
  },
  {
    id: 353,
    label: 'Playpen/Pack-N-Play',
    mwcLabel: 'Baby - Playpen',
    mwcId: 353,
    rooms: [1, 3, 5],
  },
  {
    id: 354,
    label: 'Stroller',
    mwcLabel: 'Baby - Stroller',
    mwcId: 354,
    rooms: [1, 3, 5],
  },
  {
    id: 355,
    label: 'Baby Swing',
    mwcLabel: 'Baby - Swing',
    mwcId: 355,
    rooms: [1, 3, 5],
  },
  {
    id: 24,
    label: 'Bakers Rack',
    mwcLabel: 'Bakers Rack',
    mwcId: 24,
    rooms: [2, 8],
  },
  {
    id: 412,
    label: 'Bar',
    mwcLabel: 'Bar',
    mwcId: 412,
    rooms: [5, 17],
  },
  {
    id: 73,
    label: 'Stool',
    mwcLabel: 'Bar Stool',
    mwcId: 73,
    rooms: [5, 17, 2, 8],
  },
  {
    id: 172,
    label: 'Barbecue Grill',
    mwcLabel: 'Barbecue',
    mwcId: 172,
    rooms: [17],
  },
  {
    id: 305,
    label: 'Barbecue Smoker ',
    mwcLabel: 'Barbecue Smoker ',
    mwcId: 305,
    rooms: [17],
  },
  {
    id: 338,
    label: 'Basketball Hoop',
    mwcLabel: 'Basketball Hoop',
    mwcId: 338,
    rooms: [4, 17],
  },
  {
    id: 74,
    label: 'Box Spring',
    mwcLabel: 'Bed, Box Spring Only',
    mwcId: 74,
    rooms: [1, 5],
  },
  {
    id: 248,
    label: 'Bunk Bed',
    mwcLabel: 'Bed, Bunk Bed',
    mwcId: 248,
    rooms: [1, 5],
  },
  {
    id: 574,
    label: 'California King Bed',
    mwcLabel: 'Bed, California King',
    mwcId: 574,
    rooms: [1, 5],
  },
  {
    id: 456,
    label: 'Footboard',
    mwcLabel: 'Bed, Footboard',
    mwcId: 456,
    rooms: [1, 5],
  },
  {
    id: 75,
    label: 'Bed Frame',
    mwcLabel: 'Bed, Frame Only',
    mwcId: 75,
    rooms: [1, 5],
  },
  {
    id: 76,
    label: 'Headboard',
    mwcLabel: 'Bed, Head Board',
    mwcId: 76,
    rooms: [1, 5],
  },
  {
    id: 77,
    label: 'Bed Rails',
    mwcLabel: 'Bed, Rails Only',
    mwcId: 77,
    rooms: [1, 5],
  },
  {
    id: 458,
    label: 'Single Bed ',
    mwcLabel: 'Bed, Single',
    mwcId: 458,
    rooms: [1, 5],
  },
  {
    id: 78,
    label: 'Bassinette',
    mwcLabel: 'Bed, Size: Bassinette',
    mwcId: 78,
    rooms: [1, 5],
  },
  {
    id: 79,
    label: 'Crib',
    mwcLabel: 'Bed, Size: Crib ',
    mwcId: 79,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 60,
    label: 'Full Bed',
    mwcLabel: 'Bed, Size: Full',
    mwcId: 60,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 61,
    label: 'King Bed',
    mwcLabel: 'Bed, Size: King',
    mwcId: 61,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 63,
    label: 'Queen Bed',
    mwcLabel: 'Bed, Size: Queen ',
    mwcId: 63,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 64,
    label: 'Double/Twin Bed',
    mwcLabel: 'Bed, Size: Twin',
    mwcId: 64,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 455,
    label: 'Trundle/Day Bed',
    mwcLabel: 'Bed, Trundle',
    mwcId: 455,
    rooms: [1, 5],
    supplies: ['mattressBag'],
  },
  {
    id: 459,
    label: 'Double Water Bed',
    mwcLabel: 'Bed, Water - Double',
    mwcId: 459,
    rooms: [1, 5],
  },
  {
    id: 80,
    label: 'Full Water Bed',
    mwcLabel: 'Bed, Water - Full',
    mwcId: 80,
    rooms: [1, 5],
  },
  {
    id: 81,
    label: 'King Water Bed',
    mwcLabel: 'Bed, Water - King',
    mwcId: 81,
    rooms: [1, 5],
  },
  {
    id: 82,
    label: 'Queen Water Bed',
    mwcLabel: 'Bed, Water - Queen',
    mwcId: 82,
    rooms: [1, 5],
  },
  {
    id: 460,
    label: 'Single Water Bed',
    mwcLabel: 'Bed, Water - Single',
    mwcId: 460,
    rooms: [1, 5],
  },
  {
    id: 83,
    label: 'Twin Water Bed',
    mwcLabel: 'Bed, Water - Twin',
    mwcId: 83,
    rooms: [1, 5],
  },
  {
    id: 84,
    label: 'Bench ',
    mwcLabel: 'Bench ',
    mwcId: 84,
    rooms: [2, 3],
  },
  {
    id: 173,
    label: 'Bicycle',
    mwcLabel: 'Bicycle - Adult',
    mwcId: 173,
    rooms: [4, 17, 5],
  },
  {
    id: 174,
    label: 'Child Bicycle',
    mwcLabel: 'Bicycle - Child',
    mwcId: 174,
    rooms: [4, 17, 5],
  },
  {
    id: 307,
    label: 'Bicycle Trailer',
    mwcLabel: 'Bicycle - Trailer for Kids',
    mwcId: 307,
    rooms: [4, 17, 5],
  },
  {
    id: 465,
    label: 'Child Ride-On/Bigwheel',
    mwcLabel: 'Bigwheel',
    mwcId: 465,
    rooms: [4, 17],
  },
  {
    id: 548,
    label: 'Billiards Table',
    mwcLabel: 'Billiards Table',
    mwcId: 548,
    rooms: [5, 4],
  },
  {
    id: 563,
    label: 'Birdbath',
    mwcLabel: 'Birdbath',
    mwcId: 563,
    rooms: [17],
  },
  {
    id: 550,
    label: 'Bistro Table',
    mwcLabel: 'Bistro Table',
    mwcId: 550,
    rooms: [2, 8],
  },
  {
    id: 525,
    label: 'Blanket Rack',
    mwcLabel: 'Blanket Rack',
    mwcId: 525,
    rooms: [1, 5],
  },
  {
    id: 70,
    label: 'Bookshelf Under 4 Shelves',
    mwcLabel: 'Bookcase < 4',
    mwcId: 70,
    rooms: [19, 1],
  },
  {
    id: 71,
    label: 'Bookshelf More Than 4 Shelves',
    mwcLabel: 'Bookcase > 4',
    mwcId: 71,
    rooms: [19, 1],
  },
  {
    id: 308,
    label: 'Glass Bookcase',
    mwcLabel: 'Bookcase, Glass',
    mwcId: 308,
    rooms: [19, 1],
  },
  {
    id: 164,
    label: 'Dish Pack',
    mwcLabel: 'Box - Dish Pack',
    mwcId: 164,
    rooms: [2, 8, 1],
  },
  {
    id: 175,
    label: 'Lamp Box',
    mwcLabel: 'Box - Lamp',
    mwcId: 175,
    rooms: [1, 19, 5],
  },
  {
    id: 165,
    label: 'Large Box',
    mwcLabel: 'Box - Large',
    mwcId: 165,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 166,
    label: 'Medium Box',
    mwcLabel: 'Box - Medium',
    mwcId: 166,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 167,
    label: 'Large Picture Box',
    mwcLabel: 'Box - Pic-Large',
    mwcId: 167,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 168,
    label: 'Small Picture Box',
    mwcLabel: 'Box - Pic-Small',
    mwcId: 168,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 169,
    label: 'Small Box',
    mwcLabel: 'Box - Small',
    mwcId: 169,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 250,
    label: 'Small Tote',
    mwcLabel: 'Box - Tote',
    mwcId: 250,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 335,
    label: 'Large Tote',
    mwcLabel: 'Box - Tote Large',
    mwcId: 335,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 444,
    label: 'TV Box',
    mwcLabel: 'Box - TV Crate',
    mwcId: 444,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 170,
    label: 'Wardrobe Box',
    mwcLabel: 'Box - Wardrobe',
    mwcId: 170,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 171,
    label: 'Extra Large Box',
    mwcLabel: 'Box - Xtra Large',
    mwcId: 171,
    rooms: [1, 3, 2, 8, 19, 5, 6],
  },
  {
    id: 86,
    label: 'Small Buffet Table ',
    mwcLabel: 'Buffet < 4 ft.',
    mwcId: 86,
    rooms: [2, 8],
  },
  {
    id: 87,
    label: 'Large Buffet Table',
    mwcLabel: 'Buffet > 4 ft.',
    mwcId: 87,
    rooms: [2, 8],
  },
  {
    id: 25,
    label: 'Butcher Block',
    mwcLabel: 'Butcher Block',
    mwcId: 25,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 68,
    label: 'Small Cabinet',
    mwcLabel: 'Cabinet, < 4 long',
    mwcId: 68,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 58,
    label: 'Large Cabinet',
    mwcLabel: 'Cabinet, > 4 long',
    mwcId: 58,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 55,
    label: 'China Cabinet',
    mwcLabel: 'Cabinet, China',
    mwcId: 55,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 365,
    label: 'Cupboard',
    mwcLabel: 'Cabinet, Cupboard',
    mwcId: 365,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 56,
    label: 'Curio Cabinet',
    mwcLabel: 'Cabinet, Curio',
    mwcId: 56,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 267,
    label: 'Display Case',
    mwcLabel: 'Cabinet, Display Case',
    mwcId: 267,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 425,
    label: 'Gun Cabinet',
    mwcLabel: 'Cabinet, Gun',
    mwcId: 425,
    rooms: [2, 8, 5, 4, 1, 3],
  },
  {
    id: 88,
    label: 'Hutch Cabinet',
    mwcLabel: 'Cabinet, Hutch',
    mwcId: 88,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 507,
    label: 'Jewelry Cabinet',
    mwcLabel: 'Cabinet, Jewelry',
    mwcId: 507,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 268,
    label: 'Supply Cabinet',
    mwcLabel: 'Cabinet, Metal',
    mwcId: 268,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 366,
    label: 'Pantry',
    mwcLabel: 'Cabinet, Pantry',
    mwcId: 366,
    rooms: [2, 8, 1, 3],
  },
  {
    id: 368,
    label: 'Stereo Cabinet',
    mwcLabel: 'Cabinet, Stereo',
    mwcId: 368,
    rooms: [3, 8, 5],
  },
  {
    id: 497,
    label: 'TV Cabinet',
    mwcLabel: 'Cabinet, TV',
    mwcId: 497,
    rooms: [3, 8, 5],
  },
  {
    id: 369,
    label: 'Canoe',
    mwcLabel: 'Canoe',
    mwcId: 369,
    rooms: [4, 5, 17],
  },
  {
    id: 176,
    label: 'Baby Carriage',
    mwcLabel: 'Carriage-Baby',
    mwcId: 176,
    rooms: [1, 5, 6],
  },
  {
    id: 515,
    label: 'Cat Tree/Cat Condo',
    mwcLabel: 'Cat Tree',
    mwcId: 515,
    rooms: [1, 3],
  },
  {
    id: 89,
    label: 'Cedar Chest',
    mwcLabel: 'Cedar Chest',
    mwcId: 89,
    rooms: [1, 3, 5],
  },
  {
    id: 309,
    label: 'Adirondack Chair',
    mwcLabel: 'Chair, Adirondack',
    mwcId: 309,
    rooms: [3, 1],
  },
  {
    id: 91,
    label: 'Folding Chair',
    mwcLabel: 'Chair, Folding',
    mwcId: 91,
    rooms: [3, 5],
  },
  {
    id: 310,
    label: 'Gaming Chair',
    mwcLabel: 'Chair, Gaming',
    mwcId: 310,
    rooms: [5, 1],
  },
  {
    id: 92,
    label: 'High Chair',
    mwcLabel: 'Chair, High',
    mwcId: 92,
    rooms: [5, 2],
  },
  {
    id: 311,
    label: 'Lift Chair',
    mwcLabel: 'Chair, Lift',
    mwcId: 311,
    rooms: [3, 1],
  },
  {
    id: 370,
    label: 'Desk Chair',
    mwcLabel: 'Chair, Office',
    mwcId: 370,
    rooms: [19, 22, 12, 10],
  },
  {
    id: 93,
    label: 'Oversized Chair',
    mwcLabel: 'Chair, Oversized',
    mwcId: 93,
    rooms: [3, 1, 5],
  },
  {
    id: 473,
    label: 'Papasan Chair',
    mwcLabel: 'Chair, Papasan',
    mwcId: 473,
    rooms: [3, 1],
  },
  {
    id: 372,
    label: 'Patio Chair',
    mwcLabel: 'Chair, Patio',
    mwcId: 372,
    rooms: [17, 4],
  },
  {
    id: 373,
    label: 'Patio Folding Chair',
    mwcLabel: 'Chair, Patio Folding',
    mwcId: 373,
    rooms: [17, 4],
  },
  {
    id: 94,
    label: 'Recliner',
    mwcLabel: 'Chair, Reclining',
    mwcId: 94,
    rooms: [3, 1],
  },
  {
    id: 95,
    label: 'Rocking Chair',
    mwcLabel: 'Chair, Rocking',
    mwcId: 95,
    rooms: [3, 1],
  },
  {
    id: 96,
    label: 'Chair',
    mwcLabel: 'Chair, Straight',
    mwcId: 96,
    rooms: [3, 1, 5, 8],
  },
  {
    id: 312,
    label: 'Chaise Lounge',
    mwcLabel: 'Chaise Lounge',
    mwcId: 312,
  },
  {
    id: 496,
    label: 'Chalkboard',
    mwcLabel: 'Chalkboard',
    mwcId: 496,
    rooms: [5, 1],
  },
  {
    id: 97,
    label: 'Chest ',
    mwcLabel: 'Chest, 3 drawer',
    mwcId: 97,
    rooms: [1, 5],
  },
  {
    id: 506,
    label: 'Jewelry Chest',
    mwcLabel: 'Chest, Jewelry',
    mwcId: 506,
    rooms: [1],
  },
  {
    id: 477,
    label: 'Tall Boy Chest/Dresser',
    mwcLabel: 'Chest, Tall Boy',
    mwcId: 477,
    rooms: [1, 5, 3],
  },
  {
    id: 421,
    label: 'Christmas Tree',
    mwcLabel: 'Christmas Tree',
    mwcId: 421,
    rooms: [5, 6, 4],
  },
  {
    id: 377,
    label: 'Large Clock ',
    mwcLabel: 'Clock Large',
    mwcId: 377,
    rooms: [1, 3, 5],
  },
  {
    id: 376,
    label: 'Small Clock ',
    mwcLabel: 'Clock Small',
    mwcId: 376,
    rooms: [1, 3, 5],
  },
  {
    id: 100,
    label: 'Coat Rack',
    mwcLabel: 'Coat Rack',
    mwcId: 100,
    rooms: [3, 5, 1],
  },
  {
    id: 485,
    label: 'Large Copier',
    mwcLabel: 'Commercial Copier',
    mwcId: 485,
    rooms: [21, 13],
  },
  {
    id: 484,
    label: 'Large Printer',
    mwcLabel: 'Commercial Printer',
    mwcId: 484,
    rooms: [21, 13],
  },
  {
    id: 482,
    label: 'Computer Tower',
    mwcLabel: 'Computer Tower',
    mwcId: 482,
    rooms: [1, 5, 19, 12],
  },
  {
    id: 259,
    label: 'Computer',
    mwcLabel: 'Computer, CPU',
    mwcId: 259,
    rooms: [1, 5, 19, 12],
  },
  {
    id: 258,
    label: 'Laptop',
    mwcLabel: 'Computer, Laptop',
    mwcId: 258,
    rooms: [1, 5, 19, 12],
  },
  {
    id: 260,
    label: 'Monitor',
    mwcLabel: 'Computer, Monitor',
    mwcId: 260,
    rooms: [1, 5, 19, 12],
  },
  {
    id: 147,
    label: 'Concrete Items',
    mwcLabel: 'Concrete Items',
    mwcId: 147,
    rooms: [17, 4],
  },
  {
    id: 265,
    label: 'Copier Stand',
    mwcLabel: 'Copier Stand',
    mwcId: 265,
    rooms: [19, 13, 21],
  },
  {
    id: 262,
    label: 'Small Desktop Copier ',
    mwcLabel: 'Copier, Desktop',
    mwcId: 262,
    rooms: [19, 13, 21],
  },
  {
    id: 263,
    label: 'Copy Machine',
    mwcLabel: 'Copier, Standalone',
    mwcId: 263,
    rooms: [19, 13, 21],
  },
  {
    id: 253,
    label: 'Credenza',
    mwcLabel: 'Credenza',
    mwcId: 253,
    rooms: [3, 1, 5],
  },
  {
    id: 409,
    label: 'Cubicle',
    mwcLabel: 'Cubicle',
    mwcId: 409,
    rooms: [12, 22],
  },
  {
    id: 585,
    label: 'Deck Storage Box',
    mwcLabel: 'Deck Box',
    mwcId: 585,
    rooms: [17, 4],
  },
  {
    id: 1,
    label: 'Dehumidifier',
    mwcLabel: 'Dehumidifier',
    mwcId: 1,
    rooms: [5, 3, 1],
  },
  {
    id: 487,
    label: 'Corner Desk',
    mwcLabel: 'Desk - Corner',
    mwcId: 487,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 489,
    label: 'Large Desk',
    mwcLabel: 'Desk - Executive',
    mwcId: 489,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 490,
    label: 'Rolltop Desk',
    mwcLabel: 'Desk - Rolltop',
    mwcId: 490,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 488,
    label: 'School Desk',
    mwcLabel: 'Desk - School',
    mwcId: 488,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 378,
    label: 'L-Shaped Desk',
    mwcLabel: 'Desk, L Shaped',
    mwcId: 378,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 314,
    label: 'Desk With Hutch',
    mwcLabel: 'Desk, with Hutch',
    mwcId: 314,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 102,
    label: 'Desk',
    mwcLabel: 'Desk-Office',
    mwcId: 102,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 103,
    label: 'Secretary Desk',
    mwcLabel: 'Desk-Secretary',
    mwcId: 103,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 104,
    label: 'Small Desk',
    mwcLabel: 'Desk-Small',
    mwcId: 104,
    rooms: [19, 12, 22, 1, 5],
  },
  {
    id: 448,
    label: 'Dining Table Leaf',
    mwcLabel: 'Dining Table Leaf',
    mwcId: 448,
    rooms: [2, 5, 4],
  },
  {
    id: 2,
    label: 'Dishwasher',
    mwcLabel: 'Dishwasher',
    mwcId: 2,
    rooms: [2, 5],
  },
  {
    id: 517,
    label: 'Dog House',
    mwcLabel: 'Dog House',
    mwcId: 517,
    rooms: [17, 4],
  },
  {
    id: 410,
    label: 'Doll House',
    mwcLabel: 'Doll House',
    mwcId: 410,
    rooms: [1, 5],
  },
  {
    id: 589,
    label: 'Dolly',
    mwcLabel: 'Dolly',
    mwcId: 589,
    rooms: [4, 5],
  },
  {
    id: 69,
    label: 'Double Dresser',
    mwcLabel: 'Dresser, Double',
    mwcId: 69,
    rooms: [1, 5],
  },
  {
    id: 105,
    label: 'Dresser',
    mwcLabel: 'Dresser, Other',
    mwcId: 105,
    rooms: [1, 5],
  },
  {
    id: 65,
    label: 'Triple Dresser',
    mwcLabel: 'Dresser, Triple',
    mwcId: 65,
    rooms: [1, 5],
  },
  {
    id: 315,
    label: 'Triple Dresser With Mirror',
    mwcLabel: 'Dresser, Triple with Mirror',
    mwcId: 315,
    rooms: [1, 5],
  },
  {
    id: 66,
    label: 'Vanity Dresser',
    mwcLabel: 'Dresser, Vanity',
    mwcId: 66,
    rooms: [1, 5],
  },
  {
    id: 316,
    label: 'Vanity Dresser With Mirror',
    mwcLabel: 'Dresser, Vanity with Mirror',
    mwcId: 316,
    rooms: [1, 5],
  },
  {
    id: 282,
    label: 'Dry Erase Board',
    mwcLabel: 'Dry Erase Board',
    mwcId: 282,
    rooms: [5, 1, 3],
  },
  {
    id: 467,
    label: 'Dry Sink',
    mwcLabel: 'Dry Sink',
    mwcId: 467,
    rooms: [5],
  },
  {
    id: 3,
    label: 'Dryer-Electric',
    mwcLabel: 'Dryer-Electric',
    mwcId: 3,
    rooms: [28, 5],
  },
  {
    id: 4,
    label: 'Dryer-Gas',
    mwcLabel: 'Dryer-Gas',
    mwcId: 4,
    rooms: [28, 5],
  },
  {
    id: 560,
    label: 'Dumpster',
    mwcLabel: 'Dumpster',
    mwcId: 560,
    rooms: [17],
  },
  {
    id: 547,
    label: 'DVD/CD Rack',
    mwcLabel: 'DVD/CD Rack',
    mwcId: 547,
    rooms: [3, 5],
  },
  {
    id: 252,
    label: 'Electric Fireplace',
    mwcLabel: 'Electric Fireplace',
    mwcId: 252,
    rooms: [5, 1, 4],
  },
  {
    id: 440,
    label: 'Electric Keyboard',
    mwcLabel: 'Electric Keyboard',
    mwcId: 440,
    rooms: [5, 1],
  },
  {
    id: 565,
    label: 'Electric Scooter',
    mwcLabel: 'Electric Scooter',
    mwcId: 565,
    rooms: [17, 4],
  },
  {
    id: 534,
    label: 'Electric Wheelchair',
    mwcLabel: 'Electric Wheelchair',
    mwcId: 534,
    rooms: [4, 5],
  },
  {
    id: 5,
    label: 'Elliptical',
    mwcLabel: 'Elliptical',
    mwcId: 5,
    rooms: [5, 4, 17, 1],
  },
  {
    id: 43,
    label: 'Entertainment Center',
    mwcLabel: 'Entertain. Center',
    mwcId: 43,
    rooms: [3, 5],
  },
  {
    id: 6,
    label: 'Exercise Bike',
    mwcLabel: 'Exercise Bike',
    mwcId: 6,
    rooms: [5, 4, 17, 1],
  },
  {
    id: 406,
    label: 'Exercise Equipment',
    mwcLabel: 'Exercise Equipment',
    mwcId: 406,
    rooms: [5, 4, 17, 1],
  },
  {
    id: 528,
    label: 'Small Trampoline',
    mwcLabel: 'Exercise Trampoline',
    mwcId: 528,
    rooms: [17, 4],
  },
  {
    id: 7,
    label: 'Floor Fan',
    mwcLabel: 'Fan - Floor',
    mwcId: 7,
    rooms: [1, 3, 5, 4],
  },
  {
    id: 8,
    label: 'Table Fan',
    mwcLabel: 'Fan - Table',
    mwcId: 8,
    rooms: [1, 3, 5, 4],
  },
  {
    id: 292,
    label: 'Lateral File Cabinet',
    mwcLabel: 'File,5 dr. Lateral',
    mwcId: 292,
    rooms: [5, 19, 1, 3, 12, 13, 21],
  },
  {
    id: 106,
    label: 'Small File Cabinet',
    mwcLabel: 'Filing Cab. (2)',
    mwcId: 106,
    rooms: [5, 19, 1, 3, 12, 13, 21],
  },
  {
    id: 107,
    label: 'Filing Cabinet',
    mwcLabel: 'Filing Cab. (4)',
    mwcId: 107,
    rooms: [5, 19, 1, 3, 12, 13, 21],
  },
  {
    id: 342,
    label: 'Fire Pit',
    mwcLabel: 'Fire Pit',
    mwcId: 342,
    rooms: [17, 4],
  },
  {
    id: 379,
    label: 'Fireplace',
    mwcLabel: 'Fireplace',
    mwcId: 379,
    rooms: [17, 4, 5],
  },
  {
    id: 26,
    label: 'Fireplace Set',
    mwcLabel: 'Fireplace Set',
    mwcId: 26,
    rooms: [17, 4, 5, 3],
  },
  {
    id: 590,
    label: 'Fish Tank',
    mwcLabel: 'Fish Tank',
    mwcId: 590,
    rooms: [3, 1, 5],
  },
  {
    id: 357,
    label: 'Fooseball Table',
    mwcLabel: 'Fooseball Table',
    mwcId: 357,
    rooms: [3, 5, 1, 4],
  },
  {
    id: 343,
    label: 'Fountain',
    mwcLabel: 'Fountain',
    mwcId: 343,
    rooms: [17, 4],
  },
  {
    id: 9,
    label: 'Small Freezer',
    mwcLabel: 'Freezer < 10 cf',
    mwcId: 9,
    rooms: [4, 5],
  },
  {
    id: 10,
    label: 'Large Freezer',
    mwcLabel: 'Freezer > 10 cf',
    mwcId: 10,
    rooms: [4, 5],
  },
  {
    id: 319,
    label: 'Futon',
    mwcLabel: 'Futon',
    mwcId: 319,
    rooms: [1, 5],
  },
  {
    id: 558,
    label: 'Garbage Can - Indoor',
    mwcLabel: 'Garbage Can - Indoor',
    mwcId: 558,
    rooms: [5, 4, 2],
  },
  {
    id: 559,
    label: 'Garbage Can - Outdoor',
    mwcLabel: 'Garbage Can - Outdoor',
    mwcId: 559,
    rooms: [17, 4],
  },
  {
    id: 330,
    label: 'Generator',
    mwcLabel: 'Generator',
    mwcId: 330,
    rooms: [4, 17],
  },
  {
    id: 605,
    label: 'Double Glider',
    mwcLabel: 'Glider, Double',
    mwcId: 605,
    rooms: [4, 5, 17],
  },
  {
    id: 604,
    label: 'Single Glider',
    mwcLabel: 'Glider, Single',
    mwcId: 604,
    rooms: [4, 5, 17],
  },
  {
    id: 177,
    label: 'Golf Bag/Clubs',
    mwcLabel: 'Golf Bag/Clubs',
    mwcId: 177,
    rooms: [4, 1, 5],
  },
  {
    id: 350,
    label: 'Golf Cart',
    mwcLabel: 'Golf Cart',
    mwcId: 350,
    rooms: [4, 17],
  },
  {
    id: 111,
    label: 'Grandfather Clock',
    mwcLabel: 'Grandfather Clock',
    mwcId: 111,
    rooms: [1, 5, 3, 8],
  },
  {
    id: 435,
    label: 'Grandmother Clock',
    mwcLabel: 'Grandmother Clock',
    mwcId: 435,
    rooms: [1, 5, 3, 8],
  },
  {
    id: 454,
    label: 'Grill',
    mwcLabel: 'Grill',
    mwcId: 454,
    rooms: [17, 4],
  },
  {
    id: 606,
    label: 'Guitar',
    mwcLabel: 'Guitar',
    mwcId: 606,
    rooms: [1, 5, 6],
  },
  {
    id: 572,
    label: 'Gun Locker',
    mwcLabel: 'Gun Locker',
    mwcId: 572,
    rooms: [5, 1, 4],
  },
  {
    id: 11,
    label: 'Gym Set',
    mwcLabel: 'Gym Set',
    mwcId: 11,
    rooms: [17, 5, 4],
  },
  {
    id: 584,
    label: 'Hammock with Stand',
    mwcLabel: 'Hammock with Stand',
    mwcId: 584,
    rooms: [4, 17, 5],
  },
  {
    id: 112,
    label: 'Clothes Hamper',
    mwcLabel: 'Hamper, Clothes',
    mwcId: 112,
    rooms: [1, 5],
  },
  {
    id: 478,
    label: 'Hat Rack',
    mwcLabel: 'Hat Rack',
    mwcId: 478,
    rooms: [1, 3],
  },
  {
    id: 12,
    label: 'Space Heater',
    mwcLabel: 'Heater - Space',
    mwcId: 12,
    rooms: [1, 5, 4, 3, 19],
  },
  {
    id: 323,
    label: 'Outdoor Heater',
    mwcLabel: 'Heater, Outdoor',
    mwcId: 323,
    rooms: [17, 4, 5, 6],
  },
  {
    id: 549,
    label: 'Hightop Table',
    mwcLabel: 'Hightop Table',
    mwcId: 549,
    rooms: [2, 3, 5],
  },
  {
    id: 417,
    label: 'Hobby Table',
    mwcLabel: 'Hobby Table',
    mwcId: 417,
    rooms: [5, 1, 19],
  },
  {
    id: 380,
    label: 'Hockey Bag',
    mwcLabel: 'Hockey Bag',
    mwcId: 380,
    rooms: [5, 1, 4],
  },
  {
    id: 381,
    label: 'Hockey Net',
    mwcLabel: 'Hockey Net',
    mwcId: 381,
    rooms: [4, 17],
  },
  {
    id: 148,
    label: 'Garden Hose',
    mwcLabel: 'Hose - Garden',
    mwcId: 148,
    rooms: [4, 17, 5],
  },
  {
    id: 436,
    label: 'Hose Reel/Storage',
    mwcLabel: 'Hose Reel',
    mwcId: 436,
    rooms: [4, 17, 5],
  },
  {
    id: 348,
    label: 'Hot Tub',
    mwcLabel: 'Hot Tub',
    mwcId: 348,
    locationCapabilityName: 'hotTub',
    rooms: [17],
  },
  {
    id: 486,
    label: 'Humidifier',
    mwcLabel: 'Humidifier',
    mwcId: 486,
    rooms: [1, 3, 5, 19],
  },
  {
    id: 273,
    label: 'Hutch',
    mwcLabel: 'Hutch',
    mwcId: 273,
    rooms: [2, 8, 3],
  },
  {
    id: 432,
    label: 'Large Commercial Freezer',
    mwcLabel: 'Industrial Freezer',
    mwcId: 432,
    rooms: [2, 4, 5],
  },
  {
    id: 433,
    label: 'Large Commercial Refrigerator',
    mwcLabel: 'Industrial Fridge',
    mwcId: 433,
    rooms: [2, 4, 5],
  },
  {
    id: 295,
    label: 'Large Commercial Oven',
    mwcLabel: 'Industrial Oven',
    mwcId: 295,
    rooms: [2, 4, 5],
  },
  {
    id: 586,
    label: 'Inversion Table',
    mwcLabel: 'Inversion Table',
    mwcId: 586,
    rooms: [5, 1, 4],
  },
  {
    id: 180,
    label: 'Ironing Board',
    mwcLabel: 'Ironing Board',
    mwcId: 180,
    rooms: [1, 5, 28],
  },
  {
    id: 468,
    label: 'Island',
    mwcLabel: 'Island',
    mwcId: 468,
    rooms: [2, 5, 4],
  },
  {
    id: 503,
    label: 'Jacuzzi',
    mwcLabel: 'Jacuzzi',
    mwcId: 503,
    rooms: [17],
  },
  {
    id: 28,
    label: 'Jewelry Armoire',
    mwcLabel: 'Jewelry Armoire',
    mwcId: 28,
    rooms: [1],
  },
  {
    id: 382,
    label: 'Kayak',
    mwcLabel: 'Kayak',
    mwcId: 382,
    rooms: [4, 17, 5],
  },
  {
    id: 516,
    label: 'Kennel',
    mwcLabel: 'Kennel',
    mwcId: 516,
    rooms: [4, 5, 1, 17],
  },
  {
    id: 407,
    label: 'Kitchen Appliances',
    mwcLabel: 'Kitchen Appliances',
    mwcId: 407,
    rooms: [2, 5],
  },
  {
    id: 149,
    label: 'Extension Ladder',
    mwcLabel: 'Ladder, Extension',
    mwcId: 149,
    rooms: [4, 17, 5],
  },
  {
    id: 508,
    label: 'Folding Ladder',
    mwcLabel: 'Ladder, Folding',
    mwcId: 508,
    rooms: [4, 17, 5],
  },
  {
    id: 150,
    label: 'Step Ladder',
    mwcLabel: 'Ladder, Step',
    mwcId: 150,
    rooms: [4, 17, 5],
  },
  {
    id: 358,
    label: 'Table Lamp ',
    mwcLabel: 'Lamp Table',
    mwcId: 358,
    rooms: [1, 3, 19, 12, 10, 22, 5],
  },
  {
    id: 29,
    label: 'Floor Lamp',
    mwcLabel: 'Lamp, Floor',
    mwcId: 29,
    rooms: [1, 3, 8, 12, 22, 5],
  },
  {
    id: 591,
    label: 'Large Bean Bag',
    mwcLabel: 'Large Bean Bag',
    mwcId: 591,
    rooms: [1, 3, 5, 4, 17, 19, 12],
  },
  {
    id: 151,
    label: 'Large Plants/Pot',
    mwcLabel: 'Large Plants/Pot',
    mwcId: 151,
    rooms: [4, 5, 17],
  },
  {
    id: 408,
    label: 'Laundry Appliances',
    mwcLabel: 'Laundry Appliances',
    mwcId: 408,
    rooms: [28, 5],
  },
  {
    id: 500,
    label: 'Laundry Basket',
    mwcLabel: 'Laundry Basket',
    mwcId: 500,
    rooms: [28, 5, 1],
  },
  {
    id: 153,
    label: 'Push/Power Mower',
    mwcLabel: 'Lawn mower pwr',
    mwcId: 153,
    rooms: [4, 17],
  },
  {
    id: 154,
    label: 'Riding Mower',
    mwcLabel: 'Lawn mower ride',
    mwcId: 154,
    rooms: [4, 17],
  },
  {
    id: 573,
    label: 'Love Seat Sleeper/Hide-A-Bed',
    mwcLabel: 'Love Seat Hide-A-Bed',
    mwcId: 573,
    rooms: [3, 19, 5, 1],
  },
  {
    id: 30,
    label: 'Magazine Rack',
    mwcLabel: 'Magazine Rack',
    mwcId: 30,
    rooms: [3, 1, 19, 12, 22],
  },
  {
    id: 246,
    label: 'Mailbox',
    mwcLabel: 'Mailbox',
    mwcId: 246,
    rooms: [4, 5],
  },
  {
    id: 592,
    label: 'Massage Chair',
    mwcLabel: 'Massage Chair',
    mwcId: 592,
    rooms: [4, 5, 1],
  },
  {
    id: 587,
    label: 'Massage Table',
    mwcLabel: 'Massage Table',
    mwcId: 587,
    rooms: [4, 5, 1],
  },
  {
    id: 593,
    label: 'Mechanical Bed',
    mwcLabel: 'Mechanical Bed',
    mwcId: 593,
    rooms: [1],
  },
  {
    id: 13,
    label: 'Microwave',
    mwcLabel: 'Microwave',
    mwcId: 13,
    rooms: [2, 5],
  },
  {
    id: 304,
    label: 'Microwave Cart ',
    mwcLabel: 'Microwave Cart ',
    mwcId: 304,
    rooms: [2, 5],
  },
  {
    id: 349,
    label: 'Microwave Stand',
    mwcLabel: 'Microwave Stand',
    mwcId: 349,
    rooms: [2, 5],
  },
  {
    id: 512,
    label: 'Full Length Mirror',
    mwcLabel: 'Mirror, Full Length',
    mwcId: 512,
    rooms: [1, 5],
  },
  {
    id: 23,
    label: 'Wall Mirror',
    mwcLabel: 'Mirror-Dress-Wall',
    mwcId: 23,
    rooms: [1, 5],
  },
  {
    id: 320,
    label: 'Misc Garage Items',
    mwcLabel: 'Misc Garage Items',
    mwcId: 320,
    rooms: [4, 5],
  },
  {
    id: 322,
    label: 'Misc Toys',
    mwcLabel: 'Misc Toys',
    mwcId: 322,
    rooms: [1, 5, 4, 17],
  },
  {
    id: 438,
    label: 'Moped',
    mwcLabel: 'Moped',
    mwcId: 438,
    rooms: [4, 17, 5],
  },
  {
    id: 344,
    label: 'Motorcycle',
    mwcLabel: 'Motorcycle',
    mwcId: 344,
    rooms: [4, 17, 5],
  },
  {
    id: 44,
    label: 'Organ',
    mwcLabel: 'Organ',
    mwcId: 44,
    rooms: [4, 5, 3, 8],
  },
  {
    id: 113,
    label: 'Ottoman',
    mwcLabel: 'Ottoman',
    mwcId: 113,
    rooms: [3, 1, 5],
  },
  {
    id: 281,
    label: 'Paper Shredder',
    mwcLabel: 'Paper Shredder',
    mwcId: 281,
    rooms: [19, 12, 13, 21],
  },
  {
    id: 274,
    label: 'Partitions',
    mwcLabel: 'Partitions',
    mwcId: 274,
    rooms: [5, 1, 3, 8, 4],
  },
  {
    id: 324,
    label: 'Patio Chaise Lounge',
    mwcLabel: 'Patio Chaise Lounge',
    mwcId: 324,
    rooms: [17, 5, 4],
  },
  {
    id: 325,
    label: 'Patio Sofa',
    mwcLabel: 'Patio Sofa',
    mwcId: 325,
    rooms: [17, 5, 4],
  },
  {
    id: 116,
    label: 'Patio Table',
    mwcLabel: 'Patio Table',
    mwcId: 116,
    rooms: [17, 5, 4],
  },
  {
    id: 317,
    label: 'Pedestal',
    mwcLabel: 'Pedestal',
    mwcId: 317,
    rooms: [2, 5, 4, 17],
  },
  {
    id: 414,
    label: 'Pet Condo',
    mwcLabel: 'Pet Condo',
    mwcId: 414,
    rooms: [3, 1, 5, 4],
  },
  {
    id: 339,
    label: 'Pet Crate',
    mwcLabel: 'Pet Crate',
    mwcId: 339,
    rooms: [4, 5, 1, 3],
  },
  {
    id: 424,
    label: 'Pew',
    mwcLabel: 'Pew 15 ft',
    mwcId: 424,
    rooms: [4, 5, 3],
  },
  {
    id: 519,
    label: 'Electric Piano',
    mwcLabel: 'Piano, Electric',
    mwcId: 519,
    rooms: [3, 5, 1, 19],
  },
  {
    id: 155,
    label: 'Picnic Bench',
    mwcLabel: 'Picnic Bench',
    mwcId: 155,
    rooms: [17, 4, 5],
  },
  {
    id: 31,
    label: 'Small Picture',
    mwcLabel: 'Picture > 2 sq ft',
    mwcId: 31,
    rooms: [3, 1, 8, 19, 12, 22, 5, 2],
  },
  {
    id: 384,
    label: 'Large Picture',
    mwcLabel: 'Picture Large',
    mwcId: 384,
    rooms: [3, 1, 8, 19, 12, 22, 5, 2],
  },
  {
    id: 446,
    label: 'Pinball Machine',
    mwcLabel: 'Pinball Machine',
    mwcId: 446,
    rooms: [5, 4, 1],
  },
  {
    id: 32,
    label: 'Plant Stand',
    mwcLabel: 'Plant Stand',
    mwcId: 32,
    rooms: [4, 17, 5],
  },
  {
    id: 420,
    label: 'Plastic Drawers',
    mwcLabel: 'Plastic Drawers',
    mwcId: 420,
    rooms: [4, 5, 1],
  },
  {
    id: 404,
    label: 'Play Centre Large',
    mwcLabel: 'Play Centre Large',
    mwcId: 404,
    rooms: [4, 17, 5],
  },
  {
    id: 403,
    label: 'Play Centre Small',
    mwcLabel: 'Play Centre Small',
    mwcId: 403,
    rooms: [4, 17, 5],
  },
  {
    id: 522,
    label: 'Play House',
    mwcLabel: 'Play House',
    mwcId: 522,
    rooms: [4, 17, 5],
  },
  {
    id: 523,
    label: 'Play Kitchen',
    mwcLabel: 'Play Kitchen',
    mwcId: 523,
    rooms: [4, 17, 5],
  },
  {
    id: 326,
    label: 'Pool Cue Stand',
    mwcLabel: 'Pool Cue Stand',
    mwcId: 326,
    rooms: [4, 17, 5, 3],
  },
  {
    id: 405,
    label: 'Swing/Glider',
    mwcLabel: 'Porch Swing',
    mwcId: 405,
    rooms: [4, 5, 17],
  },
  {
    id: 568,
    label: 'Portable Closet',
    mwcLabel: 'Portable Closet',
    mwcId: 568,
    rooms: [1, 5],
  },
  {
    id: 453,
    label: 'Portable Crib',
    mwcLabel: 'Portable Crib',
    mwcId: 453,
    rooms: [1, 3, 5, 6],
  },
  {
    id: 329,
    label: 'Power Washer',
    mwcLabel: 'Power Washer',
    mwcId: 329,
    rooms: [4, 17, 5],
  },
  {
    id: 333,
    label: 'Powered Chair',
    mwcLabel: 'Powered Chair',
    mwcId: 333,
    rooms: [4, 17, 5],
  },
  {
    id: 483,
    label: 'Printer Stand',
    mwcLabel: 'Printer Stand',
    mwcId: 483,
    rooms: [5, 19, 12, 21, 13, 1],
  },
  {
    id: 261,
    label: 'Printer',
    mwcLabel: 'Printer, Desktop',
    mwcId: 261,
    rooms: [5, 19, 12, 21, 13, 1],
  },
  {
    id: 451,
    label: 'ATV/Quad',
    mwcLabel: 'Quad',
    mwcId: 451,
    rooms: [4, 17],
  },
  {
    id: 332,
    label: 'Quilt Rack',
    mwcLabel: 'Quilt Rack',
    mwcId: 332,
    rooms: [5, 19, 1],
  },
  {
    id: 418,
    label: 'Reclining Sofa',
    mwcLabel: 'Reclining Sofa',
    mwcId: 418,
    rooms: [3, 5, 4],
  },
  {
    id: 15,
    label: 'Small Refrigerator',
    mwcLabel: 'Refrig < 10 cf',
    mwcId: 15,
    rooms: [2, 5, 4],
  },
  {
    id: 16,
    label: 'Large Refrigerator',
    mwcLabel: 'Refrig > 10 cf',
    mwcId: 16,
    rooms: [2, 5, 4],
  },
  {
    id: 297,
    label: 'Mini Refrigerator',
    mwcLabel: 'Refrig, Mini',
    mwcId: 297,
    rooms: [2, 5, 4, 3, 1],
  },
  {
    id: 594,
    label: 'Rowing Machine',
    mwcLabel: 'Rowing Machine',
    mwcId: 594,
    rooms: [4, 5, 1],
  },
  {
    id: 33,
    label: 'Small Area Rug',
    mwcLabel: 'Rug < 8 x 10',
    mwcId: 33,
    rooms: [1, 3, 8, 5],
  },
  {
    id: 34,
    label: 'Large Area Rug',
    mwcLabel: 'Rug > 8 x 10 ',
    mwcId: 34,
    rooms: [1, 3, 8, 5],
  },
  {
    id: 50,
    label: 'Satellite Dish',
    mwcLabel: 'Satellite Dish',
    mwcId: 50,
    rooms: [4, 3, 1, 5, 17],
  },
  {
    id: 385,
    label: 'Sauna',
    mwcLabel: 'Sauna',
    mwcId: 385,
    rooms: [5, 4, 17],
  },
  {
    id: 441,
    label: 'Sawhorse',
    mwcLabel: 'Sawhorse',
    mwcId: 441,
    rooms: [17, 4, 5],
  },
  {
    id: 264,
    label: 'Small Scanner',
    mwcLabel: 'Scanner, Desktop',
    mwcId: 264,
    rooms: [19, 12, 21, 13, 11, 5, 1],
  },
  {
    id: 347,
    label: 'Scooter',
    mwcLabel: 'Scooter',
    mwcId: 347,
    rooms: [4, 5, 17],
  },
  {
    id: 119,
    label: 'Sewing Cabinet',
    mwcLabel: 'Sewing Cabinet',
    mwcId: 119,
    rooms: [19, 1, 3, 8, 5],
  },
  {
    id: 386,
    label: 'Sewing Machine',
    mwcLabel: 'Sewing Machine',
    mwcId: 386,
    rooms: [19, 1, 3, 8, 5],
  },
  {
    id: 276,
    label: 'Large Shelving Units',
    mwcLabel: 'Shelving Units, Large',
    mwcId: 276,
    rooms: [4, 5, 17, 11, 13, 21, 6, 28],
  },
  {
    id: 275,
    label: 'Small Shelving Units',
    mwcLabel: 'Shelving Units, Small',
    mwcId: 275,
    rooms: [4, 5, 17, 11, 13, 21, 6, 28],
  },
  {
    id: 416,
    label: 'Shoe Rack',
    mwcLabel: 'Shoe Rack',
    mwcId: 416,
    rooms: [1, 3, 5],
  },
  {
    id: 564,
    label: 'Shop Vac',
    mwcLabel: 'Shop Vac',
    mwcId: 564,
    rooms: [5, 4, 17],
  },
  {
    id: 121,
    label: 'Side Board',
    mwcLabel: 'Side Board',
    mwcId: 121,
    rooms: [3, 8, 5, 1],
  },
  {
    id: 361,
    label: 'Skiis',
    mwcLabel: 'Skiis',
    mwcId: 361,
    rooms: [5, 6, 4, 17],
  },
  {
    id: 552,
    label: 'Sled',
    mwcLabel: 'Sled',
    mwcId: 552,
    rooms: [5, 6, 4, 17],
  },
  {
    id: 588,
    label: 'Sleep Number Bed',
    mwcLabel: 'Sleep Number Bed',
    mwcId: 588,
    rooms: [1, 5],
  },
  {
    id: 156,
    label: 'Slide',
    mwcLabel: 'Slide',
    mwcId: 156,
    rooms: [17, 4],
  },
  {
    id: 383,
    label: 'Small Appliances',
    mwcLabel: 'Small Appliances',
    mwcId: 383,
    rooms: [2, 5, 8],
  },
  {
    id: 254,
    label: 'Snow Blower',
    mwcLabel: 'Snow Blower',
    mwcId: 254,
    rooms: [17, 4],
  },
  {
    id: 535,
    label: 'Snowboard',
    mwcLabel: 'Snowboard',
    mwcId: 535,
    rooms: [5, 4, 6],
  },
  {
    id: 423,
    label: 'Snowmobile',
    mwcLabel: 'Snowmobile',
    mwcId: 423,
    rooms: [4, 17],
  },
  {
    id: 502,
    label: 'Soccer Net',
    mwcLabel: 'Soccer Net',
    mwcId: 502,
    rooms: [4, 17],
  },
  {
    id: 122,
    label: 'Small Sectional Couch',
    mwcLabel: 'Sofa - 2 Cush - Sec',
    mwcId: 122,
    rooms: [3, 5, 1, 22, 19],
  },
  {
    id: 124,
    label: 'Couch',
    mwcLabel: 'Sofa - 3 Cush',
    mwcId: 124,
    rooms: [3, 5, 1, 22, 19],
  },
  {
    id: 123,
    label: 'Large Sectional Couch',
    mwcLabel: 'Sofa - 3 Cush - Sec',
    mwcId: 123,
    rooms: [3, 5, 1, 22, 19],
  },
  {
    id: 125,
    label: 'Hide-a-Bed/Sleeper Couch',
    mwcLabel: 'Sofa - Hide-a-Bed',
    mwcId: 125,
    rooms: [3, 5, 1, 22, 19],
  },
  {
    id: 126,
    label: 'Love Seat',
    mwcLabel: 'Sofa - Love ',
    mwcId: 126,
  },
  {
    id: 363,
    label: 'Large Statue',
    mwcLabel: 'Statue - Larger',
    mwcId: 363,
    rooms: [4, 17, 5],
  },
  {
    id: 362,
    label: 'Small Statue',
    mwcLabel: 'Statue - Small',
    mwcId: 362,
    rooms: [4, 17, 5],
  },
  {
    id: 18,
    label: 'Steam Cleaner',
    mwcLabel: 'Steam Cleaner',
    mwcId: 18,
    rooms: [5, 28, 1, 4],
  },
  {
    id: 533,
    label: 'Steam Room',
    mwcLabel: 'Steam Room',
    mwcId: 533,
    rooms: [4, 17, 5],
  },
  {
    id: 509,
    label: 'Step Stool',
    mwcLabel: 'Step Stool',
    mwcId: 509,
    rooms: [4, 1, 2, 8, 11],
  },
  {
    id: 391,
    label: 'Stereo',
    mwcLabel: 'Stereo',
    mwcId: 391,
    rooms: [3, 5, 1, 19],
  },
  {
    id: 51,
    label: 'Stereo Speaker',
    mwcLabel: 'Stereo Speaker',
    mwcId: 51,
    rooms: [3, 5, 1, 19],
  },
  {
    id: 413,
    label: 'Storage Bin',
    mwcLabel: 'Storage Bin',
    mwcId: 413,
    rooms: [28, 5, 4, 1, 11, 21, 13, 12],
  },
  {
    id: 157,
    label: 'Storage Shed',
    mwcLabel: 'Storage Shed',
    mwcId: 157,
    rooms: [17, 4],
  },
  {
    id: 527,
    label: 'Stove',
    mwcLabel: 'Stove',
    mwcId: 527,
    rooms: [2, 5, 4, 17],
  },
  {
    id: 22,
    label: 'Suitcase',
    mwcLabel: 'Suit Case',
    mwcId: 22,
    rooms: [1, 5, 6, 4],
  },
  {
    id: 415,
    label: 'Surfboard/Paddleboard',
    mwcLabel: 'Surfboard/Paddleboard',
    mwcId: 415,
    rooms: [4, 5, 17],
  },
  {
    id: 158,
    label: 'Swing Set',
    mwcLabel: 'Swing Set',
    mwcId: 158,
    rooms: [17, 4],
  },
  {
    id: 331,
    label: 'Table Saw',
    mwcLabel: 'Table Saw',
    mwcId: 331,
    rooms: [5, 4, 17],
  },
  {
    id: 128,
    label: 'Large Folding Table',
    mwcLabel: 'Table, Card - Large',
    mwcId: 128,
    rooms: [5, 4, 17],
  },
  {
    id: 129,
    label: 'Small Folding Table',
    mwcLabel: 'Table, Card, Folding',
    mwcId: 129,
    rooms: [5, 4, 17],
  },
  {
    id: 127,
    label: 'Changing Table',
    mwcLabel: 'Table, Changing',
    mwcId: 127,
    rooms: [1, 3, 1],
  },
  {
    id: 130,
    label: 'Coffee Table',
    mwcLabel: 'Table, Coffee',
    mwcId: 130,
    rooms: [1, 3, 19, 12],
  },
  {
    id: 257,
    label: 'Conference Table',
    mwcLabel: 'Table, Conference',
    mwcId: 257,
    rooms: [19, 12, 10, 5],
  },
  {
    id: 131,
    label: 'Small Dining Table',
    mwcLabel: 'Table, Dining < 4',
    mwcId: 131,
    rooms: [2, 8, 5],
  },
  {
    id: 132,
    label: 'Large Dining Table',
    mwcLabel: 'Table, Dining > 4',
    mwcId: 132,
    rooms: [2, 8, 5],
  },
  {
    id: 442,
    label: 'Drop Leaf Table',
    mwcLabel: 'Table, Drop Leaf',
    mwcId: 442,
    rooms: [8, 2, 5, 1],
  },
  {
    id: 134,
    label: 'Kitchen Table',
    mwcLabel: 'Table, Kitchen',
    mwcId: 134,
    rooms: [2, 8, 5, 4],
  },
  {
    id: 135,
    label: 'End Table',
    mwcLabel: 'Table, Lamp',
    mwcId: 135,
    rooms: [2, 3, 8, 1, 19, 12],
  },
  {
    id: 137,
    label: 'Night Stand',
    mwcLabel: 'Table, Night',
    mwcId: 137,
    rooms: [1, 5, 19],
  },
  {
    id: 138,
    label: 'Picnic Table',
    mwcLabel: 'Table, Picnic',
    mwcId: 138,
    rooms: [17, 4],
  },
  {
    id: 139,
    label: 'Ping Pong Table',
    mwcLabel: 'Table, Ping Pong',
    mwcId: 139,
    rooms: [5, 17, 4],
  },
  {
    id: 143,
    label: 'Sofa Table',
    mwcLabel: 'Table, Sofa',
    mwcId: 143,
    rooms: [3, 1, 5],
  },
  {
    id: 341,
    label: 'Taxidermy Animal Head',
    mwcLabel: 'Taxidermy Animal Head',
    mwcId: 341,
    rooms: [4, 5, 1, 17],
  },
  {
    id: 340,
    label: 'Taxidermy Whole Animal ',
    mwcLabel: 'Taxidermy Animal Whole',
    mwcId: 340,
    rooms: [4, 5, 1, 17],
  },
  {
    id: 249,
    label: 'Tea Cart',
    mwcLabel: 'Tea Cart',
    mwcId: 249,
    rooms: [2, 8, 5],
  },
  {
    id: 570,
    label: 'Tent',
    mwcLabel: 'Tent',
    mwcId: 570,
    rooms: [4, 5, 17],
  },
  {
    id: 396,
    label: 'Tires (4)',
    mwcLabel: 'Tires (4)',
    mwcId: 396,
    rooms: [17, 4, 5],
  },
  {
    id: 364,
    label: 'Toboggan',
    mwcLabel: 'Toboggan',
    mwcId: 364,
    rooms: [17, 4, 5],
  },
  {
    id: 569,
    label: 'Toddler Bed',
    mwcLabel: 'Toddler Bed',
    mwcId: 569,
    rooms: [5, 1, 6],
  },
  {
    id: 566,
    label: 'Tool Bench',
    mwcLabel: 'Tool Bench',
    mwcId: 566,
    rooms: [5, 4, 17],
  },
  {
    id: 181,
    label: 'Small Tool Chest',
    mwcLabel: 'Tool Chest-Hnd',
    mwcId: 181,
    rooms: [5, 4, 17],
  },
  {
    id: 183,
    label: 'Large Tool Chest',
    mwcLabel: 'Tool Chest-Roll',
    mwcId: 183,
    rooms: [5, 4, 17],
  },
  {
    id: 185,
    label: 'Power Tools',
    mwcLabel: 'Tool, Power - Medium',
    mwcId: 185,
    rooms: [5, 4, 17],
  },
  {
    id: 553,
    label: 'Small Toolbox',
    mwcLabel: 'Toolbox - Hand',
    mwcId: 553,
    rooms: [5, 4, 17],
  },
  {
    id: 554,
    label: 'Large Toolbox',
    mwcLabel: 'Toolbox - Lg',
    mwcId: 554,
    rooms: [5, 4, 17],
  },
  {
    id: 159,
    label: 'Yard Tools',
    mwcLabel: 'Tools - Yrd (5)',
    mwcId: 159,
    rooms: [17, 4, 5],
  },
  {
    id: 52,
    label: 'Toy Car',
    mwcLabel: 'Toy Car Motor',
    mwcId: 52,
    rooms: [5, 1, 3, 4, 6],
  },
  {
    id: 54,
    label: 'Toy Chest',
    mwcLabel: 'Toy Chest',
    mwcId: 54,
    rooms: [5, 1, 3, 4, 6],
  },
  {
    id: 187,
    label: 'Large Trampoline',
    mwcLabel: 'Trampoline',
    mwcId: 187,
    rooms: [5, 4, 17],
  },
  {
    id: 397,
    label: 'Large Treadmill',
    mwcLabel: 'Treadmill LG',
    mwcId: 397,
    rooms: [5, 1, 4, 17],
  },
  {
    id: 398,
    label: 'Small Treadmill',
    mwcLabel: 'Treadmill SM',
    mwcId: 398,
    rooms: [5, 1, 4, 17],
  },
  {
    id: 336,
    label: 'Folding Treadmill',
    mwcLabel: 'Treadmill, Folding',
    mwcId: 336,
    rooms: [5, 1, 4, 17],
  },
  {
    id: 464,
    label: 'Tricycle',
    mwcLabel: 'Tricycle',
    mwcId: 464,
    rooms: [4, 17, 5, 6],
  },
  {
    id: 145,
    label: 'Trunk',
    mwcLabel: 'Trunk',
    mwcId: 145,
    rooms: [6, 5, 1, 3],
  },
  {
    id: 146,
    label: 'TV Stand',
    mwcLabel: 'TV Stand',
    mwcId: 146,
    rooms: [3, 5, 1, 22, 19, 4, 2],
  },
  {
    id: 447,
    label: 'TV Tray',
    mwcLabel: 'TV Tray',
    mwcId: 447,
    rooms: [3, 5, 1, 22, 19, 4, 2],
  },
  {
    id: 40,
    label: 'Old Style Big Screen TV',
    mwcLabel: 'TV, Big Screen',
    mwcId: 40,
    rooms: [3, 5, 1, 22, 19, 4, 2],
    supplies: ['tvBox'],
  },
  {
    id: 41,
    label: 'TV Console',
    mwcLabel: 'TV, Console',
    mwcId: 41,
    rooms: [3, 5, 1, 22, 19, 4, 2],
    supplies: ['tvBox'],
  },
  {
    id: 247,
    label: 'Television',
    mwcLabel: 'TV, Flat Screen',
    mwcId: 247,
    rooms: [3, 5, 1, 22, 19, 4, 2],
    supplies: ['tvBox'],
  },
  {
    id: 161,
    label: 'Patio Umbrella ',
    mwcLabel: 'Umbrella Patio',
    mwcId: 161,
    rooms: [4, 17, 5],
  },
  {
    id: 479,
    label: 'Umbrella Stand',
    mwcLabel: 'Umbrella Stand',
    mwcId: 479,
    rooms: [4, 17, 5],
  },
  {
    id: 280,
    label: 'Utility Cart',
    mwcLabel: 'Utility Cart',
    mwcId: 280,
    rooms: [5, 4, 17],
  },
  {
    id: 19,
    label: 'Vacuum',
    mwcLabel: 'Vacuum',
    mwcId: 19,
    rooms: [5, 3, 1, 22, 19, 12],
  },
  {
    id: 575,
    label: 'Large Vase',
    mwcLabel: 'Vase - Large',
    mwcId: 575,
    rooms: [5, 3, 1, 22, 19, 12],
  },
  {
    id: 294,
    label: 'Vending Machine',
    mwcLabel: 'Vending Machine',
    mwcId: 294,
    rooms: [10, 12, 4, 5, 11, 22],
  },
  {
    id: 162,
    label: 'Wagon',
    mwcLabel: 'Wagon - Child',
    mwcId: 162,
    rooms: [5, 4, 17],
  },
  {
    id: 449,
    label: 'Wardrobe Cabinet',
    mwcLabel: 'Wardrobe',
    mwcId: 449,
    rooms: [5, 1, 3],
  },
  {
    id: 561,
    label: 'Washer',
    mwcLabel: 'Washer',
    mwcId: 561,
    rooms: [5, 4, 28],
  },
  {
    id: 562,
    label: 'Front Load Washer',
    mwcLabel: 'Washer, Front Load',
    mwcId: 562,
    rooms: [5, 4, 28],
  },
  {
    id: 298,
    label: 'Water Cooler',
    mwcLabel: 'Water Cooler',
    mwcId: 298,
    rooms: [4, 5, 6, 17],
  },
  {
    id: 411,
    label: 'Water Feature',
    mwcLabel: 'Water Feature',
    mwcId: 411,
    rooms: [17, 4],
  },
  {
    id: 189,
    label: 'Weight Bench',
    mwcLabel: 'Weight Bench',
    mwcId: 189,
    rooms: [5, 4, 17, 1],
  },
  {
    id: 191,
    label: 'Weight set',
    mwcLabel: 'Weight set',
    mwcId: 191,
    rooms: [5, 4, 17, 1],
  },
  {
    id: 21,
    label: 'Wet Vac',
    mwcLabel: 'Wet Vac',
    mwcId: 21,
    rooms: [5, 4, 17],
  },
  {
    id: 346,
    label: 'Wheel Chair',
    mwcLabel: 'Wheel Chair',
    mwcId: 346,
    rooms: [5, 6, 4, 3, 1],
  },
  {
    id: 255,
    label: 'Wheelbarrow',
    mwcLabel: 'Wheelbarrow',
    mwcId: 255,
    rooms: [4, 17],
  },
  {
    id: 495,
    label: 'White Board',
    mwcLabel: 'White Board',
    mwcId: 495,
    rooms: [5, 1, 19, 12, 10],
  },
  {
    id: 37,
    label: 'Wine Rack',
    mwcLabel: 'Wine Rack',
    mwcId: 37,
    rooms: [2, 8, 5],
  },
  {
    id: 402,
    label: 'Wire Storage Rack',
    mwcLabel: 'Wire Storage Rack',
    mwcId: 402,
    rooms: [5, 28, 4, 17],
  },
  {
    id: 334,
    label: 'Workbench ',
    mwcLabel: 'Workbench ',
    mwcId: 334,
    rooms: [17, 4, 5],
  },
  {
    id: 510,
    label: 'Mower zero turn',
    mwcLabel: 'Zero Turn Lawn Mower',
    mwcId: 510,
    rooms: [4, 17],
  },
  {
    id: 4500,
    label: 'Baby Grand Piano',
    mwcLabel: 'Baby Grand',
    mwcId: 45,
    locationCapabilityName: 'pianoBabyGrand',
    rooms: [1, 3, 18],
  },
  {
    id: 4700,
    label: 'Grand Piano',
    mwcLabel: 'Grand',
    mwcId: 47,
    locationCapabilityName: 'pianoGrand',
    rooms: [1, 3, 18],
  },
  {
    id: 4900,
    label: 'Upright/Standup Piano',
    mwcLabel: 'Standup',
    mwcId: 49,
    locationCapabilityName: 'pianoUpright',
    rooms: [1, 3, 18],
  },
  {
    id: 4901,
    label: 'Spinet Piano',
    mwcLabel: 'Spinet Piano',
    mwcId: 48,
    locationCapabilityName: 'pianoSpinet',
    rooms: [1, 3, 18],
  },
  {
    id: 14000,
    label: 'Pool Table, No Slate',
    mwcLabel: 'Pool Table, No Slate',
    mwcId: 140,
    locationCapabilityName: 'poolTableNonSlate',
    rooms: [1, 4, 18],
  },
  {
    id: 14001,
    label: 'Pool Table, One Piece Slate',
    mwcLabel: 'Pool Table, One Piece Slate',
    mwcId: 580,
    locationCapabilityName: 'poolTableOnePieceSlate',
    rooms: [1, 4, 18],
  },
  {
    id: 14002,
    label: 'Pool Table, Two Piece Slate',
    mwcLabel: 'Pool Table, Two Piece Slate',
    mwcId: 581,
    locationCapabilityName: 'poolTableTwoPieceSlate',
    rooms: [1, 4, 18],
  },
  {
    id: 28400,
    label: 'Safe, 0-249 lbs',
    mwcLabel: 'Safe, 0-249 lbs',
    mwcId: 576,
    locationCapabilityName: 'safe0To250Lbs',
    rooms: [4, 11, 18],
  },
  {
    id: 28500,
    label: 'Safe, 250-499 lbs',
    mwcLabel: 'Safe, 250-499 lbs',
    mwcId: 577,
    locationCapabilityName: 'safe250To500Lbs',
    rooms: [4, 11, 18],
  },
  {
    id: 28600,
    label: 'Safe, 500-749 lbs',
    mwcLabel: 'Safe, 500-749 lbs',
    mwcId: 578,
    locationCapabilityName: 'safe500To750Lbs',
    rooms: [4, 11, 18],
  },
  {
    id: 28601,
    label: 'Safe, over 750 lbs',
    mwcLabel: 'Safe, over 750 lbs',
    mwcId: 579,
    locationCapabilityName: 'safeOver750Lbs',
    rooms: [4, 11, 18],
  },
];

export default getInventory;
