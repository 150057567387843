import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { shrinkLabel, shrinkLabelBase } from './formLabel';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: 'full',
  },
});

const base = definePartsStyle({
  field: {
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'var(--chakra-border-color)',
    h: 'auto',
    px: 6,
    pt: 5,
    pb: 1.5,
    transition: 'all 0.2s ease',
    _invalid: {
      borderColor: 'errorColor',
      borderTopRadius: '3xl',
      borderBottomRadius: 0,
      '&.shrink-label, &:focus.shrink-label, &:focus': {
        borderColor: 'errorColor',
      },
    },
    // Not working
    // _valid: {
    //   borderColor: 'green',
    //   '.sucess-icon': {
    //     display: 'inline-block',
    //   },
    // },
    _placeholder: {
      color: 'placeholderColor',
    },
    _hover: {
      bg: 'white',
      borderColor: 'hoverOutlineColor',
      // outlineOffset: '0px',
      // outlineWidth: '1px',
    },
    _focus: {
      bg: 'white',
      outlineColor: 'tmtBlue',
      // borderColor: 'var(--chakra-border-color)',
      outlineWidth: '4px',
      outlineOffset: '4px',
    },
    _dark: {
      bg: 'transparent',
      _placeholder: {
        color: 'darkPlaceholderColor',
      },
      _hover: {
        bg: 'inherit',
        outlineColor: 'darkHoverOutlineColor',
      },
      _focus: {
        bg: 'inherit',
        outlineColor: 'tmtGold',
        // outlineColor: 'darkFocusOutlineColor',
      },
    },
  },
  element: {
    py: 3.5,
    pr: 3.5,
    h: 'auto',
  },
});

const fancy = definePartsStyle({
  field: {
    ...base.field,
    '+ label': {
      ...shrinkLabelBase,
    },
    '&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '&.shrink-label, &:focus': {
      bg: 'white',
      borderColor: 'var(--chakra-border-color)',
      '+ label': {
        ...shrinkLabel,
      },
    },
  },
  element: {
    ...base.element,
  },
});

const fancyValid = definePartsStyle({
  field: {
    ...fancy.field,
    borderColor: 'tmtCareersGreen',
    pr: 12,
    '&.shrink-label, &:focus.shrink-label, &:focus': {
      borderColor: 'tmtCareersGreen',
    },
  },
  element: {
    ...fancy.element,
  },
});

// const fancyError = definePartsStyle({
//   field: {
//     ...fancy.field,
//     borderColor: 'errorColor',
//     borderTopRadius: '3xl',
//     borderBottomRadius: 0,
//     '&.shrink-label, &:focus.shrink-label, &:focus': {
//       borderColor: 'errorColor',
//     },
//   },
// });

const footerField = definePartsStyle({
  field: {
    // ...fancy.field,
    bg: 'black',
    borderWidth: '1px',
    borderColor: 'black',
    pt: 4,
    pb: 1,
    height: 'auto',
    _placeholder: {
      color: 'placeholderColor',
    },
    _hover: {
      bg: 'gray.800',
      outlineColor: 'darkHoverOutlineColor',
      outlineOffset: '0px',
    },
    _focus: {
      bg: 'gray.800',
      borderColor: 'darkHoverOutlineColor',
      boxShadow: 'none',
      outline: '4px solid',
      outlineColor: 'tmtGold',
      outlineOffset: '4px',
    },
  },
});

export default defineMultiStyleConfig({
  defaultProps: {
    variant: 'base',
  },
  baseStyle,
  variants: {
    base,
    footerField,
    fancy,
    fancyValid,
    // fancyError,
  },
});
