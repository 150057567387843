import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import {
  baseStyle as buttonBaseStyle,
  gray as buttonGray,
  secondary as buttonSecondary,
  uflLarge as largeRadioSize,
} from './button';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const link = definePartsStyle({
  control: {
    display: 'none',
  },
  label: {
    textDecoration: 'underline',
    color: 'tmtGold',
  },
});

const button = definePartsStyle({
  control: {
    position: 'absolute',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    w: '100%',
    h: '100%',
    '[&::before]': {
      display: 'none',
    },
    _hover: {
      background: 'none',
    },
    _focus: {
      background: 'none',
      color: 'transparent',
      '[&::before]': {
        display: 'none',
      },
    },
    '&[data-focus-visible]': {
      boxShadow: 'none',
      outline: '4px solid',
      outlineColor: 'tmtBlue',
      outlineOffset: 3,
    },
    '&[data-checked]': {
      background: 'none',
      color: 'transparent',
      '[&::before]': {
        display: 'none',
      },
      _hover: {
        background: 'none',
      },
    },
    _dark: {
      '&[data-focus-visible]': {
        outlineColor: 'tmtGold',
      },
    },
  },
  container: {
    ...buttonBaseStyle,
    background: 'transparent',
    border: '1px solid',
    borderColor: 'tmtBlue',
    color: 'tmtBlue',
    ...largeRadioSize,
    _hover: {
      background: 'blue.100',
    },
    _checked: {
      ...buttonSecondary,
    },
    '.checkmark': {
      display: 'none',
    },
    _dark: {
      borderColor: 'tmtGold',
      color: 'tmtGold',
      _hover: {
        background: 'orange.900',
      },
    },
  },
  label: {
    m: 0,
    display: 'flex',
    gap: 2,
  },
});

const button2 = definePartsStyle({
  control: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 'none',
    background: 'none',
    width: '100%',
    height: '100%',
    '[&::before]': {
      display: 'none',
    },
    _hover: {
      background: 'none',
    },
    '&[data-checked]': {
      background: 'none',
      _before: {
        display: 'none',
      },
      _hover: {
        background: 'none',
      },
    },
    '&[data-focused]': {
      background: 'none',
      outline: '4px solid',
      outlineColor: 'focusOutlineColor',
      outlineOffset: '2px',
      _before: {
        display: 'none',
      },
      _hover: {
        background: 'none',
      },
    },
  },
  container: {
    ...buttonBaseStyle,
    ...buttonGray,
    px: 4,
    py: 2,
    _hover: {
      background: 'gray.500',
    },
    _checked: {
      ...buttonSecondary,
    },
    '.checkmark': {
      display: 'none',
    },
  },
  label: {
    m: 0,
    display: 'flex',
    gap: 2,
  },
});

export const column = definePartsStyle({
  control: {
    position: 'absolute',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 0,
    mt: 0,
    w: '100%',
    h: '100%',
    '[&::before]': {
      display: 'none',
    },
    _hover: {
      background: 'none',
      '+ .chakra-radio__label, + .chakra-checkbox__label': {
        color: 'black',
        borderColor: 'black',
        '.svg-container': {
          svg: {
            transform: 'scale(0.9)',
          },
        },
      },
    },
    _disabled: {
      pointerEvents: 'none',
    },
    _focus: {
      background: 'none',
      color: 'transparent',
      '[&::before]': {
        display: 'none',
      },
    },
    '&[data-focus-visible]': {
      boxShadow: 'none',
      outline: '4px solid',
      outlineColor: 'tmtBlue',
      outlineOffset: 3,
    },
    '&[data-checked]': {
      background: 'none !important',
      color: 'transparent',
      '[&::before]': {
        display: 'none',
      },
      _hover: {
        background: 'none',
      },
    },
    _dark: {
      '&[data-focus-visible]': {
        outlineColor: 'tmtGold',
      },
      _hover: {
        '+ .chakra-radio__label, + .chakra-checkbox__label': {
          color: 'white',
          borderColor: 'white',
        },
      },
    },
  },
  container: {
    minH: 58,
    w: '100%',
    mb: '-1px',
    mx: '-0.5px',
    _hover: {
      zIndex: 1,
    },
    _checked: {
      zIndex: 2,
    },
  },
  label: {
    border: '1px',
    display: 'flex',
    gap: 2,
    mx: 0,
    py: 2,
    pl: 2,
    pr: 4,
    alignItems: 'center',
    w: '100%',
    fontWeight: 'bold',
    color: 'gray.700',
    '.svg-container': {
      height: '40px',
      width: '60px',
      transition: 'all 0.2s ease',
      svg: {
        height: '40px',
        width: '60px',
        transition: 'all 0.2s ease',
        position: 'relative',
        transform: 'scale(1)',
      },
    },
    _checked: {
      color: 'tmtBlue',
      borderColor: 'tmtBlue',
      boxShadow:
        'inset 1px 1px 0, inset -1px -1px 0, 1px 1px 0, -1px -1px 0, 1px -1px 0, -1px 1px 0',
      boxShadowColor: 'tmtBlue',
      '.checkmark': {
        display: 'inline-block',
      },
    },
    _hover: {
      color: 'black',
      borderColor: 'black',
      '.svg-container': {
        svg: {
          transform: 'scale(0.9)',
        },
      },
    },
    '&[data-disabled]': {
      pointerEvents: 'none',
    },
    _dark: {
      color: 'gray.200',
      _hover: {
        color: 'white',
        borderColor: 'white',
      },
      _checked: {
        color: 'tmtGold',
        borderColor: 'tmtGold',
      },
    },
  },
});

export default defineMultiStyleConfig({
  // baseStyle,
  variants: {
    button,
    column,
    link,
  },
});
