import untranslatedBoxes from '@backend/lib/mwc/boxes';
import { type Dictionary } from 'lodash';
import { useTranslation } from 'react-i18next';

export type BoxType = {
  mwcId: string;
  mwc: string;
  label: string;
  singular: string;
  supplyID: string;
  icon: string;
  info: string;
  customerSuppliedOrder?: number;
};

const useBoxTypes = (): BoxType[] => {
  const { t } = useTranslation();
  const boxTypes = Object.entries(untranslatedBoxes(t))
    .filter(
      ([, value]) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof (value as Dictionary<any>).customerSuppliedOrder !== 'undefined',
    )
    .sort(function ([, a], [, b]) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a as Dictionary<any>).customerSuppliedOrder -
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (b as Dictionary<any>).customerSuppliedOrder
      );
    })
    .map(([mwcId, value]) => ({
      ...value,
      mwcId,
    }));
  return boxTypes;
};

export default useBoxTypes;
