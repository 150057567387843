import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'lib/../i18n';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react';

import logger from 'logger';
import { useAppSelector } from 'store/configureStore';
import { useFormDefaults } from 'lib/form';
import { selectLead } from 'slices/leadSlice';
import UlfStepContainer, {
  UlfStepButtonsContainer,
  UlfStepInner,
} from 'components/UlfStepContainer';
import Intro from 'components/Intro';
import RhfTextField from 'components/fields/RhfTextField';
import { contactIcons } from 'components/steps/1-ContactStep';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import { postReauth } from 'services/backend';
import { useMutation } from '@tanstack/react-query';

const useReauth = () => {
  const mutation = useMutation({
    mutationFn: async ({
      email,
      lastName,
    }: {
      email: string;
      lastName: string;
    }) => postReauth({ email, lastName }),
  });

  return mutation;
};

export const ReauthStep = () => {
  const mutation = useReauth();
  const { t } = useTranslation();
  const {
    email,
    name: { last },
    // preferredContactMethod,
  } = useAppSelector(selectLead);

  // console.log('mutation.error', mutation.error);

  // console.log('mutation.data', mutation.data);

  const onSubmit = async () => {
    try {
      const result = await mutation.mutateAsync({
        email: email as string,
        lastName: last,
      });
      logger.info('result', result);
    } catch (error) {
      logger.error('ReauthStep onSubmit not ok', { error });
    }
  };

  const showForm = mutation.submittedAt === 0 || mutation.isError;

  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Intro title={t(`Let's keep moving!`)} />
        {showForm && (
          <Alert status="info" mb={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>
                <Trans>Your session has expired.</Trans>
              </AlertTitle>
              <AlertDescription>
                <Trans>
                  We need to send you a new link to keep working on your quote.
                </Trans>
              </AlertDescription>
            </Box>
          </Alert>
        )}
        {!mutation.isPending && mutation.isError && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertDescription>
              <Trans>There was an error! Please try again.</Trans>
            </AlertDescription>
          </Alert>
        )}
        {!showForm && (
          <Alert status="success" mb={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>
                <Trans>Your link has been sent!</Trans>
              </AlertTitle>
              <AlertDescription>
                <Trans>Open it to start moving on your quote again.</Trans>
              </AlertDescription>
            </Box>
          </Alert>
        )}
      </UlfStepInner>
      {showForm && (
        <UlfStepButtonsContainer>
          <Button
            isLoading={mutation.isPending}
            onClick={onSubmit}
            variant="stepSubmit"
            leftIcon={
              <FontAwesomeIcon
                icon={
                  contactIcons[
                    'email' // We only send emails at the moment
                    // preferredContactMethod === 'text' ? 'text' : 'email'
                  ]
                }
              />
            }
          >
            <Trans>Send me a new link</Trans>
          </Button>
        </UlfStepButtonsContainer>
      )}
    </UlfStepContainer>
  );
};

const schema = z.object({
  lastName: z.string(),
  email: z.string().email(),
});

export type ReauthStepFormValues = z.infer<typeof schema>;

export const ReauthForm = () => {
  const mutation = useReauth();
  const { t } = useTranslation();
  const { handleSubmit, formState, control } = useForm<ReauthStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
  });
  const { isSubmitting, isValid } = formState;

  const onSubmit: SubmitHandler<ReauthStepFormValues> = async ({
    email,
    lastName,
  }) => {
    try {
      const result = await mutation.mutateAsync({
        email,
        lastName,
      });
      logger.info('result', result);
    } catch (error) {
      logger.error('ReauthStep onSubmit not ok', { error });
    }
  };

  const showForm = mutation.submittedAt === 0 || mutation.isError;

  return (
    <UlfStepContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UlfStepInner>
          <Intro
            title={t(`Let's keep moving!`)}
            description={t(
              `Provide the information below to get a new link and keep working on your quote.`,
            )}
          />
          {!mutation.isPending && mutation.isError && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              <AlertDescription>
                <Trans>There was an error! Please try again.</Trans>
              </AlertDescription>
            </Alert>
          )}
          {!showForm && (
            <Alert status="success" mb={4}>
              <AlertIcon />
              <AlertDescription>
                <Trans>
                  We'll look for a quote with those details and send you a link.
                </Trans>
              </AlertDescription>
            </Alert>
          )}
          {showForm && (
            <Flex direction="column" align="center">
              <RhfTextField
                label={t('Last Name')}
                isRequired={true}
                control={control}
                name="lastName"
              />

              <RhfTextField
                label={t('Email')}
                control={control}
                isRequired={true}
                name="email"
                inputProps={{
                  type: 'email',
                }}
              />
            </Flex>
          )}
        </UlfStepInner>
        {showForm && (
          <UlfStepButtonsContainer>
            <Button
              isLoading={isSubmitting}
              isDisabled={!isValid}
              type="submit"
              variant="stepSubmit"
              leftIcon={<FontAwesomeIcon icon={contactIcons.email} />}
            >
              <Trans>Send me a new link</Trans>
            </Button>
          </UlfStepButtonsContainer>
        )}
      </form>
    </UlfStepContainer>
  );
};

const Reauth = () => {
  const lead = useAppSelector(selectLead);
  return typeof lead === 'object' && lead.email ? (
    <ReauthStep />
  ) : (
    <ReauthForm />
  );
};

export default Reauth;
