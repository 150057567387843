import { replace } from 'redux-first-history';

import types from 'constants/actionTypes';
import { getIpLocation } from 'services/maxmind';
import { FIRST_STEP } from 'utils';

export function updateLanguage(languageCode) {
  return {
    type: types.CHANGE_LANGUAGE,
    language: languageCode,
  };
}

export function setWebSocketReadyState(readyState) {
  return {
    type: types.SET_WEBSOCKET_READYSTATE,
    readyState,
  };
}

export function resetAppState() {
  return (dispatch) => {
    dispatch(resetLead());
    dispatch(resetApp());
  };
}

export function fetchGeoIpLocation() {
  return (dispatch) => {
    getIpLocation().then((location) => {
      dispatch({
        type: types.SET_GEO_IP_LOCATION,
        data: location,
      });
    });
  };
}

export function removeFinishedLead() {
  return {
    type: types.REMOVE_FINISHED_LEAD,
  };
}

export function fireGtmEvent(event, data = {}, unique = true) {
  return (dispatch, getState) => {
    if (unique) {
      const gtmEvents = getState().app.gtmEvents || [];
      if (gtmEvents.includes(event)) {
        return;
      }
    }
    window.dataLayer.push({ event, ...data });
    return dispatch({
      type: types.GTM_EVENT,
      event,
      ...data,
    });
  };
}

export function setUserMessage(message) {
  return {
    type: types.SET_USER_MESSAGE,
    message: message,
  };
}

function resetLead() {
  return {
    type: types.RESET_LEAD,
  };
}

function resetApp() {
  return {
    type: types.FULL_RESET,
  };
}

export function returnSuccess(response) {
  return {
    type: types.AUTHENTICATE_RETURNING_SUCCESS,
    response,
  };
}

export function reauthenticate(response) {
  return async (dispatch) => {
    dispatch(returnSuccess(response));
    // Hate having to do this...
    await new Promise((r) => setTimeout(r, 1000));
    dispatch(
      replace(`/${response.result.lead.lastCompletedStep || FIRST_STEP}`),
    );
  };
}
