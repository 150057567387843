import React from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { faPhone, faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
// import { useTranslation } from 'react-i18next';
import { Flex, Box, Link } from '@chakra-ui/react';

import { UFL_DOMAIN_EXT } from 'utils';
// import { getPhoneARIA } from 'lib';
import { useAppSelector } from 'store/configureStore';
import { selectCurrentLocation } from 'slices/app';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';

const HeaderLocation = () => {
  // const { t } = useTranslation();
  const currentLocation = useAppSelector(selectCurrentLocation);

  const canada = UFL_DOMAIN_EXT === 'ca';
  const phoneFallback = canada ? '866.684.6448' : '877.720.0411';
  const phone = get(currentLocation || {}, 'phone.organic', phoneFallback);

  // const phoneARIA =
  //   getPhoneARIA(phone, t) ||
  //   t(`Call {{ phone }}. to find a location`, {
  //     phone: canada ? '8 6 6. 6 8 4. 6 4 4 8' : '8 7 7. 7 2 0. 0 4 1 1',
  //   });

  return (
    <Flex
      className="tmt-location-chosen"
      id="block-chosenlocation"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      w="100%"
      fontSize={{ base: 'sm', md: 'lg' }}
      fontFamily="heading"
      fontWeight="bold"
      textTransform="uppercase"
      gap={5}
    >
      {!isNil(currentLocation) && (
        <Link
          href={currentLocation.links.locationPage}
          target="_blank"
          rel="noopener"
          textDecoration="none"
          position="relative"
          _hover={{
            color: { base: 'tmtGold', md: 'tmtBlue' },
            '.header-underline-link': { width: '100%', left: 0 },
          }}
          _focus={{
            outlineColor: { base: 'tmtGold', md: 'tmtBlue' },
            '.header-underline-link': { width: '100%', left: 0 },
          }}
          _dark={{
            _hover: {
              color: 'tmtGold',
            },
          }}
        >
          <FontAwesomeIcon
            color={{ base: 'tmtGold', md: 'tmtBlue' }}
            icon={faMapMarkerAlt}
            mr={1}
            _dark={{ color: 'tmtGold' }}
            fontSize="0.8em"
          />
          <Box as="span">{`${currentLocation.name}, ${currentLocation.address?.state}`}</Box>
          <Box
            as="span"
            className="header-underline-link"
            aria-hidden="true"
            position="absolute"
            bottom={-1}
            left="50%"
            width={0}
            height="1px"
            bg={{ base: 'tmtGold', md: 'tmtBlue' }}
            transition="all 0.2s ease"
            _dark={{ bg: 'tmtGold' }}
          />
        </Link>
      )}
      <Link
        href={`tel:${phone}`}
        className="phone"
        // key={phone}
        // aria-label={phoneARIA}
        textDecoration="none"
        position="relative"
        justifySelf="flex-end"
        _hover={{
          color: { base: 'tmtGold', md: 'tmtBlue' },
          '.header-underline-link': { width: '100%', left: 0 },
        }}
        _focus={{
          outlineColor: { base: 'tmtGold', md: 'tmtBlue' },
          '.header-underline-link': { width: '100%', left: 0 },
        }}
        _dark={{
          _hover: {
            color: 'tmtGold',
          },
        }}
      >
        <FontAwesomeIcon
          color={{ base: 'tmtGold', md: 'tmtBlue' }}
          icon={faPhone}
          mr={1}
          _dark={{ color: 'tmtGold' }}
          fontSize="0.8em"
        />
        <Box as="span" className="label">
          {phone}
        </Box>
        <Box
          as="span"
          className="header-underline-link"
          aria-hidden="true"
          position="absolute"
          bottom={-1}
          left="50%"
          width={0}
          height="1px"
          bg={{ base: 'tmtGold', md: 'tmtBlue' }}
          transition="all 0.2s ease"
          _dark={{ bg: 'tmtGold' }}
        />
      </Link>
    </Flex>
  );
};

export default HeaderLocation;
