import { useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import { createSelector } from '@reduxjs/toolkit';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import type { Dictionary } from 'lodash';

import untranslatedSteps from '@backend/lib/lead/steps';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import {
  AddressStepForm,
  AddressStepFormValues,
  parseAddressFormValues,
  useFromAddressStep,
} from 'components/steps/3-FromAddressStep';
import StepSummary from 'components/StepSummary';

const STEP_NAME = 'toAddress';

const useSelectToAddressStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) => pick(lead, ['toAddress'])),
  );

export const ToAddressStepSummary = () => {
  const values = useSelectToAddressStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid} data-private>
      {values.toAddress?.fullAddress}
    </StepSummary>
  );
};

const ToAddressStep = () => {
  const { toAddress: address } = useSelectToAddressStepValues();
  const { onSave, onContinue, customerType, searchOptions, form } =
    useFromAddressStep(address, STEP_NAME);

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<AddressStepFormValues> => {
    return async (values: AddressStepFormValues) => {
      return (
        fn &&
        fn({
          toAddress: parseAddressFormValues(values),
        })
      );
    };
  };

  return (
    <FormProvider {...form}>
      <AddressStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
        searchOptions={searchOptions}
        customerType={customerType as string}
        stepName={STEP_NAME}
      />
    </FormProvider>
  );
};

const ToAddressStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <ToAddressStep /> : <ToAddressStepSummary />;
};

export default ToAddressStepContainer;
