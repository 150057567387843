import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
} from 'react-hook-form';
import { Flex, Text } from '@chakra-ui/react';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

import untranslatedSteps from '@backend/lib/lead/steps';
import { useFormDefaults } from 'lib/form';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import StepSummary from 'components/StepSummary';
import UlfFormStep from 'components/UlfFormStep';
import RhfTextField from 'components/fields/RhfTextField';
import RhfStyledRadioField from 'components/fields/RhfStyledRadioField';

const STEP_NAME = 'squareFeet';

const useSelectSquareFeetStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['squareFeet', 'knowSquareFeet', 'knowSquareFeetUnder2000']),
    ),
  );

export const SquareFeetStepSummary = () => {
  const values = useSelectSquareFeetStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {values.knowSquareFeet === true && values.squareFeet}
      {values.knowSquareFeet === false &&
        values.knowSquareFeetUnder2000 &&
        t('Under 2000')}
      {values.knowSquareFeet === false &&
        !values.knowSquareFeetUnder2000 &&
        t('Over 2000')}
    </StepSummary>
  );
};

const schema = z
  .object({
    squareFeet: z.string().optional(),
    knowSquareFeet: z.string(),
    knowSquareFeetUnder2000: z.string().optional(),
  })
  .superRefine(
    (
      { squareFeet, knowSquareFeet, knowSquareFeetUnder2000 },
      refinementContext,
    ) => {
      if (
        knowSquareFeet === '1' &&
        (isNil(squareFeet) || (squareFeet || '').length === 0)
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          params: { i18n: 'required' },
          path: ['squareFeet'],
        });
      }
      if (
        knowSquareFeet !== '1' &&
        (isNil(knowSquareFeetUnder2000) ||
          (knowSquareFeetUnder2000 || '').length === 0)
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Let us know if your space is smaller or larger than 2000 square feet',
          path: ['knowSquareFeetUnder2000'],
        });
      }
    },
  );

export type SquareFeetStepFormValues = z.infer<typeof schema>;

export const SquareFeetStepForm = ({
  onContinue,
  onSave,
}: UseStepMethodsReturn) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { watch, handleSubmit, formState, control } =
    useFormContext<SquareFeetStepFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <RhfStyledRadioField
        label={t('Do you know your square footage?')}
        isRequired={true}
        control={control}
        name="knowSquareFeet"
        options={[
          {
            value: '1',
            label: <Trans>Yes</Trans>,
            icon: faCheck,
          },
          {
            value: '0',
            label: <Trans>No</Trans>,
            icon: faXmark,
          },
        ]}
      />
      <Flex direction="column" align="center" gap={4} mt={8}>
        {watch('knowSquareFeet') === '1' ? (
          <RhfTextField
            label={t('Square Feet')}
            isRequired={true}
            control={control}
            name="squareFeet"
            inputProps={{
              type: 'number',
              min: '1',
            }}
          />
        ) : (
          <>
            <Text textAlign="center">
              <Trans>
                That's ok! Could you tell us if your space is bigger or smaller
                than 2,000 square feet? Take your best guess!
              </Trans>
            </Text>
            <RhfStyledRadioField
              label={t('My square footage is...')}
              isRequired={true}
              control={control}
              name="knowSquareFeetUnder2000"
              options={[
                {
                  value: '1',
                  label: (
                    <>
                      &lt; <Trans>under 2,000 sq ft</Trans>
                    </>
                  ),
                },
                {
                  value: '0',
                  label: (
                    <>
                      <Trans>over 2,000 sq ft</Trans> &gt;
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </Flex>
    </UlfFormStep>
  );
};

const SquareFeetStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { squareFeet, knowSquareFeet, knowSquareFeetUnder2000 } =
    useSelectSquareFeetStepValues();

  const form = useForm<SquareFeetStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      squareFeet: squareFeet ? `${squareFeet}` : undefined,
      knowSquareFeet:
        typeof knowSquareFeet !== 'undefined'
          ? `${Number(knowSquareFeet)}`
          : '1',
      knowSquareFeetUnder2000:
        typeof knowSquareFeetUnder2000 !== 'undefined'
          ? `${Number(knowSquareFeetUnder2000)}`
          : undefined,
    },
  });

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<SquareFeetStepFormValues> => {
    return async ({
      squareFeet,
      knowSquareFeet,
      knowSquareFeetUnder2000,
    }: SquareFeetStepFormValues) =>
      fn &&
      fn({
        squareFeet: squareFeet ? parseInt(squareFeet, 10) : undefined,
        knowSquareFeet: knowSquareFeet === '1',
        knowSquareFeetUnder2000: knowSquareFeetUnder2000
          ? Boolean(parseInt(knowSquareFeetUnder2000, 10))
          : undefined,
      });
  };

  return (
    <FormProvider {...form}>
      <SquareFeetStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
      />
    </FormProvider>
  );
};

const SquareFeetStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <SquareFeetStep /> : <SquareFeetStepSummary />;
};

export default SquareFeetStepContainer;
