// import merge from 'lodash/merge';

export const uflContainerMd = {
  maxW: 'ufl.container.md',
  mx: 'auto',
};

export const lightContrastBox = {
  backgroundColor: 'white',
  // https://github.com/chakra-ui/chakra-ui/issues/2231
  '.chakra-ui-dark &': {
    backgroundColor: 'tmtGray75',
  },
};

export const contrastBox = {
  backgroundColor: 'white',
  // https://github.com/chakra-ui/chakra-ui/issues/2231
  '.chakra-ui-dark &': {
    backgroundColor: 'black',
  },
};

export const requiredText = {
  fontSize: { base: 'xs', md: 'sm' },
  fontStyle: 'oblique',
  textAlign: 'center',
  p: {
    color: 'gray.800',
    '.chakra-ui-dark &': {
      color: 'gray.200',
    },
  },
};

export const errorText = {
  bg: 'red.100',
  py: 2,
  px: 3,
  border: '1px',
  borderColor: 'errorColor',
  fontSize: { base: 'xs', md: 'sm' },
  position: 'relative',
  top: -2,
  p: {
    color: 'black',
  },
  '.chakra-ui-dark &': {
    bg: 'red:900',
    color: 'white',
    p: {
      color: 'white',
    },
  },
};

export const fieldWrapper = {
  maxW: 'ufl.fieldWrapperMaxWidth',
  mx: 'auto',
  mb: 6,
};

export const numberFieldWrapper = {
  maxW: '100px',
  mx: 'auto',
  mb: 6,
};

export const siteLogo = {
  display: 'block',
  ml: 4,
  transition: 'all 0.2s ease',
  width: { base: '150px', sm: '160px', md: '275px', lg: '300px' },
  height: { base: '60px', md: '78px' },
  bgImage: {
    base: 'url("/img/logo-black-stacked.svg")',
    md: 'url("/img/logo-black-one-line.svg")',
  },
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  '.chakra-ui-dark &': {
    bgImage: {
      base: 'url("/img/logo-white-stacked.svg")',
      md: 'url("/img/logo-white-one-line.svg")',
    },
  },
};

export const helperText = {
  color: 'gray.800',
  _dark: {
    color: 'gray.200',
  },
  lineHeight: 'normal',
  fontSize: 'sm',
};

// export const borderBox = {
//   border: '1px solid',
//   borderColor: 'borderColor',
//   // https://github.com/chakra-ui/chakra-ui/issues/2231
//   '.chakra-ui-dark &': {
//     borderColor: 'darkBorderColor',
//   },
// };

// // Merge mutates the first argument! https://lodash.com/docs/4.17.15#merge
// export const contrastBorderBox = merge({ ...contrastBox }, borderBox);
