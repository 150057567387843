// import { fieldObjectForArray, fieldArrayForStep } from 'store/schemaHelpers';
// import { FIRST_STEP } from 'utils';

export const initialAppState = {
  token: null,
  activeLeadID: null,
  apiError: null,
  language: 'en',
  currentLocation: null,
  closeLocations: null,
  activeRoomIndex: 0,
  percentageComplete: 0,
  finishedLead: null,
  gtmEvents: [],
  userMessage: null,
  userErrorMessage: null,
  mapApiLoaded: false,
  themeClass: null,
  geoIpLocation: null,
  cookieConsent: true,
  cookieBannerVisible: true,
  wip: {
    fetchingCloseLocations: false,
    fetchingLocation: false,
    updating: false,
  },
  readyState: 0,
  backendPending: false,
  ...(window.initialAppState || {}),
};

// export const initialLeadState = window.initialLeadState || {
//   // ...fieldObjectForArray(fieldArrayForStep(FIRST_STEP)),
//   visibleSteps: [FIRST_STEP],
//   currentStep: FIRST_STEP,
//   validSteps: [FIRST_STEP],
// };

export const initialDetailState = {
  postalCode: null,
  lead: {},
  estimate: {},
  attempts: 0,
  successfullyLoadedDetail: false,
  loading: false,
  action: {
    type: null,
    loading: false,
    response: null,
    details: null,
    error: null,
  },
  ...(window.initialDetailState || {}),
};
