import concat from 'lodash/concat';
import isArray from 'lodash/isArray';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { CloseLocations } from 'lib/locations';

const Map = ({
  closeLocations,
  center,
}: {
  closeLocations?: CloseLocations;
  center: Pick<Lead['fromAddress'], 'lat' | 'lon'>;
}) => {
  const root = 'https://maps.googleapis.com/maps/api/staticmap?';
  const key = 'AIzaSyCXaDG55KCvwECjzNeV5F0TyqGvlVVKTtQ';

  // Declare an absolute path for custom map marker icons: PNG recommended
  // Google Static Maps API does not support SVG icons
  // Unused icon: 'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/pin-blue.png'
  // const primary = 'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/pin-red.png'
  // const secondary = 'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/pin-orange.png'

  // New pins
  const user =
    'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/2022-pin-user.png';
  const pinA =
    'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/2022-pin-a.png';
  const pinB =
    'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/2022-pin-b.png';
  const pinC =
    'https://s3.us-east-2.amazonaws.com/dev-public.twomenandatruck.com/ufl/2022-pin-c.png';
  const locPins = [pinA, pinB, pinC];

  // Define an array of map markers in this format: icon:URLofIcon|markerLocation
  const userPin = `icon:${user}|${center.lat},${center.lon}`;
  const pins = concat(
    (closeLocations || []).map((closeLocation, i) => {
      return `icon:${locPins[i]}|${closeLocation.location.coordinates?.lat},${closeLocation.location.coordinates?.lon}`;
    }),
    userPin,
  );

  // Define an array of style declarations in this format:
  // style=feature:road|color:0xffffff
  const styles = [
    //administrative
    'feature:administrative|element:geometry.fill|visibility:off',
    'feature:administrative|element:labels.text.fill|color:0x545554',
    //landscape
    'feature:landscape|color:0xdbdbdb',
    //poi
    'feature:poi|visibility:off',
    //road
    'feature:road|saturation:-100|lightness:45|color:0xf3f3f3|visibility:on',
    'feature:road|element:labels.text.fill|color:0x575757',
    ////road - highway
    'feature:road.highway|visibility:simplified',
    'feature:road.highway|element:geometry.fill|color:0xffa800',
    'feature:road.highway|element:labels.icon|visibility:off',
    ////road - arterial
    'feature:road.arterial|element:geometry.fill|color:0xffffff',
    'feature:road.arterial|element:labels.icon|visibility:off',
    //transit
    'feature:transit|visibility:off',
    //water
    'feature:water|color:0x#85b2f2|visibility:on',
  ];

  // Assemble all key/value pairs into one object
  const parameters = {
    center: `${center.lat},${center.lon}`,
    zoom: 12,
    size: '700x500',
    key: key,
    // Since objects can't use repeat keys, use a special delimiter to join these arrays:
    markers: pins.join('&markers='),
    style: styles.join('&style='),
  };

  // If location markers are present, do not supply a value for 'center' or 'zoom'
  // If the map has no markers, a value for 'center' is required by the API
  // If the map does have markers, do not declare 'zoom': the API will auto-adjust zoom levels based on markers
  const keys = isArray(closeLocations)
    ? ['size', 'key', 'markers', 'style']
    : ['center', 'zoom', 'size', 'key', 'style'];
  // Concatenate all key/value pairs in this format: &key=value&key=value
  const keysToString = () => {
    return keys
      .filter(
        (key) =>
          typeof parameters[key as keyof typeof parameters] !== 'undefined',
      )
      .map((key) => key + '=' + parameters[key as keyof typeof parameters])
      .join('&');
  };

  return (
    <div className="map">
      <img
        className="map map-active"
        src={`${root}${keysToString()}`}
        alt="Static map showing the nearest Two Men and a Truck locations to you"
        width="100%"
      />
    </div>
  );
};

export default Map;
