import { useState, ChangeEventHandler } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import { createSelector } from '@reduxjs/toolkit';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import add from 'date-fns/add';
import format from 'date-fns/format';
import isSunday from 'date-fns/isSunday';
import parse from 'date-fns/parse';
import isEqual from 'date-fns/isEqual';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import startOfDay from 'date-fns/startOfDay';
import { Link } from 'wouter';
import { CalendarProps, DateValue, useLocale } from 'react-aria';
import { useCalendarState } from '../../../react-stately-calendar/src/useCalendarState';
import {
  createCalendar,
  getLocalTimeZone,
  parseDate,
} from '@internationalized/date';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import untranslatedSteps from '@backend/lib/lead/steps';
import { formatIsoDate } from 'lib/date';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import { selectCloseLocations, selectCurrentLocation } from 'slices/app';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import FieldSuccessIcon from 'components/primatives/FieldSuccessIcon';
import StepSummary from 'components/StepSummary';
import UlfFormStep from 'components/UlfFormStep';
import DayPicker from 'components/primatives/DayPicker';

const STEP_NAME = 'serviceDate';

const useSelectServiceDateStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['preferredServiceDate']),
    ),
  );

const isMatch = (date: Date, matchers: Date[]) => {
  return typeof matchers.find((match) => isEqual(match, date)) !== 'undefined';
};

const parseDateFieldValue = (value: string) =>
  parse(value, 'yyyy-MM-dd', new Date());

const calculateValid = ({
  value,
  fromDate,
  disabledDays,
}: {
  value: string | undefined;
  fromDate: Date;
  disabledDays: Date[];
}) => {
  if (!value) {
    return false;
  }

  const date = parseDateFieldValue(value);
  return date
    ? !isMatch(date, disabledDays) &&
        !isSunday(date) &&
        !isAfter(fromDate, date)
    : true;
};

const formatDateIntoDateFieldValue = (date: Date) => format(date, 'yyyy-MM-dd');

export const ServiceDateStepSummary = () => {
  const values = useSelectServiceDateStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {values.preferredServiceDate as unknown as string}
    </StepSummary>
  );
};

export const ServiceDateStepForm = ({
  onContinue,
  onSave,
  closedUntilDate,
  isLocationClosed = false,
  openLocationExists = false,
  disabledDays = [],
  fromDate,
  preferredServiceDate,
}: UseStepMethodsReturn & {
  closedUntilDate?: Date | null;
  isLocationClosed?: boolean;
  openLocationExists?: boolean;
  disabledDays?: Date[];
  fromDate: Date;
  preferredServiceDate?: string;
}) => {
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { locale } = useLocale();
  const [value, setValue] = useState<string>(preferredServiceDate || '');
  const [isDirty, setIsDirty] = useState(false);
  const hadDefaultValue = typeof preferredServiceDate === 'string';

  const formattedCloseUntilDate = !isNil(closedUntilDate)
    ? format(closedUntilDate, 'iiii, MMMM d, yyyy')
    : '';

  const isDateUnavailable = (day: DateValue): boolean => {
    const date = day.toDate(getLocalTimeZone());
    return isSunday(date) === true || isMatch(date, disabledDays) === true;
  };

  const handleDayChange = (selectedDay: Date) => {
    setIsDirty(true);

    if (
      calculateValid({
        value: formatDateIntoDateFieldValue(selectedDay),
        fromDate,
        disabledDays,
      })
    ) {
      setValue(formatDateIntoDateFieldValue(selectedDay));
    }
  };

  const onCalendarChange = (value: CalendarProps<DateValue>['value']) => {
    if (value) {
      handleDayChange(value.toDate(getLocalTimeZone()));
    }
  };

  const isValid = calculateValid({ value, fromDate, disabledDays });
  const isInvalid = (hadDefaultValue || isDirty) && !isValid;
  const showSuccess = (hadDefaultValue || isDirty) && isValid;

  const calendarState = useCalendarState({
    isDateUnavailable,
    value: value ? parseDate(formatIsoDate(parseDateFieldValue(value))) : null,
    locale,
    createCalendar,
    minValue: parseDate(formatIsoDate(fromDate)),
    onChange: onCalendarChange,
    isInvalid,
  });

  const setCalendarDate = (date: Date) => {
    calendarState.setValue(parseDate(formatIsoDate(date)));
    calendarState.setFocusedDate(parseDate(formatIsoDate(date)));
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleInputBlur: ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsDirty(true);
    const value = event.target.value;

    if (calculateValid({ value, fromDate, disabledDays })) {
      setCalendarDate(parseDateFieldValue(value));
    }
  };

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={(onValid) => async (e) => {
        if (e) {
          e.preventDefault && e.preventDefault();
          e.persist && e.persist();
        }

        return onValid({ preferredServiceDate: value }, e) as Promise<void>;
      }}
      isSubmitting={false}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      {isLocationClosed && openLocationExists && (
        <p>
          <Trans formattedCloseUntilDate={formattedCloseUntilDate}>
            Please note, the location you selected is not conducting moves until{' '}
            <b>{formattedCloseUntilDate}</b>.
          </Trans>
          &nbsp;
          <Trans>
            If you need to move prior to that date, you may{' '}
            <Link to="locationSelector">select a different location</Link>.
          </Trans>
        </p>
      )}
      {isLocationClosed && !openLocationExists && (
        <p>
          <Trans formattedCloseUntilDate={formattedCloseUntilDate}>
            We apologize, the location you selected is currently not conducting
            moves until <b>{formattedCloseUntilDate}</b>.
          </Trans>
          &nbsp;
          <Trans>
            Please select a later date and we would be happy to accommodate you
            at that time.
          </Trans>
        </p>
      )}
      <FormControl
        id="preferredServiceDate"
        isRequired={true}
        isInvalid={isInvalid}
        className={clsx('shrink-label', showSuccess && 'show-success')}
        layerStyle="fieldWrapper"
      >
        <InputGroup>
          <Input
            variant={showSuccess ? 'fancyValid' : 'fancy'}
            className={clsx('shrink-label', showSuccess && 'show-success')}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={value || ''}
            type="date"
          />
          <FormLabel>
            <Trans>Preferred Service Date</Trans>
          </FormLabel>
          <InputRightElement>
            {showSuccess && <FieldSuccessIcon />}
          </InputRightElement>
        </InputGroup>
        {isInvalid && (
          <FormErrorMessage>
            <Trans>Required.</Trans>
          </FormErrorMessage>
        )}
      </FormControl>
      <DayPicker state={calendarState} />
    </UlfFormStep>
  );
};

const ServiceDateStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { preferredServiceDate } = useSelectServiceDateStepValues();
  const closeLocations = useAppSelector(selectCloseLocations);
  const currentLocation = useAppSelector(selectCurrentLocation);
  const closedDates = useAppSelector((state) => state.app.closedDates);

  const now = new Date();

  const today = startOfDay(now);

  const firstPossibleDate = startOfTomorrow();

  const closedUntilDate = currentLocation?.closedUntilDate
    ? new Date(currentLocation.closedUntilDate)
    : null;

  const isLocationClosed =
    !isNil(closedUntilDate) && isAfter(closedUntilDate, today);

  const openLocationExists =
    isLocationClosed &&
    closeLocations.reduce((result, closeLocation) => {
      return (
        result ||
        isNil(closeLocation.location.closedUntilDate) ||
        isBefore(new Date(closeLocation.location.closedUntilDate), today)
      );
    }, false);

  const startDate = closedUntilDate || firstPossibleDate;

  const fromDate = isBefore(startDate, firstPossibleDate)
    ? firstPossibleDate
    : startDate;

  const disabledDays = Array.isArray(closedDates)
    ? closedDates.map((d) => add(d, { hours: 12 }))
    : [];

  return (
    <ServiceDateStepForm
      onSave={onSave}
      onContinue={onContinue}
      closedUntilDate={closedUntilDate}
      isLocationClosed={isLocationClosed}
      openLocationExists={openLocationExists}
      disabledDays={disabledDays}
      fromDate={fromDate}
      preferredServiceDate={
        preferredServiceDate
          ? (preferredServiceDate as unknown as string)
          : undefined
      }
    />
  );
};

const ServiceDateStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <ServiceDateStep /> : <ServiceDateStepSummary />;
};

export default ServiceDateStepContainer;
