import { PartialDeep } from 'type-fest';
import type { Dictionary } from 'lodash';
import get from 'lodash/get';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { FIRST_STEP } from 'utils';
import { calculateMilage } from 'services/mapbox';
import {
  fromLocationDidChange,
  toLocationDidChange,
} from 'actions/actionUtilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialLeadState: Lead = (window as Dictionary<any>)
  .initialLeadState || {
  // ...fieldObjectForArray(fieldArrayForStep(FIRST_STEP)),
  visibleSteps: [FIRST_STEP],
  currentStep: FIRST_STEP,
  validSteps: [FIRST_STEP],
};

export const calculateMoveDriveDistance = async ({
  incomingLead,
  existingLead,
  updatedLead,
}: {
  incomingLead: PartialDeep<Lead>;
  existingLead: PartialDeep<Lead>;
  updatedLead: PartialDeep<Lead>;
}): Promise<number | undefined> => {
  if (
    (get(incomingLead, 'fromAddress.fullAddress', false) ||
      get(incomingLead, 'toAddress.fullAddress', false)) &&
    get(updatedLead, 'fromAddress.fullAddress', false) &&
    get(updatedLead, 'toAddress.fullAddress', false) &&
    (fromLocationDidChange(updatedLead, existingLead) ||
      toLocationDidChange(updatedLead, existingLead))
  ) {
    return calculateMilage(updatedLead.fromAddress, updatedLead.toAddress);
  }

  return undefined;
};
