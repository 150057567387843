import { isBuildingTypeStorage } from '@backend/lib/lead/helperFunctions';
import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { get } from 'lodash';
import { LocationApiLocation } from 'lib/locations';
import type { PartialDeep } from 'type-fest/source/partial-deep';

export const hasEmptyOrMissingProperty = (lead: PartialDeep<Lead>): boolean => {
  if (!lead.moveDriveDistance) {
    return true;
  }
  if (!lead.customerType) {
    return true;
  }

  if (!(lead.services || []).length) {
    return true;
  }

  if (!get(lead, 'fromAddress.region', null)) {
    return true;
  }

  if (!get(lead, 'toAddress.region', null)) {
    return true;
  }

  if (!lead.selectedLocationDistance) {
    return true;
  }

  return false;
};

export const leadCrossesStateLines = ({
  currentLocation,
  updatedLead,
}: {
  currentLocation: Pick<LocationApiLocation, 'serviceArea' | 'address'> | null;
  updatedLead: PartialDeep<Lead>;
}): boolean => {
  const crossesStateLines =
    get(updatedLead, 'toAddress.region') !==
      get(updatedLead, 'fromAddress.region') ||
    get(currentLocation, 'address.state') !==
      get(updatedLead, 'fromAddress.region');

  return crossesStateLines;
};

export const calculateRoomBasedEstimate = ({
  currentLocation,
  updatedLead,
}: {
  currentLocation: Pick<LocationApiLocation, 'serviceArea' | 'address'> | null;
  updatedLead: PartialDeep<Lead>;
}): boolean => {
  if (hasEmptyOrMissingProperty(updatedLead)) {
    return false;
  }

  const moveDriveDistanceThreshold = parseInt(
    currentLocation && currentLocation.serviceArea?.distance
      ? currentLocation.serviceArea?.distance
      : '30',
    10,
  );

  const allowedDistance = 30.0;

  const isCrossStateLinesAllowed =
    currentLocation &&
    Boolean(get(currentLocation, 'serviceArea.crossState', false)) === true;

  if (
    updatedLead.moveDriveDistance &&
    updatedLead.moveDriveDistance > moveDriveDistanceThreshold
  ) {
    return false;
  }

  if (
    updatedLead.selectedLocationDistance &&
    updatedLead.selectedLocationDistance > allowedDistance
  ) {
    return false;
  }

  if (
    !isCrossStateLinesAllowed &&
    leadCrossesStateLines({
      currentLocation,
      updatedLead,
    })
  ) {
    return false;
  }

  if (updatedLead.customerType !== 'home') {
    return false;
  }

  if (
    (updatedLead.services || []).includes('storage') ||
    (updatedLead.services || []).includes('packing')
  ) {
    return false;
  }

  if (isBuildingTypeStorage(updatedLead, 'fromAddress')) {
    return false;
  }

  if (isBuildingTypeStorage(updatedLead, 'toAddress')) {
    return false;
  }

  if (
    updatedLead.additionalStops &&
    parseInt(`${updatedLead.additionalStops}`, 10) > 0
  ) {
    return false;
  }

  if (
    get(updatedLead, 'knowSquareFeet', true) &&
    updatedLead.squareFeet &&
    parseInt(`${updatedLead.squareFeet}`, 10) > 2000
  ) {
    return false;
  }

  if (
    !get(updatedLead, 'knowSquareFeet', true) &&
    !get(updatedLead, 'knowSquareFeetUnder2000', true)
  ) {
    return false;
  }

  if (get(updatedLead, 'fromAddress.region', null) === 'CA') {
    return false;
  }

  if (get(updatedLead, 'fromAddress.region', null) === 'OR') {
    return false;
  }

  return true;
};
