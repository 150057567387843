import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { Trans, useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { createSelector } from '@reduxjs/toolkit';
import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
} from 'react-hook-form';
import { Box, Circle, Flex, Grid, Text } from '@chakra-ui/react';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import untranslatedSteps from '@backend/lib/lead/steps';
import { useAppSelector } from 'store/configureStore';
import { useFormDefaults } from 'lib/form';
import { CloseLocations, LocationApiLocation } from 'lib/locations';
import { selectCloseLocations, selectCurrentLocation } from 'slices/app';
import {
  selectLead,
  selectLeadSelectedLocationDistance,
} from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import StepSummary from 'components/StepSummary';
import RhfStyledRadioField from 'components/fields/RhfStyledRadioField';
import UlfFormStep from 'components/UlfFormStep';
import Map from 'components/Map';

const STEP_NAME = 'locationSelector';

const formatDistance = (miles: number) => {
  return Math.round(miles * 10) / 10;
};

const useSelectLocationSelectorStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['locationID', 'fromAddress', 'locationOK']),
    ),
  );

export const LocationSelectorStepSummary = () => {
  const values = useSelectLocationSelectorStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  const currentLocation = useAppSelector(selectCurrentLocation);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {currentLocation?.name}
    </StepSummary>
  );
};

const schema = z.object({
  locationID: z.string(),
});

export type LocationSelectorStepFormValues = z.infer<typeof schema>;

export const LocationSelectorStepForm = ({
  onContinue,
  onSave,
  closeLocations,
  center,
  currentLocation,
  isCurrentLocationNotRecommended,
  selectedLocationDistance,
}: UseStepMethodsReturn & {
  closeLocations: CloseLocations;
  currentLocation?: LocationApiLocation;
  isCurrentLocationNotRecommended?: boolean;
  center: Pick<Lead['fromAddress'], 'lat' | 'lon'>;
  selectedLocationDistance: Lead['selectedLocationDistance'];
}) => {
  const { t } = useTranslation();
  // const customerTypes = untranslatedCustomerTypes(t);
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { handleSubmit, formState, control } =
    useFormContext<LocationSelectorStepFormValues>();
  const { isSubmitting, isValid } = formState;

  const rawDescription = stepDefinition.description;
  let description = t(rawDescription, { pluralNumber: closeLocations.length });

  if (closeLocations.length === 0 || closeLocations[0].distance > 50) {
    description = t(
      `Sorry, we didn't find any locations within 50 miles of your address. Here are the next closest three! Please select one to continue.`,
    );
  } else if (closeLocations.length === 1) {
    description = t(
      'We only found one location within 50 miles of your address. Please select this option to continue.',
    );
  }

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={6}
        textAlign="left"
      >
        <RhfStyledRadioField
          label={t('Choose a location')}
          isRequired={true}
          isComplex={true}
          isColumns={false}
          control={control}
          name="locationID"
          options={closeLocations.map(({ location, distance }, index) => ({
            value: location.id || '',
            label: (
              <Flex
                gap={2}
                py={1}
                pl={2}
                align="flex-start"
                justify="space-between"
              >
                <Box>
                  <Box>{location.address?.street}</Box>
                  {location.address?.street2 && (
                    <Box>{location.address?.street2}</Box>
                  )}
                  <Box>
                    {location.address?.city}, {location.address?.state}{' '}
                    {location.address?.postalCode}
                  </Box>
                </Box>
                <Box pl={4} textAlign="right" flexShrink="0">
                  <Circle
                    bg="tmtGold"
                    color="bodyFontColor"
                    fontFamily="heading"
                    fontWeight="normal"
                    fontSize="xl"
                    size="6"
                    aria-hidden="true"
                    ml="auto"
                  >
                    {['A', 'B', 'C'][index]}
                  </Circle>
                  <Box fontStyle="italic" fontWeight="normal" textAlign="right">
                    {formatDistance(distance)} mi
                  </Box>
                </Box>
              </Flex>
            ),
          }))}
          {...(isCurrentLocationNotRecommended &&
            currentLocation && {
              suffix: (
                <Flex
                  align="center"
                  gap={2}
                  justify="center"
                  m={2}
                  w="full"
                  fontFamily="heading"
                  textTransform="uppercase"
                >
                  <Circle
                    fontSize="xs"
                    // bg="white"
                    // color="black"
                    border="1px"
                    size={6}
                  >
                    <Trans>or</Trans>
                  </Circle>
                  <Text>
                    <Trans>Keep my currently selected location:</Trans>
                  </Text>
                </Flex>
              ),
              additionalOptions: [
                {
                  value: currentLocation.id || '',
                  label: (
                    <Flex gap={2} align="center">
                      <Box w={10}>
                        <Text fontSize="xs" fontWeight="normal">
                          Not shown
                        </Text>
                      </Box>
                      <Box>
                        <Box>{currentLocation.address?.street}</Box>
                        {currentLocation.address?.street2 && (
                          <Box>{currentLocation.address?.street2}</Box>
                        )}
                        <Box>
                          {currentLocation.address?.city},{' '}
                          {currentLocation.address?.state}{' '}
                          {currentLocation.address?.postalCode}
                        </Box>
                        {selectedLocationDistance && (
                          <Box fontStyle="italic" fontWeight="normal">
                            {formatDistance(selectedLocationDistance)} mi
                          </Box>
                        )}
                      </Box>
                    </Flex>
                  ),
                },
              ],
            })}
        />
        <Box mt={{ base: 0, md: 10 }}>
          <Map closeLocations={closeLocations} center={center} />
        </Box>
      </Grid>
    </UlfFormStep>
  );
};

const LocationSelectorStep = () => {
  const { t } = useTranslation();
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const { fromAddress, locationID } = useSelectLocationSelectorStepValues();
  const selectedLocationDistance = useAppSelector(
    selectLeadSelectedLocationDistance,
  );
  const currentLocation = useAppSelector(selectCurrentLocation);
  const closeLocations = useAppSelector(selectCloseLocations);

  const isCurrentLocationNotRecommended =
    // Lead has location ID
    !isNil(locationID) &&
    // App has has current location
    !isNil(currentLocation) &&
    // Current location isn't in closest locations.
    isNil(
      closeLocations.find(
        (closeLocation) =>
          parseInt(closeLocation.location.id || '0', 10) ===
          parseInt(`${locationID}`, 10),
      ),
    );

  const form = useForm<LocationSelectorStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues: {
      locationID: `${locationID}`,
    },
  });

  const { setError } = form;

  const onSubmit = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (arg: Dictionary<any>) => void,
  ): SubmitHandler<LocationSelectorStepFormValues> => {
    return async ({ locationID }: LocationSelectorStepFormValues) => {
      try {
        const closeLocation = closeLocations.find(
          (closeLocation) =>
            parseInt(closeLocation.location.id || '0', 10) ===
            parseInt(`${locationID}`, 10),
        ) || {
          location: currentLocation,
          distance: `${selectedLocationDistance}`,
        };

        if (!location) {
          throw new Error('No location found!');
        }

        return (
          fn &&
          fn({
            locationID: parseInt(`${closeLocation.location?.id}`, 10),
            franchiseID: closeLocation.location?.franchise?.id,
            selectedLocationDistance:
              parseInt(`${closeLocation.distance}`, 10) || 100,
            locationOK: true,
          })
        );
      } catch (error) {
        setError('locationID', {
          message: t('There was an error! Please try again.') as string,
        });
      }
    };
  };

  return (
    <FormProvider {...form}>
      <LocationSelectorStepForm
        onSave={onSave ? onSubmit(onSave) : undefined}
        onContinue={onSubmit(onContinue)}
        closeLocations={closeLocations}
        isCurrentLocationNotRecommended={isCurrentLocationNotRecommended}
        currentLocation={currentLocation}
        selectedLocationDistance={selectedLocationDistance}
        center={fromAddress}
      />
    </FormProvider>
  );
};

const LocationSelectorStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? (
    <LocationSelectorStep />
  ) : (
    <LocationSelectorStepSummary />
  );
};

export default LocationSelectorStepContainer;
