import FontAwesomeIcon, { FontAwesomeIconProps } from './FontAwesomeIcon';

export const FontAwesomeSubIcon = (props: FontAwesomeIconProps) => (
  <FontAwesomeIcon
    px={0.5}
    fontSize={9}
    position="absolute"
    bottom={0}
    right={-2}
    borderRadius={'full'}
    className="subIcon"
    {...props}
  />
);

export default FontAwesomeSubIcon;
