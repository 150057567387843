import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import type { Dictionary } from 'lodash';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import rootReducer from 'reducers';
import api, { CALL_API } from 'middleware/api';
import { rehydrate } from 'actions/formActions';
import { routerMiddleware, createReduxHistory } from './reduxFirstHistory';

export const store = configureStore({
  reducer: rootReducer,
  // @TODO: Replace reduxReset.
  // enhancers: [reduxReset()],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Customize the built-in serializability dev check
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: [`${CALL_API}.reauthorizeWith`],
      },
    }).concat(api, routerMiddleware),
});

// Expose store when run in Cypress.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as Dictionary<any>).Cypress) {
  // console.log('configureStore: storing store', store);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as Dictionary<any>).store = store;
}

export const persistor = persistStore(store, {}, () => {
  store.dispatch(rehydrate());
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
