import {
  LeadPhoto,
  LeadPhotoTag,
  LeadRoom,
} from '@backend/src/interfaces/mongoose.gen';
import { itemsBySpace } from 'lib/space';
import { InventoryApiResponse } from 'hooks/useInventoryDefinitions';

export const formatPhotoTagTitle = ({
  inventoryDefinitions,
  tag,
}: {
  inventoryDefinitions?: InventoryApiResponse;
  tag: LeadPhotoTag;
}): string | undefined => {
  return tag.itemType && inventoryDefinitions
    ? inventoryDefinitions.inventoryKeyedById[tag.itemType].label
    : tag.Name;
};

export const leadPhotoTagsBySpaceAndType = ({
  spaceId,
  itemType,
  leadPhotos,
}: {
  spaceId: LeadRoom['_id'];
  itemType: number;
  leadPhotos: LeadPhoto[];
}): (LeadPhotoTag & { photoId: LeadPhoto['_id'] })[] => {
  const photos = itemsBySpace(spaceId, leadPhotos);

  const tags = photos
    .map((photo) => {
      if (typeof photo.tags === 'undefined') {
        return [];
      }

      return (photo.tags || []).map((tag) => ({
        ...tag,
        photoId: photo._id,
      }));
    })
    .flat()
    .filter((tag) => tag && `${tag.itemType}` === `${itemType}`);

  return tags;
};
