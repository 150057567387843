import { PartialDeep } from 'type-fest';
import difference from 'lodash/difference';
import last from 'lodash/last';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import untranslatedSteps, {
  INCOMPLETE_STEP,
  calcValidSteps,
} from '@backend/lib/lead/steps';

const steps = untranslatedSteps();

const calculateFields = ({
  incomingLead,
  stepToSave,
  stepToNagivateTo,
  updatedLead,
}: {
  incomingLead: PartialDeep<Lead>;
  stepToSave: string;
  stepToNagivateTo: string | number;
  updatedLead: PartialDeep<Lead>;
}): PartialDeep<Lead> => {
  const validSteps = calcValidSteps(updatedLead);
  const completeSteps = validSteps.filter((stepName) =>
    steps[stepName].isValid(updatedLead),
  );
  const incompleteSteps = difference(validSteps, completeSteps);
  let currentStep = stepToNagivateTo;

  const lastCompletedStep = completeSteps.length
    ? (last(completeSteps) as string)
    : '';
  const lastCompletedStepIndex = validSteps.indexOf(lastCompletedStep);
  const visibleSteps =
    lastCompletedStepIndex >= 0
      ? // 1 for 0 index, 1 to show the next step after completed one.
        validSteps.slice(0, lastCompletedStepIndex + 2)
      : validSteps;

  // Handle incremental navigation
  if (typeof stepToNagivateTo === 'number') {
    const currentStepIndex = validSteps.indexOf(stepToSave);
    if (stepToNagivateTo < 0) {
      currentStep = visibleSteps[currentStepIndex - 1];
    } else {
      const newCurrentStepIndex = currentStepIndex + 1;
      currentStep =
        newCurrentStepIndex + 1 > visibleSteps.length
          ? 'finish'
          : visibleSteps[newCurrentStepIndex];
    }
  }
  // Check that manual navigation is to a valid step.
  else if (stepToNagivateTo === INCOMPLETE_STEP) {
    // Goes to first incomplete.
    currentStep = incompleteSteps[0];
  }
  // Check that manual navigation is to a valid step.
  else if (!visibleSteps.includes(stepToNagivateTo)) {
    // Goes to last visible.
    currentStep = last(visibleSteps) as string;
  }

  incomingLead = {
    ...incomingLead,
    currentStep: currentStep as string,
    incompleteSteps,
    completeSteps,
    validSteps,
    visibleSteps,
    lastCompletedStep,
  };

  return incomingLead;
};

export default calculateFields;
