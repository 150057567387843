export const photoStatus = {
  Uploading: 'Uploading',
  Error: 'Error',
  Processing: 'Processing',
  Rejected: 'Rejected',
  Empty: 'Empty',
  NeedsReview: 'NeedsReview',
  Reviewed: 'Reviewed',
};

// module.exports = {
//   photoStatus
// };

export default { photoStatus };
