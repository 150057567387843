import _ from 'lodash';
import mbxClient from '@mapbox/mapbox-sdk';
import mbxMatrix from '@mapbox/mapbox-sdk/services/matrix';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

import { meterConversionRatio } from 'constants/config';
import { validZipRegex, MAPBOX_KEY, MAPBOX_COUNTRY_CODE } from 'utils';

export function mapboxLoader() {
  return new Promise((resolve) => {
    resolve(mbxClient({ accessToken: MAPBOX_KEY }));
  });
}

export function fetchMatchingGeocodedAddresses(address, searchOptions) {
  return new Promise((resolve, reject) => {
    if (!address) {
      resolve([]);
      return;
    }
    const geolocation =
      _.isObjectLike(searchOptions.geolocation) &&
      _.isNumber(searchOptions.geolocation.latitude) &&
      _.isNumber(searchOptions.geolocation.longitude)
        ? [
            searchOptions.geolocation.longitude,
            searchOptions.geolocation.latitude,
          ]
        : undefined;
    mapboxLoader()
      .then((mapboxClient) => {
        return mbxGeocoding(mapboxClient);
      })
      .then((geocodingClient) => {
        return geocodingClient
          .forwardGeocode({
            query: address,
            mode: 'mapbox.places',
            types: ['address', 'postcode'],
            countries: [MAPBOX_COUNTRY_CODE],
            proximity: geolocation,
            autocomplete: true,
            limit: 5,
            // language: ['en']
          })
          .send();
      })
      .then((response) => {
        resolve(response.body.features);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}

/**
 * Returns promise with results and coordinates
 * @param {*string} address/
 */
export function fetchGeocodedAddress(address) {
  return new Promise((resolve, reject) => {
    if (!address) {
      resolve([]);
      return;
    }
    mapboxLoader()
      .then((mapboxClient) => {
        return mbxGeocoding(mapboxClient);
      })
      .then((geocodingClient) => {
        return geocodingClient
          .forwardGeocode({
            query: address,
            mode: 'mapbox.places',
            countries: [MAPBOX_COUNTRY_CODE],
            types: ['address', 'postcode', 'region', 'locality'],
            autocomplete: false,
            limit: 1,
            // language: ['en']
          })
          .send();
      })
      .then((response) => {
        return response.body.features[0];
      })
      .then((location) => {
        // eslint-disable-next-line no-console
        console.log('location', location);
        const geocodedAddress = {
          address_components: {
            postalCode: parseAddressComponents(location, 'postcode'),
            street: parseAddressComponents(location, 'address'),
            premise: undefined,
            city: parseAddressComponents(location, 'place'),
            region: parseAddressComponents(location, 'region', 'short_code')
              .substring(3)
              .toUpperCase(),
            country: parseAddressComponents(
              location,
              'country',
              'short_code',
            ).toUpperCase(),
          },
          formatted_address: location.place_name,
          coordinates: {
            lat: _.get(location, 'geometry.coordinates[1]'),
            lng: _.get(location, 'geometry.coordinates[0]'),
          },
        };
        resolve({
          ...geocodedAddress,
          address_components: {
            ...geocodedAddress.address_components,
            valid: addressIsValid(
              geocodedAddress.address_components,
              geocodedAddress.formatted_address,
            ),
          },
        });
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}

export const formatAddress = (location) => {
  return {
    fullAddress: location.place_name,
    lat: _.get(location, 'geometry.coordinates[1]'),
    lon: _.get(location, 'geometry.coordinates[0]'),
    postalCode: parseAddressComponents(location, 'postcode'),
    street: parseAddressComponents(location, 'address'),
    premise: undefined,
    city: parseAddressComponents(location, 'place'),
    region: parseAddressComponents(location, 'region', 'short_code')
      .substring(3)
      .toUpperCase(),
    country: parseAddressComponents(
      location,
      'country',
      'short_code',
    ).toUpperCase(),
  };
};

export function parseAddressComponents(location, type, nameType = 'text') {
  if (location.place_type[0] === type) {
    switch (type) {
      case 'region':
        return location.properties.short_code;
      case 'address':
        return _.trim(_.get(location, 'address', '') + ' ' + location.text);
      default:
        return location.text;
    }
  }
  const i = _.findIndex(location.context, (o) => _.startsWith(o.id, type));
  return location.context[i] ? location.context[i][nameType] : '';
}

function addressIsValid(address_components, label) {
  //   console.log('location.localityType', location.localityType);
  // const resultType = location.resultType;
  // const localityType = location.localityType;
  // const postalCode = location.address.postalCode;
  // console.log('addressIsValid', {resultType, localityType, postalCode, location });
  return (
    !_.isUndefined(address_components?.postalCode) && validZipRegex.test(label)
  );
}

export function calculateLocationDistances(fromAddress, locations) {
  return new Promise((resolve, reject) => {
    mapboxLoader().then((mapboxClient) => {
      const matrixClient = mbxMatrix(mapboxClient);
      const points = [
        {
          coordinates: [
            _.toNumber(fromAddress.lon),
            _.toNumber(fromAddress.lat),
          ],
        },
        ..._.map(locations, (l) => {
          return {
            coordinates: [_.toNumber(l.lng), _.toNumber(l.lat)],
          };
        }),
      ];
      matrixClient
        .getMatrix({
          points: points,
          sources: [0],
          annotations: ['distance'],
        })
        .send()
        .then(
          (response) => {
            if (response.body.code !== 'Ok') {
              reject('No usable result from Mapbox Distance Matrix');
              return;
            }
            const distances = response.body.distances;
            const updatedLocations = _.map(locations, (loc, i) => {
              return {
                ...loc,
                distance: distances[0][i + 1] * meterConversionRatio,
              };
            });
            resolve(updatedLocations);
          },
          (error) => {
            reject(
              `Mapbox Distance Error Calculating Distances: ${error.message}`,
            );
            return;
          },
        );
    });
  });
}

export function calculateMilage(sourceAddress, destinationAddress) {
  return new Promise((resolve, reject) => {
    mapboxLoader().then((mapboxClient) => {
      if (!sourceAddress.lat || !sourceAddress.lon) {
        reject('Missing Coordinates on From Address');
      }
      if (!destinationAddress.lat || !destinationAddress.lon) {
        reject('Missing Coordinates on Destination Location');
      }

      const matrixClient = mbxMatrix(mapboxClient);
      const points = [
        {
          coordinates: [
            parseFloat(sourceAddress.lon),
            parseFloat(sourceAddress.lat),
          ],
        },
        {
          coordinates: [
            parseFloat(destinationAddress.lon),
            parseFloat(destinationAddress.lat),
          ],
        },
      ];

      matrixClient
        .getMatrix({
          points: points,
          sources: [0],
          destinations: [1],
          annotations: ['distance'],
        })
        .send()
        .then(
          (response) => {
            if (response.body.code !== 'Ok') {
              reject(`Mapbox Distance Error Code: ${response.body.code}`);
              return;
            }
            if (!_.has(response, 'body.distances[0][0]')) {
              reject('No usable result from Mapbox Distance Matrix');
              return;
            }
            resolve(
              _.get(response, 'body.distances[0][0]') * meterConversionRatio,
            );
          },
          (error) => {
            reject(`Mapbox Distance Error Calculaing Milage: ${error.message}`);
            return;
          },
        );
    });
  });
}
