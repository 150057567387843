import omit from 'lodash/omit';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { photoStatus as backendPhotoStatus } from '@backend/src/lib/lead/constants';
import { RootState } from 'store/configureStore';

export const photoStatus = backendPhotoStatus as Record<
  PhotoStatus,
  PhotoStatus
>;

export type PhotoStatus = keyof typeof backendPhotoStatus;

export type Upload = {
  _id: string;
  fileName: string;
  spaceId: string;
  status: PhotoStatus;
};

export type UploadsState = Record<string, Upload>;

const initialState: UploadsState = {};

export const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    startUpload: (state, action: PayloadAction<Omit<Upload, 'status'>>) => {
      return {
        ...state,
        [action.payload._id]: {
          ...action.payload,
          status: photoStatus.Uploading,
        },
      };
    },
    updateUpload: (
      state,
      action: PayloadAction<Pick<Upload, '_id'> & Partial<Upload>>,
    ) => {
      const upload = state[action.payload._id];
      return {
        ...state,
        [action.payload._id]: { ...upload, ...action.payload },
      };
    },
    removeUpload: (state, action: PayloadAction<Upload['_id']>) => {
      return omit(state, [action.payload]);
    },
  },
});

// Action creators are generated for each case reducer function
export const { startUpload, updateUpload, removeUpload } = uploadsSlice.actions;

export default uploadsSlice.reducer;

export const selectUploads = (state: RootState): UploadsState => state.uploads;
