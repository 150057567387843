import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/configureStore';

export const SPACE_ADD = 'add';

export const SPACE_TAB_ITEMS = 'items';
export const SPACE_TAB_BOXES = 'boxes';

export type InventoryTabType = typeof SPACE_TAB_ITEMS | typeof SPACE_TAB_BOXES;

export type InventorySliceState = {
  spaceId?: string;
  tab?: InventoryTabType;
};

const initialState: InventorySliceState = {
  spaceId: undefined,
  tab: undefined,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<InventorySliceState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setInventorySpaceId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        spaceId: action.payload,
      };
    },
    clearInventorySpaceId: (state) => {
      return {
        ...state,
        spaceId: undefined,
      };
    },
    setInventoryTab: (state, action: PayloadAction<InventoryTabType>) => {
      return {
        ...state,
        tab: action.payload,
      };
    },
    clearInventoryTab: (state) => {
      return {
        ...state,
        spaceId: undefined,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInventory,
  setInventorySpaceId,
  clearInventorySpaceId,
  setInventoryTab,
  clearInventoryTab,
} = inventorySlice.actions;

export default inventorySlice.reducer;

export const selectInventorySpaceId = (
  state: RootState,
): InventorySliceState['spaceId'] => state.inventory?.spaceId;

export const selectInventoryTab = (
  state: RootState,
): InventorySliceState['tab'] => state.inventory?.tab;
