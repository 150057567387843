import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const enclosed = definePartsStyle({
  tablist: {
    px: 4,
    gap: 1,
  },
  tab: {
    borderColor: 'var(--chakra-border-color)',
    bgColor: 'white',
    _dark: {
      bgColor: 'black',
    },
    _hover: {
      textDecoration: 'underline',
      // bgColor: 'gray.50',
      // _dark: {
      //   bgColor: 'gray.900',
      // },
    },
    _selected: {
      borderBottom: 'none',
      bgColor: 'bodyBgColor',
      _dark: {
        bgColor: 'darkBodyBgColor',
      },
      // _hover: {
      //   bgColor: 'gray.100',
      //   _dark: {
      //     bgColor: 'gray.700',
      //   },
      // },
    },
  },
  tabpanel: {
    borderTop: '1px solid',
    borderColor: 'var(--chakra-border-color)',
    bgColor: 'bodyBgColor',
    _dark: {
      bgColor: 'darkBodyBgColor',
    },
  },
});
export default defineMultiStyleConfig({
  variants: {
    enclosed,
  },
  defaultProps: {
    colorScheme: 'black',
  },
});
