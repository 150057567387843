import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Flex, Text, BoxProps } from '@chakra-ui/react';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faPencil as faPencilLight } from '@fortawesome/pro-light-svg-icons';

import untranslatedSteps from '@backend/lib/lead/steps';
import { useAppDispatch } from 'store/configureStore';
import { saveStep } from 'actions/leadActions';
import { UlfCard } from 'components/UlfStepContainer';
import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';

export type StepSummaryProps = BoxProps & { stepKey: string; isValid: boolean };

const StepSummary = memo(
  ({ children, stepKey, isValid, ...rest }: StepSummaryProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const steps = untranslatedSteps(t);

    const stepDetails = steps[stepKey as keyof typeof untranslatedSteps] as {
      summaryName?: string;
      name: string;
    };

    const onGotoStep = () => {
      dispatch(
        saveStep({
          stepToSave: stepKey,
          stepToNagivateTo: stepKey,
        }),
      );
    };

    return (
      <UlfCard textAlign="inherit" padding={0} pb={0} px={0}>
        <Flex justify="space-between">
          <Box py={2} px={4} flex="1" flexShrink="1">
            <Text
              fontSize={{ base: 'xs', sm: 'sm' }}
              textTransform="capitalize"
            >
              {stepDetails.summaryName || stepDetails.name}
            </Text>
            <Box
              fontSize={{ base: 'sm', sm: 'base', md: 'lg' }}
              fontWeight="bold"
              {...rest}
            >
              {children}
            </Box>
          </Box>
          <FontAwesomeIcon
            py={4}
            pr={4}
            color={!isValid ? 'warningColor' : 'validColor'}
            fontSize="lg"
            icon={!isValid ? faExclamationCircle : faCheckCircle}
            alignSelf="center"
          />
          <Box borderLeft="1px">
            <Button variant="action" onClick={onGotoStep}>
              <FontAwesomeIcon fontSize="lg" icon={faPencilLight} />
              <Trans>edit</Trans>
            </Button>
          </Box>
        </Flex>
      </UlfCard>
    );
  },
);

export default StepSummary;
