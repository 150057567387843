/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type BuildingTypes = {
  home: Record<
    number,
    {
      mwc: string;
      label: string;
      icon: string;
    }
  >;
  business: Record<
    number,
    {
      mwc: string;
      label: string;
      icon: string;
    }
  >;
};

const buildingTypes = (t_fn?: any): BuildingTypes => {
  const t = t_fn ? t_fn : (x: any) => x;

  const home = {
    1: {
      mwc: 'House',
      label: t('House'),
      icon: '/img/icon-building-house.svg',
    },
    2: {
      mwc: 'Condo',
      label: t('Condo'),
      icon: '/img/icon-building-condo.svg',
    },
    3: {
      mwc: 'Apartment',
      label: t('Apartment'),
      icon: '/img/icon-building-apartment.svg',
    },
    17: {
      mwc: 'Storage',
      label: t('Storage'),
      icon: '/img/icon-building-storage.svg',
    },
  };

  const business = {
    6: {
      mwc: 'Office',
      label: t('Office'),
      icon: '/img/icon-building-office.svg',
    },
    13: {
      mwc: 'Warehouse',
      label: t('Warehouse'),
      icon: '/img/icon-building-warehouse.svg',
    },
    16: {
      mwc: 'Retail',
      label: t('Retail'),
      icon: '/img/icon-building-retail.svg',
    },
    17: {
      mwc: 'Storage',
      label: t('Storage'),
      icon: '/img/icon-building-storage.svg',
    },
  };

  return {
    home: home,
    business: business,
  };
};

export default buildingTypes;

// module.exports = buildingTypes;
