/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type Service = {
  mwcID?: number;
  mwc: string;
  enabled?: boolean;
  label: string;
  icon: string;
  // This property is used for incoming CSR leads, to map back to the service in question.
  estimate?: string;
  incompatible?: string[];
  callToAction?: boolean;
  conversationalReply?: string;
  customerContent?: string;
  ctaURL?: string;
  rooms?: boolean;
  inventory?: boolean;
  destination?: boolean;
  availableFranchises?: string[];
  // storageInventory?: boolean;
  finalStep?: string;
  skipSqFt?: boolean;
  skipConcerns?: boolean;
};

export type Services = Record<string, Service>;

const services = (t_fn?: any): Services => {
  const t = t_fn ? t_fn : (x: any) => x;

  return {
    move: {
      mwcID: 1,
      mwc: 'move',
      label: t('Help Me Move'),
      icon: '/img/icon-local.svg',
      // This property is used for incoming CSR leads, to map back to the service in question.
      estimate: 'isMove',
      incompatible: [
        // 'load',
        'internal',
        // 'unload'
      ],
      rooms: true,
      inventory: true,
      destination: true,
    } /*
    'load': {
      mwcID: 2,
      mwc: 'load',
      label: t('Load A Truck'),
      icon: '/img/icon-service-loading.svg',
      estimate: 'isPack',
      incompatible: ['move', 'internal', 'unload'],
      rooms: true,
      inventory: true,
    },*/,
    internal: {
      mwcID: 3,
      mwc: 'moveInternal',
      label: t("I'm Rearranging My Space"),
      icon: '/img/icon-service-internal2.svg',
      estimate: 'isMoveInternal',
      incompatible: [
        'move',
        // 'load',
        // 'unload'
      ],
      rooms: true,
      inventory: true,
    },
    packing: {
      mwcID: 4,
      mwc: 'packing',
      label: t('Help Me Pack'),
      icon: '/img/icon-packing.svg',
      estimate: 'isPack',
      rooms: true,
      inventory: true,
    } /*
    'unload': {
      mwcID: 5,
      mwc: 'unload',
      label: t('Unload a Truck'),
      icon: '/img/icon-service-unloading.svg',
      estimate: 'isPack',
      incompatible: ['move', 'internal', 'load']
    },*/,
    junk: {
      mwcID: 7,
      mwc: 'junk',
      label: t('Junk Removal'),
      icon: '/img/icon-service-junk-removal.svg',
      estimate: 'isPack',
      skipConcerns: true,
      skipSqFt: true,
      availableFranchises: [
        '0001',
        '0511',
        '0267',
        '0191',
        '0030',
        '0029',
        '0273',
        '0336',
        '0580',
        '0036',
        '0055',
        '0106',
        '0135',
        '0186',
        '0196',
        '0711',
        '0218',
        '0229',
        '0248',
        '0269',
        '0278',
        '0302',
        '0308',
        '0318',
        '0327',
        '0670',
        '0334',
        '0344',
        '0403',
        '0451',
        '0482',
        '0557',
        '0556',
        '0712',
        '0586',
        '0588',
        '3020',
        '3021',
        '0026',
        '0052',
        '0079',
        '0080',
        '0084',
        '0094',
        '0102',
        '0131',
        '0155',
        '0179',
        '0234',
        '0235',
        '0253',
        '0291',
        '0297',
        '0298',
        '0348',
        '0378',
        '0380',
        '0394',
        '0396',
        '0397',
        '0398',
        '0430',
        '0454',
        '0463',
        '0464',
        '0480',
        '0498',
        '0534',
        '0538',
        '0561',
        '0562',
        '0563',
        '0564',
        '0565',
        '0566',
        '0582',
        '0585',
        '0492',
        '0561',
        '0562',
        '0563',
        '0564',
        '0565',
        '0566',
        '3012',
        '3020',
        '3021',
        '3025',
        '3026',
        '3033',
        '3034',
        '3035',
        '3036',
        '0514',
        '0515',
        '0522',
        '0523',
        '0065',
        '0695',
        '0166',
        '0231',
        '0264',
        '0315',
        '0328',
        '0341',
        '0349',
        '0361',
        '0373',
        '0424',
        '0425',
        '0426',
        '0696',
        '0572',
        '0577',
        '0600',
        '0601',
        '0602',
        '0613',
        '0620',
        '3037',
        '0329',
        '0545',
        '0680',
        '0379',
      ],
    },
    supplies: {
      // No mwcID
      mwc: 'supplies',
      enabled: false,
      label: t('I Need Supplies'),
      icon: '/img/icon-boxes.svg',
      // Necessary?
      rooms: true,
      // Necessary?
      inventory: true,
      // The callToAction property signifies a service type for which TMT does NOT provide
      // quotes. A user with only CTA services will not be allowed to proceed with the quote steps.
      callToAction: true,
      // The ctaURL is only shown on the CallToActionStep, for users who only select CTA services.
      ctaURL: 'https://twomenandatruck.com/moving-supplies',
      conversationalReply: t("We've got the tools you need!"),
      customerContent: t(
        'Did you know we offer a wide range of supplies? From boxes to packing tape,' +
          ' no detail is too small. Learn more about how we can provide the tools you need.',
      ),
    },
    storage: {
      // No mwcID
      mwc: 'storage',
      label: t('I Need Storage Services'),
      icon: '/img/icon-storage.svg',
      ctaURL: 'https://twomenandatruck.com/',
      callToAction: true,
      // Necessary?
      rooms: true,
      // Necessary?
      inventory: true,
      conversationalReply: t('Storage made easy'),
      customerContent: t(
        "We offer a wide range of storage options. Whether it's long term or temporary, " +
          'you can rest assured your belongings are safe with us. Learn more about our storage fa' +
          'cilites and see how we can take the worry out of storage.',
      ),
      availableFranchises: [
        '0090',
        '0695',
        '0183',
        '0227',
        '0248',
        '0314',
        '0327',
        '0329',
        '0359',
        '0373',
        '0423',
        '0451',
        '0469',
        '0477',
        '0537',
        '0587',
        '0600',
        '0131',
        '0249',
        '0429',
        '0001',
        '0015',
        '0021',
        '0024',
        '0030',
        '0032',
        '0036',
        '0069',
        '0079',
        '0081',
        '0084',
        '0090',
        '0094',
        '0124',
        '0131',
        '0135',
        '0138',
        '0147',
        '0171',
        '0176',
        '0680',
        '0186',
        '0191',
        '0196',
        '0711',
        '0216',
        '0219',
        '0226',
        '0229',
        '0234',
        '0243',
        '0245',
        '0246',
        '0248',
        '0255',
        '0260',
        '0267',
        '0278',
        '0282',
        '0286',
        '0289',
        '0339',
        '0290',
        '0314',
        '0328',
        '0329',
        '0331',
        '0334',
        '0336',
        '0337',
        '0344',
        '0349',
        '0353',
        '0361',
        '0363',
        '0394',
        '0403',
        '0407',
        '0415',
        '0417',
        '0418',
        '0423',
        '0429',
        '0430',
        '0438',
        '0451',
        '0396',
        '0464',
        '0469',
        '0470',
        '0472',
        '0477',
        '0479',
        '0480',
        '0482',
        '0492',
        '0496',
        '0498',
        '0501',
        '0504',
        '0505',
        '0506',
        '0507',
        '0508',
        '0512',
        '0674',
        '0696',
        '0537',
        '0539',
        '0544',
        '0553',
        '0554',
        '0556',
        '0560',
        '0567',
        '0570',
        '0588',
        '0609',
        '0611',
        '0618',
        '0713',
        '0620',
        '3001',
        '3002',
        '3003',
        '3005',
        '3005',
        '3034',
        '3033',
        '3008',
        '3010',
        '3010',
        '3011',
        '3014',
        '3039',
        '3016',
        '3019',
        '3020',
        '3021',
        '3028',
        '3035',
        '3035',
        '0454',
        '0411',
        '0029',
      ],
    },
  };
};

export default services;

// module.exports = services;
