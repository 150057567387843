import { Box, BoxProps, VisuallyHidden } from '@chakra-ui/react';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import FontAwesomeIcon from 'components/primatives/FontAwesomeIcon';
import { useTranslation } from 'react-i18next';

const FieldSuccessIcon = ({ children, ...props }: BoxProps) => {
  const { t } = useTranslation();
  return (
    <Box className="success-icon" {...props}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        color="tmtCareersGreen"
        position="absolute"
        right={6}
        top={3.5}
      />
      <VisuallyHidden>{children ? children : t('Valid')}</VisuallyHidden>
    </Box>
  );
};

export default FieldSuccessIcon;
