import React from 'react';
import { Trans } from 'react-i18next';

import Hamburger from './Hamburger';
import HeaderLocation from './HeaderLocation';
import { UFL_DOMAIN_EXT, UFL_ENVIRONMENT, isProd } from 'utils';
import { Flex, Box, Link, VisuallyHidden } from '@chakra-ui/react';

const Header = ({ isTranslationReady = false }) => {
  // const { t } = useTranslation();
  const homeURL = isProd
    ? `https://twomenandatruck.${UFL_DOMAIN_EXT}`
    : `https://brand-d8-${UFL_ENVIRONMENT}.twomenbeta.${UFL_DOMAIN_EXT}`;

  return (
    <Box
      as="header"
      className="App-header site-header-wrapper"
      id="header-wrapper"
      position="relative"
    >
      <Flex
        className="region region-header"
        px={4}
        py={1}
        alignItems="center"
        display={{ base: 'flex', md: 'none' }}
        bg="gray.800"
        _dark={{ bg: 'black' }}
        color="white"
      >
        {isTranslationReady && <HeaderLocation />}
      </Flex>
      <Flex
        id="header"
        className="site-header"
        layerStyle="lightContrastBox"
        borderBottom="1px"
        justify="space-between"
        alignItems="center"
        wrap="wrap"
      >
        <Link
          name="Home"
          id="logo"
          href={homeURL}
          target="_blank"
          rel="noopener"
          // aria-label={t('Two Men and a Truck, Movers Who Care')}
          layerStyle="siteLogo"
        >
          <VisuallyHidden>
            {isTranslationReady && <Trans>TWO MEN AND A TRUCK Home</Trans>}
          </VisuallyHidden>
        </Link>
        <Flex
          className="region region-header"
          grow="1"
          textAlign="right"
          px={5}
          justifyContent="flex-end"
          alignItems="center"
          mr="79px"
          display={{ base: 'none', md: 'flex' }}
        >
          {isTranslationReady && <HeaderLocation />}
        </Flex>
      </Flex>
      {isTranslationReady && <Hamburger />}
    </Box>
  );
};

export default Header;
