import { Box, BoxProps } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon as FAIcon,
  FontAwesomeIconProps as FAIconProps,
} from '@fortawesome/react-fontawesome';

export type FontAwesomeIconProps = BoxProps & {
  icon: IconDefinition;
  iconProps?: Omit<FAIconProps, 'icon'>;
  width?: string | number;
  height?: string | number;
};

export const FontAwesomeIcon = ({
  icon,
  width,
  height,
  iconProps,
  ...props
}: FontAwesomeIconProps) => (
  <Box as="span" fontSize="inherit" {...props}>
    <FAIcon
      icon={icon}
      {...(width && { width })}
      {...(height && { height })}
      {...iconProps}
    />
  </Box>
);

export default FontAwesomeIcon;
