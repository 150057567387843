import React from 'react';
import { createRoot } from 'react-dom/client';
import 'focus-visible/dist/focus-visible.js';

import Root from 'components/Root.jsx';

const root = createRoot(document.getElementById('root'));

// When the document has been completely loaded, re-render w/out loader content
window.addEventListener('load', function (event) {
  if (event.target.readyState === 'complete') {
    root.render(<Root />);
  }
});
