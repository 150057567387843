import { Flex, Fade } from '@chakra-ui/react';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { VITE_MODE } from 'utils';
import { useAppSelector } from 'store/configureStore';

const Loading = () => (
  <FontAwesomeIcon color="white" fontSize="20px" icon={faCircleNotch} spin />
);

const FooterIndicator = () => {
  // const readyState = useAppSelector((state) => state.app.readyState);
  const backendPending = useAppSelector((state) => state.app.backendPending);

  return (
    <Flex
      position="fixed"
      bottom={4}
      right={4}
      align="end"
      zIndex="10000"
      direction="column"
      gap={4}
    >
      <Fade
        in={backendPending}
        transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
      >
        <Loading />
      </Fade>
      {/* {VITE_MODE === 'development' && (
        <Alert status="info" mb={4}>
          <Box>Websocket State: {readyState}</Box>
        </Alert>
      )} */}
    </Flex>
  );
};

export default FooterIndicator;
