import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'lib/../i18n';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import { createSelector } from '@reduxjs/toolkit';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import {
  faBuilding,
  faHouseChimney,
  IconDefinition,
} from '@fortawesome/pro-duotone-svg-icons';
import { Box } from '@chakra-ui/react';

import untranslatedSteps from '@backend/lib/lead/steps';
import untranslatedCustomerTypes from '@backend/lib/mwc/customer-type';
import { useFormDefaults } from 'lib/form';
import { useAppSelector } from 'store/configureStore';
import { selectLead } from 'slices/leadSlice';
import useStepMethods, { UseStepMethodsReturn } from 'hooks/useStepMethods';
import StepSummary from 'components/StepSummary';
import RhfTextField from 'components/fields/RhfTextField';
import RhfStyledRadioField from 'components/fields/RhfStyledRadioField';
import UlfFormStep from 'components/UlfFormStep';

const icons = {
  home: faHouseChimney,
  business: faBuilding,
} as Record<string, IconDefinition>;

const STEP_NAME = 'customerType';

const useSelectCustomerTypeStepValues = () =>
  useAppSelector(
    createSelector([selectLead], (lead) =>
      pick(lead, ['customerType', 'businessName', 'businessTitle']),
    ),
  );

export const CustomerTypeStepSummary = () => {
  const values = useSelectCustomerTypeStepValues();
  const { t } = useTranslation();
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const isValid = stepDefinition.isValid(values);
  return (
    <StepSummary stepKey={STEP_NAME} isValid={isValid}>
      {capitalize(values.customerType || '')}
    </StepSummary>
  );
};

const schema = z
  .object({
    customerType: z.enum(['home', 'business']),
    businessName: z.string().optional(),
    businessTitle: z.string().optional(),
  })
  .superRefine(
    ({ customerType, businessName, businessTitle }, refinementContext) => {
      if (
        customerType === 'business' &&
        (isNil(businessName) || (businessName || '').length === 0)
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          params: { i18n: 'required' },
          path: ['businessName'],
        });
      }
      if (
        customerType === 'business' &&
        (isNil(businessTitle) || (businessTitle || '').length === 0)
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          params: { i18n: 'required' },
          path: ['businessTitle'],
        });
      }
    },
  );

export type CustomerTypeStepFormValues = z.infer<typeof schema>;

export const CustomerTypeStepForm = ({
  onContinue,
  onSave,
}: UseStepMethodsReturn) => {
  const { t } = useTranslation();
  const customerTypes = untranslatedCustomerTypes(t);
  const stepDefinition = untranslatedSteps(t)[STEP_NAME];
  const { watch, handleSubmit, formState, control } =
    useFormContext<CustomerTypeStepFormValues>();
  const { isSubmitting, isValid } = formState;

  return (
    <UlfFormStep
      title={stepDefinition.title}
      description={stepDefinition.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isValid={isValid}
      onContinue={onContinue}
      onSave={onSave}
    >
      <RhfStyledRadioField
        label={t('These services are for:')}
        isRequired={true}
        control={control}
        name="customerType"
        options={Object.entries(customerTypes).map(([value, option]) => ({
          value,
          label: option.label,
          icon: icons[value],
        }))}
      />
      {watch('customerType') === 'business' && (
        <Box mt={8}>
          <RhfTextField
            label={t('Business Name')}
            control={control}
            name="businessName"
            isRequired
          />
          <RhfTextField
            label={t('Job Title')}
            control={control}
            name="businessTitle"
            isRequired
          />
        </Box>
      )}
    </UlfFormStep>
  );
};

const CustomerTypeStep = () => {
  const { onSave, onContinue } = useStepMethods(STEP_NAME);
  const defaultValues = useSelectCustomerTypeStepValues();

  const form = useForm<CustomerTypeStepFormValues>({
    ...useFormDefaults,
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <FormProvider {...form}>
      <CustomerTypeStepForm onSave={onSave} onContinue={onContinue} />
    </FormProvider>
  );
};

const CustomerTypeStepContainer = ({
  isCurrentStep,
}: {
  isCurrentStep: boolean;
}) => {
  return isCurrentStep ? <CustomerTypeStep /> : <CustomerTypeStepSummary />;
};

export default CustomerTypeStepContainer;
