import { forwardRef, Box, BoxProps } from '@chakra-ui/react';

const UlfLayoutContainer = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => (
    <Box ref={ref} w="100%" maxW="1200px" mx="auto" {...props}>
      {children}
    </Box>
  ),
);

export default UlfLayoutContainer;
