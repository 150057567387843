import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const baseStyle = defineStyle({
  fontSize: 'md',
  fontFamily: 'heading',
  transition: 'all 0.2s ease',
  color: 'gray.800',
  zIndex: 1,
  _dark: {
    color: 'gray.100',
  },
});

export const base = defineStyle({
  position: 'relative',
});

export const shrinkLabelBase = defineStyle({
  position: 'absolute',
  top: 3.5,
  zIndex: 1,
  left: 6,
});

export const shrinkLabel = defineStyle({
  ...shrinkLabelBase,
  top: 1.5,
  fontSize: 'sm',
});

export default defineStyleConfig({
  defaultProps: {
    variant: 'base',
  },
  baseStyle,
  variants: {
    base,
    shrink: shrinkLabel,
    // shrinkLabelBase,
  },
});
