import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  dialog: {
    bg: 'bodyBgColor',
    _dark: {
      bg: 'darkBodyBgColor',
    },
  },
  header: {},
  body: {
    paddingBottom: 4,
  },
  footer: {},
  closeButton: {},
});

export default defineMultiStyleConfig({
  baseStyle,
});
