import { Trans, useTranslation } from 'react-i18next';

import { Lead } from '@backend/src/interfaces/mongoose.gen';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { selectLeadRoomsStart } from 'slices/leadSlice';
import UlfStepContainer, { UlfStepInner } from 'components/UlfStepContainer';
import Intro from 'components/Intro';
import { Box, Divider, Flex, Link, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { fireGtmEvent } from 'actions/appActions';

export const FinishForm = ({
  roomsStart,
}: {
  roomsStart: Lead['roomsStart'];
}) => {
  const { t } = useTranslation();

  const lightGif = roomsStart
    ? '/img/tmt-finish-offline.gif'
    : '/img/tmt-finish-offline.gif';

  const lightPng = roomsStart
    ? '/img/tmt-finish-offline.png'
    : '/img/tmt-finish-offline.png';

  const darkGif = roomsStart
    ? '/img/tmt-finish-offline-dark.gif'
    : '/img/tmt-finish-offline-dark.gif';

  const darkPng = roomsStart
    ? '/img/tmt-finish-offline-dark.png'
    : '/img/tmt-finish-offline-dark.png';

  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Intro
          title={
            roomsStart ? (
              <Trans>We'll get moving on your quote!</Trans>
            ) : (
              <Trans>Talk to you soon!</Trans>
            )
          }
          rawDescription={
            roomsStart ? (
              <Trans>
                We've got all the information we need to get working on your
                quote and will contact you once it's ready.
              </Trans>
            ) : (
              <Trans>
                Thank you! We'll reach out shortly to follow up about the
                services you selected and to gather additional details.
              </Trans>
            )
          }
        />
        <Box maxW="md" mx="auto" mb={10}>
          <picture>
            <source
              srcSet={darkGif}
              media="(prefers-reduced-motion: no-preference) and (prefers-color-scheme: dark)"
            ></source>
            <source
              srcSet={darkPng}
              media="(prefers-color-scheme: dark)"
            ></source>
            <source
              srcSet={lightGif}
              media="(prefers-reduced-motion: no-preference)"
            ></source>
            <img
              srcSet={lightPng}
              alt={t("You've completed your moving form!")}
            />
          </picture>
        </Box>
        <Divider my={6} />
        <Flex direction="column" align="center">
          <Box maxW="3xs">
            <a href="https://movetrac.com/">
              <picture>
                <source
                  srcSet="/img/movetrac-dark.png"
                  media="(prefers-color-scheme: dark)"
                ></source>
                <img srcSet="/img/movetrac.png" alt="Movetrac" />
              </picture>
            </a>
          </Box>
          <Text textAlign={'center'} maxW="container.sm">
            <Trans>
              Get a head start on organizing your move with{' '}
              <Link variant="unstyled" href="https://movetrac.com/?utm_source=">
                movetrac®
              </Link>
              ! You can track your quote progress, keep up on your moving tasks,
              and more.
            </Trans>
          </Text>
        </Flex>
      </UlfStepInner>
    </UlfStepContainer>
  );
};

const Finish = () => {
  const roomsStart = useAppSelector(selectLeadRoomsStart);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fireGtmEvent('leadComplete'));
  }, []);

  return <FinishForm roomsStart={roomsStart} />;
};

export default Finish;
