// import { normalize, schema } from 'normalizr';
// import { camelizeKeys } from 'humps';
import { headers } from 'constants/config';
// import 'isomorphic-fetch';
import { API_ROOT } from 'utils';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, schema, request, token) => {
  const fullUrl =
    endpoint.indexOf('http') === -1 ? API_ROOT + endpoint : endpoint;

  if (request && request.body) {
    request.body = JSON.stringify(request.body);
  }

  const tokenizedHeaders = token
    ? {
        ...headers,
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    : {
        ...headers,
        'Content-Type': 'application/json',
      };
  const requestWithHeaders = {
    ...{ headers: tokenizedHeaders },
    ...request,
    credentials: 'same-origin',
  };

  return fetch(fullUrl, requestWithHeaders)
    .then((response) => response.json().then((body) => ({ response, body })))
    .then(({ response, body }) => {
      if (!response.ok) {
        return Promise.reject(body);
      }

      // const camelizedJson = camelizeKeys(body);
      // const result = camelizedJson || {};
      const receivedAt = Date.now();
      // const { notification, result } = body;

      // if (schema) {
      //   return {
      //     // ...normalize(result, schema),
      //     result,
      //     notification,
      //     receivedAt,
      //   };
      // }

      return {
        result: body,
        // notification,
        receivedAt,
      };
    });
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// const leadSchema = new schema.Entity('lead');
// const estimateSchema = new schema.Entity('estimate');
// const estimateLeadSchema = new schema.Entity('estimateLead', {
//   lead: leadSchema,
// });
// const authReturnSchema = new schema.Entity('authReturn', {
//   lead: leadSchema,
// });

// Schemas for UFL API responses.
// export const schemas = {
//   LEAD: leadSchema,
//   ESTIMATE: estimateSchema,
//   ESTIMATE_LEAD: estimateLeadSchema,
//   AUTH_RETURN: authReturnSchema,
// };

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { token, activeLeadID } = store.getState().app;

  let { endpoint, method } = callAPI;
  const { schema, types, payload, reauthorizeWith, external } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }
  if (!method) {
    method = 'GET';
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (activeLeadID) {
    endpoint = endpoint.replace('{id}', activeLeadID);
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  const request = {
    method,
    body: payload,
  };

  return callApi(endpoint, schema, request, external ? null : token).then(
    (response) =>
      next(
        actionWith({
          response,
          type: successType,
        }),
      ),
    (error) => {
      next(
        actionWith({
          type: failureType,
          statusCode: error.statusCode || 'Unknown',
          error: error.message || 'Something bad happened',
        }),
      );
      if ([401, 403].includes(error.statusCode)) {
        store.dispatch(reauthorizeWith(payload));
      }
    },
  );
};
