import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const baseStyle = defineStyle({
  fontWeight: 'bold',
  fontFamily: 'heading',
  borderRadius: 'full',
  textTransform: 'uppercase',
  textDecoration: 'none',
  _focus: {
    outline: '4px solid',
    outlineColor: 'tmtBlue',
    outlineOffset: '4px',
    boxShadow: 'none',
    _dark: {
      outlineColor: 'tmtGold',
    },
  },
});

export const uflLarge = defineStyle({
  py: '0.875rem',
  px: 6,
  height: 'auto',
});

export const orange = defineStyle({
  ...uflLarge,
  background: 'orange.500',
  '& .subIcon': {
    background: 'orange.500',
  },
  color: 'bodyFontColor',
  _hover: {
    background: 'orange.400',
    '& .subIcon': {
      background: 'orange.400',
    },
    _disabled: {
      background: 'orange.500',
    },
  },
});

export const blue = defineStyle({
  ...uflLarge,
  background: 'blue.500',
  '& .subIcon': {
    background: 'blue.500',
  },
  color: 'darkBodyFontColor',
  _hover: {
    background: 'blue.600',
    '& .subIcon': {
      background: 'blue.600',
    },
    _disabled: {
      background: 'blue.500',
    },
  },
});

export const primary = defineStyle({
  ...orange,
  _dark: {
    ...blue,
  },
});

export const stepSubmit = defineStyle({
  ...primary,
  ...uflLarge,
  minW: '50%',
});

export const secondary = defineStyle({
  ...blue,
  _dark: {
    ...orange,
  },
});

export const gray = defineStyle({
  background: 'gray.600',
  color: 'darkBodyFontColor',
  _hover: {
    background: 'gray.700',
  },
  // _focus: {
  //   outline: '4px solid',
  //   outlineColor: 'tmtBlue',
  // },
  _dark: {
    background: 'gray.500',
    color: 'black',
    _hover: {
      background: 'gray.400',
    },
    // _focus: {
    //   outlineColor: 'tmtGold',
    // },
  },
});

const destructive = defineStyle({
  background: 'errorColor',
  color: 'darkBodyFontColor',
  _hover: {
    background: 'errorHoverColor',
  },
});

const link = defineStyle({
  textDecoration: 'underline',
  color: 'bodyFontColor',
  _hover: {
    textDecoration: 'none',
  },
  _dark: {
    color: 'darkBodyFontColor',
  },
});

const footerLink = defineStyle({
  textDecoration: 'underline',
  color: 'tmtGold',
  textTransform: 'none',
  padding: 0,
  fontWeight: 'normal',
  fontFamily: 'body',
  borderRadius: 0,
  _hover: {
    textDecoration: 'none',
    color: 'orange.400',
  },
  _focus: {
    outlineColor: 'tmtGold',
    boxShadow: 'none',
  },
});

const actionButton = defineStyle({
  borderRadius: 1,
});

const menuItem = defineStyle({
  fontWeight: 'normal',
  fontFamily: 'body',
  textTransform: 'none',
  borderRadius: 'none',
  minW: 'auto',
  '&:not(:first-of-type)': {
    borderTop: '1px',
  },
  _hover: {
    bg: 'tmtBlue',
    color: 'white',
    zIndex: 2,
  },
  _focus: {
    bg: 'tmtBlue',
    color: 'white',
    zIndex: 2,
  },
  _dark: {
    _hover: {
      bg: 'tmtGold',
      color: 'black',
    },
    _focus: {
      bg: 'tmtGold',
      color: 'black',
    },
  },
});

const action = defineStyle({
  p: 2,
  fontSize: 'sm',
  fontWeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  gap: 0.5,
  h: 'full',
  borderRadius: 0,
  _hover: {
    textDecoration: 'underline',
    bg: 'tmtBlue',
    color: 'white',
  },
  _dark: {
    _hover: {
      bg: 'tmtGold',
      color: 'black',
    },
  },
});

const mobileMenuToggle = defineStyle({
  flex: '0 0 auto',
  height: 'auto',
  display: 'block',
  position: 'absolute',
  top: { base: '30px', md: 0 },
  right: 0,
  borderLeft: '1px',
  borderRadius: 0,
  px: { base: '20px', md: '25px' },
  py: { base: '14.5px', md: '18px' },
  fontWeight: 'light',
  fontSize: { base: 'sm', md: 'lg' },
  '.bar': {
    display: 'block',
    width: { base: '24px', md: '28px' },
    height: { base: '2px', md: '4px' },
    marginLeft: 'auto',
    marginRight: 'auto',
    bg: 'gray.800',
    position: 'relative',
    transition: 'all 0.2s ease',
    '+ .bar': {
      marginTop: 1,
    },
  },
  '&:hover, &:focus': {
    '.bar.first': {
      position: 'relative',
      top: { base: '6px', md: '9px' },
      left: '-6px',
      width: '20px',
      transform: 'rotate(45deg)',
    },
    '.bar.last': {
      position: 'relative',
      top: { base: '-6px', md: '-7px' },
      width: '20px',
      right: { base: '-7px', md: '-6px' },
      transform: 'rotate(-45deg)',
    },
    '.bar.middle': {
      opacity: 0,
    },
    '&[aria-expanded="true"]': {
      '.bar.first': {
        transform: 'rotate(-45deg)',
      },
      '.bar.last': {
        transform: 'rotate(45deg)',
      },
    },
  },
  _dark: {
    '.bar': {
      bg: 'white',
    },
  },
});

export default defineStyleConfig({
  defaultProps: {
    variant: 'primary',
  },
  baseStyle,
  sizes: {
    uflLarge,
  },
  variants: {
    primary,
    secondary,
    stepSubmit,
    destructive,
    link,
    actionButton,
    menuItem,
    action,
    footerLink,
    orange,
    blue,
    gray,
    mobileMenuToggle,
  },
});
