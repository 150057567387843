import React, { Suspense, lazy, useEffect } from 'react';
import { Trans } from 'react-i18next';
import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { FINISH_STEP, FIRST_STEP } from 'utils';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import {
  selectLeadCurrentStep,
  selectLeadFormComplete,
  selectLeadId,
  selectLeadVisibleSteps,
} from 'slices/leadSlice';
import { fetchLead } from 'actions/leadActions';
import { networkErrorMessage } from 'reducers/app';
import LoadingCard from 'components/LoadingCard';
import ContactStepContainer from 'components/steps/1-ContactStep';
import CustomerTypeStepContainer from 'components/steps/2-CustomerTypeStep';
import FromAddressStepContainer from 'components/steps/3-FromAddressStep';
import LocationSelectorStepContainer from 'components/steps/4-LocationSelectorStep';
import ServicesStepContainer from 'components/steps/5-ServicesStep';
import EndOfTheRoadStepContainer from 'components/steps/EndOfTheRoadStep';
import SquareFeetStepContainer from 'components/steps/6-SquareFeetStep';
import ServiceDateStepContainer from 'components/steps/7-ServiceDateStep';
import ToAddressStepContainer from 'components/steps/8-ToAddressStep';
import AdditionalStopsStepContainer from 'components/steps/9-AdditionalStopsStep';
import ConcernsStepContainer from 'components/steps/10-ConcernsStep';
import NextStepsStep from 'components/steps/11-NextStepsStep';
const ItemsStep = lazy(() => import('components/steps/12-InventoryStep'));
import SuppliesStepContainer from 'components/steps/13-SuppliesStep';
import Finish from 'components/Finish';

const Steps = {
  contact: ContactStepContainer,
  customerType: CustomerTypeStepContainer,
  fromAddress: FromAddressStepContainer,
  locationSelector: LocationSelectorStepContainer,
  services: ServicesStepContainer,
  endOfTheRoad: EndOfTheRoadStepContainer,
  squareFeet: SquareFeetStepContainer,
  serviceDate: ServiceDateStepContainer,
  toAddress: ToAddressStepContainer,
  additionalStops: AdditionalStopsStepContainer,
  concerns: ConcernsStepContainer,
  nextSteps: NextStepsStep,
  inventory: ItemsStep,
  supplies: SuppliesStepContainer,
};

export const ErrorAlert = () => {
  return (
    <Alert status="error" mb={4}>
      <AlertIcon />
      <Box>
        <AlertTitle>
          <Trans>Ruh roh! We ran into an issue!</Trans>
        </AlertTitle>
        <AlertDescription>
          <Trans>
            Please check your connection. If your connection is OK it may be our
            servers. Best bet may be to call the number in the top-left corner
            to finish your quote. Sorry for the inconvenience.
          </Trans>
        </AlertDescription>
      </Box>
    </Alert>
  );
};

const MainContentContainer = () => {
  const dispatch = useAppDispatch();
  const userMessage = useAppSelector((state) => state.app.userMessage) || null;
  const userErrorMessage =
    useAppSelector((state) => state.app.userErrorMessage) || null;
  const visibleStepsInState = useAppSelector(selectLeadVisibleSteps);
  const currentStepInState = useAppSelector(selectLeadCurrentStep);
  const leadId = useAppSelector(selectLeadId);
  const isFormComplete = useAppSelector(selectLeadFormComplete);

  const visibleSteps = visibleStepsInState.length
    ? visibleStepsInState
    : [FIRST_STEP];
  const currentStep = currentStepInState || FIRST_STEP;

  useEffect(() => {
    // If there's a lead in Redux, refresh it from the backend.
    if (leadId) {
      dispatch(fetchLead());
    }
  }, [leadId]);

  if (isFormComplete === true || currentStep === FINISH_STEP) {
    return <Finish />;
  }

  return (
    <>
      {userMessage && <Box className="user-message">{userMessage}</Box>}
      {visibleSteps.map((stepName) => {
        const Step = Steps[stepName];
        if (Step) {
          return (
            <Suspense key={stepName} fallback={<LoadingCard />}>
              <Step isCurrentStep={stepName === currentStep} />
              {userErrorMessage === networkErrorMessage && <ErrorAlert />}
            </Suspense>
          );
        }

        return <div key={stepName}></div>;
      })}
    </>
  );
};

export default MainContentContainer;
