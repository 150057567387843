import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import fuzzysort from 'fuzzysort';

import getInventory from '@backend/lib/mwc/inventory';
import { useAppSelector } from 'store/configureStore';
import { selectCurrentLocation } from 'slices/app';
import { isInventoryItemIncompatible } from 'lib/inventory';
import { InventoryType } from 'services/movetrac';

export type InventoryApiResponse = {
  inventoryLabels: Fuzzysort.Prepared[];
  inventoryKeyedByLabel: Dictionary<InventoryType>;
  inventoryKeyedById: Dictionary<InventoryType>;
  incompatibleInventoryIds: number[];
};

export type UseInventoryDefinitions = UseQueryResult<InventoryApiResponse>;

const useInventoryDefinitions = (): UseInventoryDefinitions => {
  const currentLocation = useAppSelector(selectCurrentLocation);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return useQuery({
    queryKey: ['inventory', currentLanguage],
    queryFn: async () => {
      // @TODO: Switch this over to movetrac for live inventory, once launched.
      // const inventory = await getInventory();
      const inventory = getInventory();

      const inventoryLabels = inventory.map((i) => fuzzysort.prepare(i.label));
      const inventoryKeyedByLabel = keyBy(inventory, 'label');
      const inventoryKeyedById = keyBy(inventory, 'id');
      const incompatibleInventoryIds = inventory
        .filter(
          (inventoryItem) =>
            currentLocation &&
            isInventoryItemIncompatible({ currentLocation, inventoryItem }),
        )
        .map((inventoryItem) => inventoryItem.id);

      return {
        inventoryLabels,
        inventoryKeyedByLabel,
        inventoryKeyedById,
        incompatibleInventoryIds,
      };
    },
    enabled: Boolean(currentLocation && currentLanguage),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

export default useInventoryDefinitions;
