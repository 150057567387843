import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  textDecoration: 'underline',
  _hover: {
    textDecoration: 'none',
  },
  _focus: {
    outline: '4px solid',
    outlineColor: 'tmtBlue',
    outlineOffset: '4px',
    boxShadow: 'none',
  },
  _dark: {
    _focus: {
      outlineColor: 'tmtGold',
    },
  },
});

const link = defineStyle({
  // textDecoration: 'underline',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontFamily: 'heading',
  // _hover: {
  //   textDecoration: 'none',
  // },
});

const footerLink = defineStyle({
  color: 'tmtGold',
  _hover: {
    color: 'orange.400',
  },
  _focus: {
    outlineColor: 'tmtGold',
  },
});

const button = defineStyle({
  w: '100%',
  // _hover: {
  //   textDecoration: 'none',
  // },
});

export default defineStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'link',
  },
  variants: {
    link,
    button,
    footerLink,
  },
});
