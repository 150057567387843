import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { Dictionary } from 'lodash';
import pick from 'lodash/pick';

import { isFromAddressBuildingTypeStorage } from '@backend/lib/lead/helperFunctions';
import { Lead, LeadRoom } from '@backend/src/interfaces/mongoose.gen';
import { ObjectId } from '@backend/src/lib/lead';
import logger from 'logger';
import types from 'constants/actionTypes';
import { isLeadMultiSpace, itemsBySpace } from 'lib/space';
import { leadPhotoTagsBySpaceAndType } from 'lib/photoTag';
import { initialLeadState as initialState } from 'lib/lead';
import { RootState } from 'store/configureStore';
import { LocationApiLocation } from 'lib/locations';

export const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(types.SAVE_LEAD_DETAILS, (state, action): Lead => {
        logger.info(types.SAVE_LEAD_DETAILS, action);
        return {
          ...state,
          ...action.detailsPartial,
        };
      })
      // Shared
      .addCase(types.UPDATE_LEAD_SUCCESS, (state, action): Lead => {
        logger.info(types.UPDATE_LEAD_SUCCESS, action);
        return {
          ...state,
          ...(state.gdprConsent ? action.response.result : {}),
        };
      })
      // // Shared
      .addCase(types.AUTHENTICATE_RETURNING_SUCCESS, (_state, action): Lead => {
        return action.response.result.lead;
      })
      // Shared
      .addCase(types.AUTHENTICATE_RETURNING_FAILURE, () => {
        return initialState;
      })
      // Shared
      .addCase(types.RESET_LEAD, (): Lead => {
        return initialState;
      })
      // Shared
      .addCase(types.UPDATE_CURRENT_LOCATION, (state, action): Lead => {
        const currentLocation = action.payload as LocationApiLocation;
        return {
          ...state,
          locationID: parseInt(currentLocation?.id || '0', 10),
          franchiseID: currentLocation.franchise?.id,
        };
      });
  },
});

export default leadSlice.reducer;

export const selectLead = (state: RootState): Lead => state.lead;

export const selectLeadId = (state: RootState): Lead['_id'] =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state.lead as Dictionary<any>).id ||
  state.lead._id ||
  state.app.activeLeadID;

export const selectLeadCompleteSteps = (
  state: RootState,
): Lead['completeSteps'] => state.lead?.completeSteps || [];

export const selectLeadVisibleSteps = (
  state: RootState,
): Lead['visibleSteps'] => state.lead?.visibleSteps || [];

export const selectLeadCurrentStep = (state: RootState): Lead['currentStep'] =>
  state.lead.currentStep;

export const selectLeadValidSteps = (state: RootState): Lead['validSteps'] =>
  state.lead?.validSteps || [];

export const selectIsStepComplete = createSelector(
  [selectLeadCompleteSteps, (_state, stepName) => stepName],
  (completeSteps, stepName) => completeSteps.includes(stepName),
);

const selectLeadMultiSpace = (state: RootState) =>
  pick(state.lead, ['oneSpace', 'inventory', 'rooms']);

export const selectIsLeadMultiSpace = createSelector(
  [selectLeadMultiSpace],
  (lead) => isLeadMultiSpace(lead),
);

const selectLeadFromAddressBuildingType = (state: RootState) =>
  pick(state.lead, ['customerType', 'fromAddress', 'toAddress']);

export const selectIsLeadStorage = createSelector(
  [selectLeadFromAddressBuildingType],
  (lead) => isFromAddressBuildingTypeStorage(lead),
);

export const selectLeadFormComplete = (
  state: RootState,
): Lead['formComplete'] => state.lead.formComplete;

export const selectLeadRoomsStart = (state: RootState): Lead['roomsStart'] =>
  state.lead.roomsStart;

export const selectLeadLocationId = (state: RootState): Lead['locationID'] =>
  state.lead.locationID;

export const selectLeadFranchiseId = (state: RootState): Lead['franchiseID'] =>
  state.lead.franchiseID;

export const selectLeadCustomerType = (
  state: RootState,
): Lead['customerType'] => state.lead.customerType;

export const selectLeadSelectedLocationDistance = (
  state: RootState,
): Lead['selectedLocationDistance'] => state.lead.selectedLocationDistance;

export const selectLeadServices = (state: RootState): Lead['services'] =>
  state.lead.services;

export const selectLeadInventory = (state: RootState): Lead['inventory'] =>
  state.lead.inventory;

export const selectLeadBoxes = (state: RootState): Lead['boxes'] =>
  state.lead.boxes;

export const selectLeadPhotos = (state: RootState): Lead['photos'] =>
  state.lead.photos;

export const selectLeadSpaces = (state: RootState): Lead['rooms'] =>
  state.lead.rooms;

export const selectLeadSpaceById = createSelector(
  [selectLeadSpaces, (_state, spaceId: ObjectId) => spaceId],
  (spaces, spaceId) => (spaces || []).find((space) => space._id === spaceId),
);

export const selectLeadSpaceIndex = createSelector(
  [selectLeadSpaces, (_state, spaceId: ObjectId) => spaceId],
  (spaces, spaceId) => {
    const spaceIds = (spaces || []).map((space) => space._id);
    return spaceIds.indexOf(spaceId) + 1;
  },
);

export const selectLeadPhotoTagsBySpaceAndType = createSelector(
  [
    selectLeadPhotos,
    (_state, spaceId: LeadRoom['_id']) => spaceId,
    (_state, _spaceId, itemType: number) => itemType,
  ],
  (leadPhotos, spaceId, itemType) =>
    leadPhotoTagsBySpaceAndType({
      leadPhotos,
      spaceId,
      itemType,
    }),
);

export const selectPhoto = createSelector(
  [selectLeadPhotos, (_state, id: ObjectId) => id],
  (photos, id) => (photos || []).find((photo) => photo._id === id),
);

export const selectLeadPhotosBySpaceId = createSelector(
  [selectLeadPhotos, (_state, spaceId: ObjectId) => spaceId],
  (photos, spaceId) => itemsBySpace(spaceId, photos),
);

export const selectLeadBoxesBySpace = createSelector(
  [selectLeadBoxes, (_state, spaceId: LeadRoom['_id']) => spaceId],
  (boxes, spaceId) => itemsBySpace(spaceId, boxes),
);

export const selectLeadBoxBySpaceAndType = createSelector(
  [
    selectLeadBoxes,
    (_state, spaceId: LeadRoom['_id']) => spaceId,
    (_state, _spaceId, itemType: number) => itemType,
  ],
  (boxes, spaceId, itemType) =>
    boxes.find(
      (box) =>
        box.spaceId === (spaceId as unknown as string) &&
        box.itemType === itemType,
    ),
);

export const selectLeadInventoryBySpace = createSelector(
  [selectLeadInventory, (_state, spaceId: LeadRoom['_id']) => spaceId],
  (inventory, spaceId) => itemsBySpace(spaceId, inventory),
);
