/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const boxes = (t_fn?: any) => {
  const t = t_fn ? t_fn : (x: any) => x;
  return {
    165: {
      //
      // Every supply item MUST have BOTH a singular and a plural human-readable lable.
      // These are used when displaying a user's choices back to them in a dynamic step description,
      // i.e., "You indicated you need [#] Large Boxes, and 1 Small Box for your move..." (PurchaseBoxesStep).
      //
      // The 'label' property is always plural, the 'singular' property is singular.
      mwc: 'Box - Large',
      label: t('Large Boxes'),
      singular: t('Large Box'),
      supplyID: '1',
      icon: '/img/icon-supplies-box-large.svg',
      info: t(
        'A large box is about 6 cubic feet. Use these for light objects. If you pack heavy items in large boxes they will be difficult to move.',
      ),
      customerSuppliedOrder: 3,
    },
    166: {
      mwc: 'Box - Medium',
      label: t('Medium Boxes'),
      singular: t('Medium Box'),
      supplyID: '2',
      icon: '/img/icon-supplies-box-medium.svg',
      info: t(
        'A medium box is about 3 cubic feet. Pack lighter objects in here than you would a smaller box, like pillows, bedding, and stuffed animals.',
      ),
      customerSuppliedOrder: 2,
    },
    169: {
      mwc: 'Box - Small',
      label: t('Small Boxes'),
      singular: t('Small Box'),
      supplyID: '3',
      icon: '/img/icon-supplies-box-small.svg',
      info: t(
        'A small box is about 1.5 cubic feet. They are best used for small heavy objects like books, records, cans, jars of food, utensils, flatware, shoes, etc.',
      ),
      customerSuppliedOrder: 1,
    },
    164: {
      mwc: 'Dish Barrel',
      label: t('Dish Packs'),
      singular: t('Dish Pack'),
      supplyID: '14',
      icon: '/img/icon-supplies-dish-pack.svg',
      info: t(
        'Also called a barrel, dish barrel or dish box, a dish pack is the strongest box available. Pack fragile, breakable items such as china and other kitchen items.',
      ),
      // Conditionally added only for Kitchens
      onlyFoundInRoom: '2',
    },
    170: {
      mwc: 'Wardrobe Box w/ Bar',
      label: t('Wardrobe Boxes'),
      singular: t('Wardrobe Box'),
      supplyID: '45',
      icon: '/img/icon-supplies-wardrobe-box.svg',
      info: t(
        'Wardrobe boxes allow clothes to be transported on hangers. This includes a metal bar along the top of the box to hang clothing.',
      ),
      // Conditionally added only for Bedrooms
      onlyFoundInRoom: '1',
    },
  };
};

export default boxes;
