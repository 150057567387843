/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type Room = {
  mwc: string;
  label: any;
  icon: string;
  items: number[];
  averageBoxes?: {
    small: number;
    medium: number;
    large: number;
  };
  itemGroups?: number[];
};

export type Rooms = Record<number, Room>;

const rooms = (t_fn?: any): Record<string, Rooms> => {
  const t = t_fn ? t_fn : (x: any) => x;

  // TODO: Get 'average number of boxes' content for each room type
  const home = {
    1: {
      mwc: 'Bedroom',
      label: t('Bedroom'),
      icon: '/img/icon-room-bedroom.svg',
      items: [79, 61, 63, 64, 71, 72, 93, 105, 137, 247, 104, 96, 406],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000001, 1000003],
    },
    2: {
      mwc: 'Kitchen',
      label: t('Kitchen'),
      icon: '/img/icon-room-kitchen.svg',
      items: [96, 383, 249, 468, 134, 73, 407],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    3: {
      mwc: 'Family/Living',
      label: t('Family Room'),
      icon: '/img/icon-room-living-room.svg',
      items: [71, 93, 43, 124, 126, 130, 133, 247, 94],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000001],
    },
    4: {
      mwc: 'Garage',
      label: t('Garage'),
      icon: '/img/icon-room-garage.svg',
      items: [173, 153, 247, 182, 185, 159],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      default: true,
    },
    5: {
      mwc: 'N/A',
      label: t('Basement'),
      icon: '/img/icon-room-basement.svg',
      items: [
        72, 93, 96, 253, 107, 124, 126, 130, 182, 185, 247, 120, 549, 73, 406,
      ],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000003, 1000002],
    },
    6: {
      mwc: 'Attic',
      label: t('Attic'),
      icon: '/img/icon-room-attic.svg',
      items: [96, 130, 137, 120],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    //"7, {mwc: 'Bathroom'}],
    8: {
      mwc: 'Dining Room',
      label: t('Dining Room'),
      icon: '/img/icon-room-dining-room.svg',
      items: [87, 55, 96, 132, 249],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000003],
    },
    17: {
      mwc: 'Patio/Outdoor',
      label: t('Patio'),
      icon: '/img/icon-room-patio.svg',
      items: [372, 116, 405, 130, 325, 153, 159, 454, 348],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    //"18, {mwc: 'Appliance'}],
    19: {
      mwc: 'Office',
      label: t('Office'),
      icon: '/img/icon-room-office.svg',
      items: [71, 72, 370, 259, 260, 253, 102, 107, 247, 126, 261, 406],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000001, 1000002, 1000003],
    },
    27: {
      mwc: 'storage unit',
      label: t('storage unit'),
      icon: '/img/icon-room-laundry-room.svg',
      items: [408],
      // averageBoxes: {},
      internal: true,
    },
    28: {
      mwc: 'Laundry',
      label: t('Laundry'),
      icon: '/img/icon-room-laundry-room.svg',
      items: [408],
      // averageBoxes: {},
    },
  };

  const business = {
    // "9": {   mwc: 'Office',   items: [71, 93, 96, 102, 107, 124, 126, 130, 247,
    // 249": 253] },
    10: {
      mwc: 'Conference Room',
      label: t('Conference Room'),
      icon: '/img/icon-room-conference-room.svg',
      items: [120, 257, 370, 247, 71, 495],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    11: {
      mwc: 'Supply Room',
      label: t('Supply Room'),
      icon: '/img/icon-room-supply-room.svg',
      items: [71, 107, 268, 120],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      default: true,
    },
    12: {
      mwc: 'B-Office',
      label: t('Office'),
      icon: '/img/icon-room-office.svg',
      items: [71, 259, 260, 102, 126, 247, 370, 106, 103, 261],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
      itemGroups: [1000002],
    },
    13: {
      mwc: 'B-Filing Room',
      label: t('File Room'),
      icon: '/img/icon-room-file-room.svg',
      items: [292, 107, 120],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    21: {
      mwc: 'B-Copy Room',
      label: t('Copy Room'),
      icon: '/img/icon-room-copy-room.svg',
      items: [107, 259, 260, 263, 484],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    22: {
      mwc: 'B-Reception Area',
      label: t('Reception Area'),
      icon: '/img/icon-room-reception-area.svg',
      items: [370, 259, 260, 292, 107, 130, 133],
      averageBoxes: {
        small: 6,
        medium: 4,
        large: 2,
      },
    },
    27: {
      mwc: 'storage unit',
      label: t('storage unit'),
      icon: '/img/icon-room-laundry-room.svg',
      items: [408],
      // averageBoxes: {},
      internal: true,
    },
  };
  return {
    home: home,
    business: business,
  };
};

export default rooms;
