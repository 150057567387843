import { LegacyRef } from 'react';
import { Dictionary } from 'lodash';
import clsx from 'clsx';
import get from 'lodash/get';
import {
  useController,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input as ChakraInput,
  InputProps,
  InputGroup,
  InputRightElement,
  FormHelperText,
} from '@chakra-ui/react';
import TextMaskedInput, { MaskedInputProps } from 'react-text-mask';

import { shouldShowSuccess } from 'lib/form';
import FieldSuccessIcon from 'components/primatives/FieldSuccessIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RhfTextFieldProps = UseControllerReturn<any, string> & {
  label: string;
  helpText?: string | JSX.Element;
  isRequired?: boolean;
  isPrivate?: boolean;
  showSuccess?: boolean;
  inputProps?: InputProps;
  maskedInputProps?: MaskedInputProps;
};

export const RhfTextField = ({
  label,
  helpText,
  inputProps,
  isRequired = false,
  isPrivate = false,
  showSuccess = true,
  field,
  fieldState,
  formState,
  maskedInputProps,
}: RhfTextFieldProps) => {
  const defaultValue = get(formState.defaultValues, field.name, '');
  const hadDefaultValue = (defaultValue || '').length > 0;
  const isSuccess =
    showSuccess && shouldShowSuccess({ field, hadDefaultValue, fieldState });
  const isInvalid = typeof fieldState.error !== 'undefined';

  const variant = isSuccess ? 'fancyValid' : 'fancy';

  const className = clsx(
    field.value && 'shrink-label',
    isSuccess && 'show-success',
  );

  return (
    <FormControl
      id={field.name}
      isRequired={isRequired}
      isInvalid={isInvalid}
      className={className}
      layerStyle="fieldWrapper"
    >
      <InputGroup>
        {typeof maskedInputProps === 'object' ? (
          <TextMaskedInput
            {...field}
            value={field.value || ''}
            //   // We do this because "Types of property 'height' are incompatible".
            {...(inputProps as Dictionary<unknown>)}
            {...maskedInputProps}
            render={(ref, maskedInputProps) => (
              <ChakraInput
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                variant={variant}
                className={className}
                data-private={isPrivate ? 'data-private' : undefined}
                {...maskedInputProps}
              />
            )}
          />
        ) : (
          <ChakraInput
            variant={variant}
            className={className}
            {...field}
            value={field.value || ''}
            data-private={isPrivate ? 'data-private' : undefined}
            {...inputProps}
          />
        )}
        <FormLabel>{label}</FormLabel>
        <InputRightElement>
          {isSuccess && <FieldSuccessIcon />}
        </InputRightElement>
      </InputGroup>
      {isInvalid && (
        <FormErrorMessage variant="fancyField">
          {fieldState.error?.message}
        </FormErrorMessage>
      )}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

const RhfTextFieldContainer = ({
  label,
  inputProps,
  isRequired,
  isPrivate,
  helpText,
  showSuccess,
  maskedInputProps,
  ...props // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UseControllerProps<any> &
  Pick<
    RhfTextFieldProps,
    | 'label'
    | 'inputProps'
    | 'isRequired'
    | 'isPrivate'
    | 'helpText'
    | 'showSuccess'
    | 'maskedInputProps'
  >) => {
  const controler = useController(props);
  return (
    <RhfTextField
      label={label}
      inputProps={inputProps}
      isRequired={isRequired}
      isPrivate={isPrivate}
      showSuccess={showSuccess}
      helpText={helpText}
      maskedInputProps={maskedInputProps}
      {...controler}
    />
  );
};

export default RhfTextFieldContainer;
