import { Trans, useTranslation } from 'react-i18next';
import { Alert, AlertIcon } from '@chakra-ui/react';

import UlfStepContainer, { UlfStepInner } from 'components/UlfStepContainer';
import Intro from 'components/Intro';

export const ErrorCard = () => {
  const { t } = useTranslation();
  return (
    <UlfStepContainer>
      <UlfStepInner>
        <Intro title={t(`Uh oh!`)} />
        <Alert status="error" mb={4}>
          <AlertIcon />
          <Trans>There was a problem with your request!</Trans>
        </Alert>
      </UlfStepInner>
    </UlfStepContainer>
  );
};

export default ErrorCard;
