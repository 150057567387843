import Alert from './alert';
import Button from './button';
import Checkbox from './checkbox';
import Form from './formControl';
import FormError from './formError';
import FormLabel from './formLabel';
import Heading from './heading';
import Input from './input';
import Link from './link';
import Modal from './modal';
import Radio from './radio';
import Select from './select';
import Tabs from './tabs';
import Menu from './menu';
import Drawer from './drawer';

const components = {
  Alert,
  Button,
  Checkbox,
  Form,
  FormError,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  Radio,
  Select,
  Tabs,
  Menu,
  Drawer,
};

export default components;
